import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import { resetPassword } from 'Util/Server/ResetPassword'

function ResetPassword() {
  const history = useHistory()
  const params: {
    token: string
  } = useParams()

  const Schema = Yup.object().shape({
    password: Yup.string()
      .min(7)
      .required('Please enter a password of minimum 7 characters'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Passwords must match'),
  })

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      resetPassword(
        {
          token: params.token,
          password: values.password,
        },
        () => {
          // success callback is going to login page
          history.push('/login')
        }
      )
      // reset form
      resetForm()
    },
  })

  const isPasswordValid: boolean = !(
    form.touched.password && form.errors.password
  )
  const isConfirmPasswordValid: boolean = !(
    form.touched.confirmPassword && form.errors.confirmPassword
  )

  return (
    <div className="w-full my-12">
      <form
        className="max-w-xl mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={form.handleSubmit}
      >
        <div className="text-xl mb-5 font-semibold text-gray-600">
          Reset your password
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            className={isPasswordValid ? 'form-ok' : 'form-notok'}
            id="password"
            name={'password'}
            type="password"
            placeholder="******************"
            value={form.values.password}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
          {!isPasswordValid && (
            <p className="text-red-500 text-xs italic">
              {form.errors.password}
            </p>
          )}
        </div>
        <div className="mb-6">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Confirm Password
          </label>
          <input
            className={isConfirmPasswordValid ? 'form-ok' : 'form-notok'}
            id="confirm-password"
            name={'confirmPassword'}
            type="password"
            placeholder="******************"
            value={form.values.confirmPassword}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
          {!isConfirmPasswordValid && (
            <p className="text-red-500 text-xs italic">
              {form.errors.confirmPassword}
            </p>
          )}
        </div>
        <div className="flex items-center justify-between">
          <Button
            role={ButtonRole.Submit}
            type={ButtonType.Primary}
            text={'Reset Password'}
          />
        </div>
      </form>
    </div>
  )
}

export default ResetPassword
