import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Link } from 'react-router-dom'

import NavActions from 'Components/NavActions/'

export const HEADER_HEIGHT_DIMENSION = 48
export const HEADER_HEIGHT = `${HEADER_HEIGHT_DIMENSION}px`

const Wrapper = styled.header`
  ${tw`sticky flex justify-center z-10 text-purple-700 px-5 py-0 text-xl font-semibold shadow`};
  top: 1px;
  background-color: #f5f5f5;
  height: ${HEADER_HEIGHT};
`

function Header() {
  return (
    <Wrapper data-testid={'jublime-header'}>
      <div className="flex text-2xl font-semibold items-center justify-center">
        <Link to={'/'}>Jublime</Link>
      </div>
      <div className="flex ml-auto">
        <NavActions />
      </div>
    </Wrapper>
  )
}

export default Header
