import axios from 'axios'
import { notify } from 'Util/Noty'

interface Payload {
  feedback: string
  url: string
  source: string
}

type Props = {
  payload: Payload
  successCallback: () => void
  errorCallback: () => void
}

export function sendFeedback({
  payload,
  successCallback,
  errorCallback,
}: Props) {
  axios
    .post(`/public_api/send_feedback`, {
      ...payload,
    })
    .then(({ data }) => {
      successCallback()
      notify({
        type: 'success',
        text: 'Thank you for your feedback',
      })
    })
    .catch(err => {
      errorCallback()
      notify({
        type: 'error',
        text: 'Problem sending your feedback. Please try again',
      })
    })
}
