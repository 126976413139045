import { _getJobId } from 'Try/ApiContext'

const jobs = [
  {
    name: 'Interesting Startup Content Writing job',
    comments:
      'Looks interesting. (Your comments for this job. Click the job title to edit comments)',
    company_url: 'https://jublime.com',
    inserted_at: new Date().toISOString(),
    job_url: 'https://jublime.com',
    location_id: 1,
    tag_ids: '1,3',
    updated_at: new Date().toISOString(),
  },
  {
    name: 'Content Writer for a Big Corp',
    comments:
      'Looks interesting. (Your comments for this job. Click the job title to edit comments)',
    company_url: 'https://jublime.com',
    inserted_at: new Date().toISOString(),
    job_url: 'https://jublime.com',
    location_id: 1,

    tag_ids: '1,2,3',
    updated_at: new Date().toISOString(),
  },
  {
    name: 'Technical writer for BioTech startup',
    comments:
      'Looks interesting. (Your comments for this job. Click the job title to edit comments)',
    company_url: 'https://jublime.com',
    inserted_at: new Date().toISOString(),
    job_url: 'https://jublime.com',
    location_id: 1,

    tag_ids: '1,5,7',
    updated_at: new Date().toISOString(),
  },
  {
    name: 'Writer for GreatFoodBlog.com',
    comments:
      'Looks interesting. (Your comments for this job. Click the job title to edit comments)',
    company_url: 'https://jublime.com',
    inserted_at: new Date().toISOString(),
    job_url: 'https://jublime.com',
    location_id: 1,

    tag_ids: '1,4,6',
    updated_at: new Date().toISOString(),
  },
  {
    name: 'Writer for an intersting Social Media startup',
    comments:
      'Looks interesting. (Your comments for this job. Click the job title to edit comments)',
    company_url: 'https://jublime.com',
    inserted_at: new Date().toISOString(),
    job_url: 'https://jublime.com',
    location_id: 1,

    tag_ids: '1,2,5',
    updated_at: new Date().toISOString(),
  },
]

const DATA = jobs.map(job => ({
  id: _getJobId(),
  ...job,
}))

export default DATA
