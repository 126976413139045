import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { X } from 'Svg/'
import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import { sendResetInstructions } from 'Util/Server/ResetPassword'

function Form() {
  const [resetMessage, setResetMessage] = useState(false)

  const Schema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Please enter your registered email to send instructions'),
  })

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      sendResetInstructions(values.email, setResetMessage)
      // reset form
      resetForm()
    },
  })

  const isEmailValid: boolean = !(form.touched.email && form.errors.email)

  return (
    <div className="w-full my-12">
      {resetMessage && (
        <div className="flex items-center justify-center p-3 rounded text-center bg-green-500 text-white font-semibold mb-8">
          <div className="flex flex-col w-full">
            <div>
              <span>
                Please check your registered email for password reset
                instructions.
              </span>
            </div>

            <div className="italic font-normal text-sm my-1">
              Please check your spam for emails from{' '}
              <span className="font-semibold">arun@jublime.com</span> and mark
              it as not spam.
            </div>
          </div>
          <div
            className="ml-auto hover:opacity-75 cursor-pointer"
            onClick={() => setResetMessage(false)}
          >
            <X />
          </div>
        </div>
      )}
      <form
        className="max-w-xl mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        onSubmit={form.handleSubmit}
      >
        <div className="text-xl mb-5 font-semibold text-gray-600">
          Forgot your password?
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className={isEmailValid ? 'form-ok' : 'form-notok'}
            id="email"
            name={'email'}
            type="email"
            placeholder="Please enter your registered email to get reset instructions"
            value={form.values.email}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
          {!isEmailValid && (
            <p className="text-red-500 text-xs italic">{form.errors.email}</p>
          )}
        </div>
        <div className="flex items-center justify-between">
          <Button
            role={ButtonRole.Submit}
            type={ButtonType.Primary}
            text={'Send instructions to reset password'}
          />
        </div>
      </form>
    </div>
  )
}

export default Form
