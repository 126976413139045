import React, { useState } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import { Add, Minus, CheckCircle } from 'Svg/'

const ToAddHolder = styled.button`
  ${tw`flex w-full h-full items-center justify-center align-baseline font-bold text-sm text-purple-500 hover:text-blue-800 cursor-pointer hover:bg-gray-100`}
`

const AddedHolder = styled.button`
  ${tw`flex w-full h-full items-center justify-center align-baseline font-bold text-sm text-green-500 hover:text-blue-800 cursor-pointer hover:bg-gray-100`}

  & .minus {
    ${tw`text-red-400`}
  }
`

interface Props {
  onClick: Function
}

function AddProspect({ onClick }: Props) {
  return (
    <ToAddHolder onClick={() => onClick()}>
      <div className="w-4 h-4 mr-1">
        <Add />
      </div>
      <div>{'Add'}</div>
    </ToAddHolder>
  )
}

function AddedProspect({ onClick }: Props) {
  const [hovered, setHoverStatus] = useState(false)

  return (
    <AddedHolder
      onClick={() => onClick()}
      onMouseEnter={() => setHoverStatus(true)}
      onMouseLeave={() => setHoverStatus(false)}
    >
      <div className="w-4 h-4 mr-1">
        {hovered ? (
          <span className="minus">
            <Minus />
          </span>
        ) : (
          <CheckCircle />
        )}
      </div>
      <div>{hovered ? <span className="minus">{'Remove'}</span> : 'Added'}</div>
    </AddedHolder>
  )
}

export { AddProspect, AddedProspect }
