import React from 'react'
import { isEmpty, words, isNil } from 'lodash-es'
import styled from 'styled-components'
import tw from 'twin.macro'

type Props = {
  highlight?: string | null
  content?: string
}

const Holder = styled.div`
  & .highlight {
    ${tw`bg-yellow-100`}
  }
`

function SearchHighlighter({ highlight, content }: Props) {
  if (isEmpty(highlight) || isNil(highlight)) {
    return (
      <Holder>
        <span>{content || ''}</span>
      </Holder>
    )
  }

  if (isNil(content)) {
    return <Holder></Holder>
  }

  // split words from search term
  const regexQuery = new RegExp(`(${words(highlight).join('|')})`, 'img')
  const splitted = content.split(regexQuery).map(word => {
    // if it has highlighted term we need to add class
    // const isHighlight = highlight.toLowerCase().includes(word.toLowerCase())
    const isHighlight = regexQuery.test(word)

    if (isHighlight) {
      return <span className={'highlight'}>{word}</span>
    }

    return <span>{word}</span>
  })

  return (
    <Holder>
      {splitted.map((term, index) => (
        <React.Fragment key={index}>{term}</React.Fragment>
      ))}
    </Holder>
  )
}

export default SearchHighlighter
