import React, { useState } from 'react'
import { isNil } from 'lodash-es'

import SearchHighlighter from 'Components/SearchHighlighter/'

/*
 * Gist for long content
 *
 * Shows gist for long content like job description
 * Takes content, maxLength
 * If the content is less than maxLength, shows content as is
 * if it exceeds the maxLength
 * 'Show More/Show Less' CTA is shown which toggles between content and gist
 */

interface Props {
  content: string
  maxLength: number
  highlight?: string | null
}

const defaultProps: Partial<Props> = {
  maxLength: 1008,
}

function Gist({ content, maxLength, highlight }: Props) {
  const isBigText = content.length > maxLength
  const [showGist, setGistStatus] = useState(isBigText)

  if (!isBigText) {
    if (isNil(highlight)) {
      return <div>{content}</div>
    }

    return <SearchHighlighter highlight={highlight} content={content} />
  }

  const gistText = content.slice(0, maxLength) + ' ...'
  const toShow = showGist ? gistText : content

  const Text = () => {
    if (isNil(highlight)) {
      return <div>{toShow}</div>
    }

    return <SearchHighlighter highlight={highlight} content={toShow} />
  }

  return (
    <div>
      <Text />
      <div>
        <button
          className={
            'my-4 text-purple-700 hover:text-purple-500 underline font-semibold'
          }
          onClick={() => {
            if (showGist) {
              setGistStatus(false)
            } else {
              setGistStatus(true)
            }
          }}
        >
          {showGist ? 'Show More' : 'Show Less'}
        </button>
      </div>
    </div>
  )
}

Gist.defaultProps = defaultProps

export default Gist
