import React from 'react'
import Select from 'react-select'

import { Locations } from 'Context/Auth/'

type Props = {
  locations: Locations
  value?: number
  onChange: Function
}

type Value = {
  value: number
  label: string
}

function LocationDropdown({ locations, value, onChange }: Props) {
  // parse locations
  const location_options: Array<Value> = locations.map(l => ({
    value: l.id,
    label: l.name,
  }))

  const selected: Value | undefined = value
    ? location_options.find(l => l.value === value)
    : location_options[0]

  return (
    <Select
      options={location_options}
      value={selected}
      onChange={(l: any) => {
        onChange(l?.value)
      }}
      theme={theme => ({
        ...theme,
        colors: {
          ...theme.colors,

          primary: '#9f7aea',
          primary75: '#b794f4',
          primary50: '#d6bcfa',
          primary25: '#e9d8fd',
        },
      })}
    />
  )
}

export default LocationDropdown
