import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import { sendFeedback } from 'Util/Server/Feedback'
import { InfoBanner } from 'Util/Styles'

const SubmitWrapper = styled.div`
  ${tw`w-full my-2`};

  & button {
    ${tw`w-full`};
  }
`

const Schema = Yup.object().shape({
  job_board: Yup.string()
    .url('Please enter a valid job board url (starting with https://, http://)')
    .required('Please enter a valid job board url.'),
})

function GetJobBoard() {
  const initialValues = {
    job_board: '',
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Schema,
    validateOnBlur: false,
    onSubmit: (values, { resetForm }) => {
      const payload = {
        url: window.location.href,
        source: 'WEB',
        feedback: `[FAVORITE-JOB-BOARD]: ${values.job_board}`,
      }
      sendFeedback({
        payload,
        successCallback: () => {
          // reset the form
          resetForm()
        },
        errorCallback: () => {},
      })
    },
  })

  return (
    <form onSubmit={form.handleSubmit}>
      <InfoBanner>
        <div>
          {`Jublime aggregates latest jobs from multiple job boards. 
          If you would like to see latest jobs from your favorite job board, please let me know via `}
          <span className="italic font-semibold">feedback form.</span>{' '}
        </div>
        <div className={'w-full mt-2'}>
          <input
            className={`flex border border-gray-300 rounded focus:shadow p-2 px-4 focus:outline-none focus:border-purple-600 focus:border-2 w-full`}
            placeholder={'Your favorite job board ...'}
            name={'job_board'}
            value={form.values.job_board}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </div>
        {!form.isValidating &&
          form.touched.job_board &&
          form.errors.job_board && (
            <p className="text-red-500 text-xs italic my-1">
              {form.errors.job_board}
            </p>
          )}
        <SubmitWrapper>
          <Button
            role={ButtonRole.Submit}
            type={ButtonType.Primary}
            text={'Submit Job Board'}
            disabled={!form.isValid}
          />
        </SubmitWrapper>
      </InfoBanner>
    </form>
  )
}

export default GetJobBoard
