import axios from 'axios'
import { notify } from 'Util/Noty/'

import { Callback } from 'Types/'
import { EditProspect } from 'Types/Search'

export interface AddProspectProps extends Callback {
  prospects: Array<number>
  search_id: number
}

// add prospects to search
export function addProspectsToSearch({
  prospects,
  search_id,
  successCallback,
  errorCallback,
}: AddProspectProps) {
  axios
    .post('/api/job_search/add_prospects', {
      prospects,
      search_id,
    })
    .then(({ data }) => {
      notify({
        type: 'success',
        text: 'Added job to search',
      })

      successCallback(data)
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to continue ...',
        })
      } else {
        notify({
          type: 'error',
          text: 'Error adding prospects to search. Please try again.',
        })
      }

      errorCallback()
    })
}

export interface EditProspectProps extends Callback {
  prospect: EditProspect
}

// edit prospect
export function editProspect({
  prospect,
  successCallback,
  errorCallback,
}: EditProspectProps) {
  axios
    .post('/api/job_search/edit_prospect', {
      prospect,
    })
    .then(({ data }) => {
      notify({
        type: 'success',
        text: 'Updated successfully',
      })
      successCallback(data)
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to continue ...',
        })
      } else {
        notify({
          type: 'error',
          text: 'Error editing prospect. Please try again.',
        })
      }

      errorCallback()
    })
}

export interface DeleteProspectProps extends Callback {
  prospect: {
    id: number
    search_id: number
    job_id: number
  }
}

// delete prospect
export function deleteProspect({
  prospect,
  successCallback,
  errorCallback,
}: DeleteProspectProps) {
  axios
    .post('/api/job_search/delete_prospect', {
      prospect,
    })
    .then(() => {
      notify({
        type: 'success',
        text: 'Deleted successfully',
      })
      successCallback()
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to continue ...',
        })
      } else {
        notify({
          type: 'error',
          text: 'Error editing prospect. Please try again.',
        })
      }

      errorCallback()
    })
}
