import React, { useState, useContext, createContext } from 'react'

import Modal from 'Common/Modals/Login/'
import { notify } from 'Util/Noty'

type Props = {
  children: React.ReactNode
}

type Options = {
  title?: string
  text?: string
}

export interface ContextValue {
  showLoginModal: (options?: Options) => void
  hideLoginModal: () => void
}

const defaultContextValue: ContextValue = {
  showLoginModal: (options?: Options) => {},
  hideLoginModal: () => {},
}

export const LoginModalContext = createContext<ContextValue>(
  defaultContextValue
)

function LoginModal({ children }: Props) {
  const [isOpen, setModalStatus] = useState(false)

  return (
    <LoginModalContext.Provider
      value={{
        showLoginModal: (options?: Options) => {
          // notify with message?
          if (options?.text) {
            notify({
              title: options.title,
              text: options.text,
              timeout: 5555,
              type: 'info',
            })
          }
          setModalStatus(true)
        },
        hideLoginModal: () => {
          setModalStatus(false)
        },
      }}
    >
      {children}
      {/* Login Modal */}
      <Modal isOpen={isOpen} setModalStatus={setModalStatus} />
    </LoginModalContext.Provider>
  )
}

export default LoginModal

// helper hook to use login modal
export function useLoginModal() {
  const { showLoginModal, hideLoginModal } = useContext(LoginModalContext)

  return {
    showLoginModal,
    hideLoginModal,
  }
}
