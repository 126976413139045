import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from 'react'

import { AuthContext } from 'Context/Auth/'
import { getAllViewedJobs, updateViewedJob } from 'Util/Server/ViewedJobs'

interface Props {
  children: React.ReactNode
}

interface ContextValue {
  jobs: Array<string>
  setJobs: React.Dispatch<React.SetStateAction<string[]>>
  addViewed: (jobId: string) => void
  removeViewed: (jobId: string) => void
  updateViewed: (jobId: string) => void
  isJobViewed: (jobId: string) => boolean
}

export const ViewedJobsContext = createContext<ContextValue>({
  jobs: [],
  setJobs: () => {},
  addViewed: () => {},
  removeViewed: () => {},
  updateViewed: () => {},
  isJobViewed: () => false,
})

function ViewedJobs({ children }: Props) {
  const [jobs, setJobs] = useState<Array<string>>([])
  const { authStatus } = useContext(AuthContext)

  const addViewed = useCallback((jobId: string) => {
    setJobs(currentJobs => [...currentJobs, jobId])
  }, [])

  const removeViewed = useCallback((jobId: string) => {
    setJobs(currentJobs => currentJobs.filter(c => c !== jobId))
  }, [])

  const isJobViewed = useCallback(
    (jobId: string) => {
      const isJobPresent = jobs.find(j => j === jobId)

      return isJobPresent ? true : false
    },
    [jobs]
  )

  const updateViewed = useCallback(
    (jobId: string) => {
      // first we need to find out the correct action
      const isJobPresent = jobs.find(j => j === jobId)
      const action = isJobPresent ? 'remove' : 'add'

      updateViewedJob({
        jobId,
        action,
        successCallback: () => {
          if (isJobPresent) {
            // we have to remove the job after api call
            removeViewed(jobId)
          } else {
            // we need to add the job to viewed list
            addViewed(jobId)
          }
        },
        errorCallback: () => {},
      })
    },
    [jobs, addViewed, removeViewed]
  )

  useEffect(() => {
    if (!authStatus?.user?.is_admin) {
      return
    }

    // we will be fetching the jobs
    getAllViewedJobs({
      successCallback: (jobs: string[]) => {
        setJobs(jobs)
      },
      errorCallback: () => {},
    })
  }, [authStatus])

  return (
    <ViewedJobsContext.Provider
      value={{
        jobs,
        setJobs,
        addViewed,
        removeViewed,
        updateViewed,
        isJobViewed,
      }}
    >
      {children}
    </ViewedJobsContext.Provider>
  )
}

export default ViewedJobs
