import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { emailSubscription } from 'Util/Server/Subscriptions'

const Button = styled.button<{
  disabled: boolean
}>`
  ${tw`bg-red-500 font-bold rounded text-white p-4 px-6`}

  ${props =>
    props.disabled
      ? tw`opacity-75 cursor-text`
      : tw`hover:bg-red-400 hover:duration-300`}
`

function Subscription() {
  const Schema = Yup.object().shape({
    email: Yup.string().email().required('Please enter your email'),
    source: Yup.string(),
    url: Yup.string(),
  })

  const initialValues = {
    email: '',
    source: 'SITE',
    url: window.location.href,
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      emailSubscription(values, resetForm)
    },
  })

  return (
    <div className="flex flex-col my-10 p-5 border rounded-md w-3/5 bg-purple-600">
      <div className="flex text-xl text-gray-100 font-semibold my-3">
        Get updates on Jublime and make your job search better.
      </div>
      <form className="flex my-3" onSubmit={form.handleSubmit}>
        <div className="flex flex-grow">
          <input
            className="rounded w-full p-3 mr-3 bg-gray-100 border"
            type="text"
            name={'email'}
            placeholder="Enter your Email ..."
            value={form.values.email}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </div>
        <div className="flex">
          <Button
            type="submit"
            disabled={!!(form.touched.email && form.errors.email)}
          >
            Get Updates
          </Button>
        </div>
      </form>
    </div>
  )
}

export default Subscription
