import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { useHistory, useLocation } from 'react-router-dom'
import tw from 'twin.macro'

import Button, { ButtonType } from 'Common/Button'
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronLeft,
  ChevronRight,
} from 'Svg/'

const Holder = styled.div<{
  disabled?: boolean
}>`
  ${tw`flex p-1 px-2 items-center cursor-pointer border border-purple-100`};

  & svg {
    ${tw`w-6 h-6 mx-1 text-purple-500`};
  }

  ${props => (props.disabled ? tw`opacity-25 pointer-events-none` : ``)}

  &:hover {
    ${tw`border-purple-500 opacity-75`};
  }
`

interface Props {
  total: number
}

function Pagination({ total }: Props) {
  const location = useLocation()
  const history = useHistory()

  const currentPage = new URLSearchParams(location.search).get('page')

  const [page, setPage] = useState(Number(currentPage) || 1)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)

    // let us not switch page if first page (no url params)
    if (page === 1 && !params.has('page')) {
      return
    }

    // update the page
    params.set('page', String(page))
    // construct the url
    const url = `/find-jobs?${params.toString()}`

    history.push(url)
  }, [page, history])

  const PAGE_COUNT = 30
  const lastPage = Math.ceil(total / PAGE_COUNT)

  const isFirstPage = page === 1
  const isLastPage = page === lastPage

  return (
    <div className={'flex items-center justify-center p-2'}>
      <Holder disabled={isFirstPage} onClick={() => setPage(1)}>
        <ChevronDoubleLeft />
        <Button type={ButtonType.Outline} text={'First'} />
      </Holder>

      <Holder disabled={isFirstPage} onClick={() => setPage(page - 1)}>
        <ChevronLeft />
        <Button type={ButtonType.Outline} text={'Prev'} />
      </Holder>

      <Holder disabled={isLastPage} onClick={() => setPage(page + 1)}>
        <Button type={ButtonType.Outline} text={'Next'} />
        <ChevronRight />
      </Holder>

      <Holder disabled={isLastPage} onClick={() => setPage(lastPage)}>
        <Button type={ButtonType.Outline} text={'Last'} />
        <ChevronDoubleRight />
      </Holder>
    </div>
  )
}

export default Pagination
