import React from 'react'
import Modal from 'react-modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { modalStyles } from 'Util/Styles'
import { X } from 'Svg/'
import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import { sendFeedback } from 'Util/Server/Feedback'

// bind modal to root element
Modal.setAppElement('#root')

interface Props {
  isOpen: boolean
  setModalStatus: React.Dispatch<React.SetStateAction<boolean>>
}

function FeedbackModal({ isOpen, setModalStatus }: Props) {
  const Schema = Yup.object().shape({
    comments: Yup.string()
      .min(3, 'Too Short!')
      .required('Please enter your comments ...'),
  })

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      comments: '',
    },
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      const payload = {
        feedback: values.comments,
        url: window.location.href,
        source: 'WEB',
      }
      sendFeedback({
        payload,
        successCallback: () => {
          resetForm()
          setModalStatus(false)
        },
        errorCallback: () => {},
      })
    },
  })

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyles}
      onRequestClose={() => setModalStatus(false)}
    >
      <form className="flex flex-col h-full" onSubmit={form.handleSubmit}>
        <div className="flex flex-1 w-full items-center text-gray-700 border-b border-gray-400 mb-3 px-1 py-3">
          <div className="font-bold text-2xl">{'Feedback please'}</div>
          <div
            className="ml-auto hover:opacity-75 cursor-pointer"
            onClick={() => setModalStatus(false)}
          >
            <X />
          </div>
        </div>
        <div className="flex flex-col h-full px-1 w-full overflow-y-auto pr-6">
          {/* Comments for job */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="job-comments"
            >
              Comments
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="job-comments"
              placeholder="Comments/Description/Thoughts ... "
              rows={8}
              name={'comments'}
              value={form.values.comments}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
            <p className="text-red-500 text-xs italic my-1">
              {form.errors.comments}
            </p>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex flex-1 items-center justify-between border-t border-gray-400 pt-3 px-1">
          <Button
            type={ButtonType.Secondary}
            text={'Cancel'}
            onClick={() => setModalStatus(false)}
          />
          <Button
            role={ButtonRole.Submit}
            type={ButtonType.Primary}
            text={'Send Feedback'}
          />
        </div>
      </form>
    </Modal>
  )
}

export default FeedbackModal
