import React, { useState, useEffect, useCallback, useRef } from 'react'

import { DotsVertical } from 'Svg'

type Children = (closeMenu: () => void) => React.ReactNode

function BurgerMenu({ children }: { children: Children }) {
  const [showMenu, setMenuStatus] = useState(false)
  const $menuEl = useRef(null)

  const handleMenuClick = useCallback(
    e => {
      const isMenuClicked = $menuEl.current === e.target

      if (!isMenuClicked) {
        // close the menu
        setMenuStatus(false)
      }
    },
    [$menuEl]
  )

  useEffect(() => {
    document.addEventListener('click', handleMenuClick)

    return () => document.removeEventListener('click', handleMenuClick)
  })

  return (
    <div
      className=""
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <div className="flex items-center justify-center ml-5">
        <button
          className="block h-10 w-10 rounded-full overflow-hidden border-2 focus:outline-none"
          onClick={e => {
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation()

            setMenuStatus(status => !status)
          }}
        >
          <div className="flex items-center justify-center h-full w-full text-gray-600 hover:bg-gray-300">
            <DotsVertical />
          </div>
        </button>
      </div>
      <div className="relative">
        {showMenu && (
          <div
            ref={$menuEl}
            className="absolute right-0 mt-1 py-2 w-64 bg-white rounded-sm shadow-md"
          >
            {children(() => setMenuStatus(false))}
          </div>
        )}
      </div>
    </div>
  )
}

export default BurgerMenu
