import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

const Question = styled.div`
  ${tw`flex text-2xl font-semibold text-gray-700 leading-relaxed my-5`}
`

const Answer = styled.div`
  ${tw`block text-lg font-normal text-gray-700 leading-relaxed my-3`}
`

const Bold = styled.span`
  ${tw`text-lg font-bold text-gray-800 leading-relaxed mx-1`}
`

function Faq() {
  return (
    <div className="flex flex-col my-3 border-b py-5 w-full">
      <div className="flex items-center my-5 justify-center text-3xl text-gray-700 font-semibold">
        FAQ
      </div>

      <div>
        <Question>What is Jublime?</Question>
        <Answer>
          Jublime is a <Bold>Job Search Organizer.</Bold> It helps you track and
          organize jobs in your job search.
        </Answer>
      </div>

      <div>
        <Question>For whom is this tool useful?</Question>
        <Answer>
          This tool is mainly useful for <Bold>active job seekers</Bold>and
          <Bold>passive job seekers</Bold>tracking interesting jobs. If you are
          a freelancer, who actively seeks out prospects all the time, this tool
          might be of use for you.
        </Answer>
      </div>

      <div>
        <Question>What can I do with this tool?</Question>
        <Answer>
          You can start a job search, add jobs to the search and track your
          current job search prospects. If you are not actively seeking job, you
          can add interesting jobs you come across. Later, when you actively
          seek a job, you can add the job to your search. You can set status of
          your job prospects, add comments on job search and job prospects. This
          tool can also act as your reference for your job search history.
        </Answer>
      </div>

      <div>
        <Question>Will you charge for this product?</Question>
        <Answer>
          Jublime will be <Bold>free, till we get over COVID-19 crisis.</Bold>
          After that, there will be a free version and a paid version with more
          features which will cost roughly around $5-$7 per month. Please
          contact me or check back later for detailed info on pricing.{' '}
        </Answer>
      </div>

      <div>
        <Question>
          I would like to suggest a feature for my use. What should I do?
        </Question>
        <Answer>
          Jublime is currently in <Bold>beta, seeking feedback.</Bold> Some
          features are hidden currently during beta phase. If you would like to
          suggest a new feature, please reach out to me at{' '}
          <Bold>arun@jublime.com</Bold>
        </Answer>
      </div>
    </div>
  )
}

export default Faq
