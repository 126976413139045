import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { isUndefined } from 'lodash-es'

import { AuthContext } from 'Context/Auth/'
import Form from './Form'

function ForgotPassword() {
  const { authStatus } = useContext(AuthContext)

  /*
   * NOTE:
   * authStatus => undefined
   * Data/Status is not yet fetched from server
   */

  if (isUndefined(authStatus)) {
    // do not proceed
    return null
  }

  const { token, user } = authStatus
  const isLoggedIn = token && user

  return isLoggedIn ? <Redirect to="/" /> : <Form />
}

export default ForgotPassword
