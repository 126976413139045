import axios from 'axios'
import { notify } from 'Util/Noty/'
import { debounce } from 'lodash'

import { Callback } from 'Types/Job/'

export function getAllViewedJobs({ successCallback, errorCallback }: Callback) {
  axios
    .get('/api/viewed_job/get_all')
    .then(({ data }) => {
      successCallback(data)
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to continue ...',
        })
      } else {
        notify({
          type: 'error',
          text: 'Error getting job details. Please try again.',
        })
      }

      errorCallback()
    })
}

interface UpdateProps extends Callback {
  action: 'add' | 'remove'
  jobId: string
}

function _updateViewedJob({
  jobId,
  action,
  successCallback,
  errorCallback,
}: UpdateProps) {
  axios
    .post(`/api/viewed_job/${action}`, {
      job: jobId,
    })
    .then(({ data }) => {
      successCallback(data)
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to continue ...',
        })
      } else {
        notify({
          type: 'error',
          text: 'Error getting job details. Please try again.',
        })
      }

      errorCallback()
    })
}

export const updateViewedJob = debounce(_updateViewedJob, 1111)
