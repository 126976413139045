import React, { useContext } from 'react'
import { isNil } from 'lodash'
import styled from 'styled-components/macro'
import tw from 'twin.macro'
import { useFormik } from 'formik'

import { X, Add } from 'Svg/'
import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import useRecentJobSearch from 'Hooks/Search/useRecentJobSearch'
import { ApiContext } from 'Context/Api/'
import { Tags, AuthContext } from 'Context/Auth/'

import TagSelect from 'Common/TagSelect'
import { JobSchema } from 'Util/Schema'
import CurrentSearch from './CurrentSearch'
import Name from './Name'
import Location from './Location'
import JobLink from './JobLink'
import CompanyUrl from './CompanyUrl'
import Comments from './Comments'

const Holder = styled.div`
  position: fixed;
  bottom: 1px;
  right: 1px;

  width: 50%;
  height: 60%;

  ${props => tw`border border-purple-700`};

  z-index: 6;
  background: white;
  border-radius: 4px;
`

const Header = styled.div`
  height: 5%;
`

const Body = styled.div`
  ${tw`px-2`}
  height: 85%;

  overflow-y: auto;
`

const Footer = styled.div`
  height: 10%;

  ${tw`flex flex-1 items-center justify-between border-t border-gray-400 px-2`}
`

interface Props {
  isOpen: boolean
  setModalStatus: React.Dispatch<React.SetStateAction<boolean>>
  afterAddCallback: (job_id: string) => void
}

function AddJob({ setModalStatus, afterAddCallback }: Props) {
  const recentSearch = useRecentJobSearch()
  const { addJob } = useContext(ApiContext)
  const { locations, tags, refreshTags } = useContext(AuthContext)

  const initialValues = {
    name: '',
    location_id: locations.length > 0 ? locations[0].id : undefined,
    company_url: '',
    job_url: '',
    comments: '',
    tags: [],
    job_search: !isNil(recentSearch) ? recentSearch.id : undefined,
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: JobSchema,
    onSubmit: (values, { resetForm }) => {
      addJob({
        job: values,
        successCallback: ({ job_id }: { job_id: string }) => {
          // refresh tags after adding job
          refreshTags()
          // close the modal
          setModalStatus(false)
          // reset the form
          resetForm()
          // call the after add callback with job id
          afterAddCallback(job_id)
        },
        errorCallback: () => {},
      })
    },
  })

  const isNameValid: boolean = !(form.touched.name && form.errors.name)
  const isCompanyURLValid: boolean = !(
    form.touched.company_url && form.errors.company_url
  )
  const isJobURLValid: boolean = !(form.touched.job_url && form.errors.job_url)

  return (
    <form
      className="flex flex-col mb-1 h-full text-base font-normal"
      onSubmit={form.handleSubmit}
    >
      <Holder>
        <Header>
          <div className="font-bold">{''}</div>
          <div
            className="flex justify-end mr-1 hover:opacity-75 cursor-pointer"
            onClick={() => setModalStatus(false)}
          >
            <X />
          </div>
        </Header>
        <Body>
          <CurrentSearch
            recentSearch={recentSearch}
            value={form.values.job_search}
            onChange={(id?: number) => {
              form.setFieldValue('job_search', id)
            }}
          />

          {/* Job Name */}
          <Name
            value={form.values.name}
            handleChange={form.handleChange}
            handleBlur={form.handleBlur}
            errors={!isNameValid ? form.errors.name : undefined}
          />

          {/* Job Location */}
          <Location
            locations={locations}
            value={form.values.location_id}
            handleChange={(value: number) => {
              form.setFieldValue('location_id', value)
            }}
          />

          {/* Job Link */}
          <JobLink
            value={form.values.job_url}
            handleChange={form.handleChange}
            handleBlur={form.handleBlur}
            errors={!isJobURLValid ? form.errors.job_url : undefined}
          />

          {/* Company URL */}
          <CompanyUrl
            value={form.values.company_url}
            handleChange={form.handleChange}
            handleBlur={form.handleBlur}
            errors={!isCompanyURLValid ? form.errors.company_url : undefined}
          />

          {/* Job Tags */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="job-tag"
            >
              Tags
            </label>
            <TagSelect
              tags={tags}
              value={form.values.tags}
              onChange={(values: Tags) => {
                form.setFieldValue('tags', values)
              }}
              autoFocus={false}
            />
          </div>

          {/* Comments for job */}
          <Comments
            value={form.values.comments}
            handleChange={form.handleChange}
            handleBlur={form.handleBlur}
          />
        </Body>
        <Footer>
          <Button
            role={ButtonRole.Button}
            type={ButtonType.Tertiary}
            text={'Cancel'}
            onClick={() => setModalStatus(false)}
          />
          <Button
            role={ButtonRole.Submit}
            type={ButtonType.Primary}
            text={'Add Job'}
            Icon={Add}
          />
        </Footer>
      </Holder>
    </form>
  )
}

const AddJobWrapper = (props: Props) => {
  if (!props.isOpen) {
    return null
  }

  return <AddJob {...props} />
}

export default AddJobWrapper
