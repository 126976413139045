import React, { useState, useEffect, useContext } from 'react'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'
import TimeAgo from 'timeago-react'

import { JobBoards } from 'Types/Enums/'
import { AuthContext } from 'Context/Auth/'
import Button, { ButtonSize, ButtonType } from 'Common/Button/'
import { Add } from 'Svg/'
import ExternalLink from 'Common/ExternalLink'
import {
  latestJobPostings,
  LatestPost,
  PostingResponse,
} from 'Util/Server/Explore'
import Pagination from './Pagination'
import AddJobModal from 'Common/Modals/AddJob/'
import { HackerNews, FourDay } from 'Svg/'
import { Loading } from 'Assets/Loaders'
import ViewedJobsContext from 'Context/ViewedJobs/'
import ViewJob from 'Components/ViewJob/'
import { getPostingURL } from 'Util/index'
import Filters from './Filters/'
import Gist from 'Components/Gist'
import JobBoardFeedback from 'Components/Feedback/GetJobBoard'
import ActiveSearchJobs from 'Components/ActiveSearchJobs/'
import { useLoginModal } from 'Context/LoginModal'
import SearchHighlighter from 'Components/SearchHighlighter/'

// helper function to show total jobs
function _showTotalInfo({
  page,
  total,
}: {
  page?: string | null
  total: number
}) {
  const current_page = page ? Number(page) : 1
  const PAGE_COUNT = 30

  const first = (current_page - 1) * PAGE_COUNT + 1
  let last = current_page * PAGE_COUNT

  if (last > total) {
    last = total
  }

  return (
    <div className={'my-4 text-sm'}>
      <span>{`Showing `}</span>
      <span className={'font-bold'}>{`${first} - ${last} `}</span>
      <span>{`of `}</span>
      <span className={'font-bold'}>{`${total} `}</span>
      <span>{`latest jobs.`}</span>
    </div>
  )
}

function Explore() {
  const location = useLocation()
  const params = new URLSearchParams(location.search)

  // query params
  const page = params.get('page')
  const source = params.get('source')
  const search = params.get('search')

  const [total, setTotal] = useState(0)
  const [postings, setPostings] = useState<Array<LatestPost>>([])

  const [isJobModalOpen, setJobModalStatus] = useState(false)

  const { showLoginModal } = useLoginModal()

  // loading/error state
  const [loading, setLoading] = useState(false)

  const { authStatus } = useContext(AuthContext)
  const isLoggedIn = authStatus && authStatus.token && authStatus.user

  useEffect(() => {
    setLoading(true)
    // fetch latest job postings
    latestJobPostings({
      page: page ? Number(page) : 1,
      source: source
        ? (source.split(',').map(x => x.trim()) as Array<JobBoards>)
        : undefined,
      search: search ? search.trim() : undefined,
      successCallback: (response: PostingResponse) => {
        setPostings(response.posts)
        setTotal(response.total)

        setLoading(false)
      },
      errorCallback: () => {
        setLoading(false)
      },
    })

    // scroll to top on page transition
    window.scrollTo(0, 0)
  }, [page, source, search])

  if (loading) {
    return <Loading />
  }

  return (
    <div className={'flex-none'}>
      <Filters />
      <div className={'flex'}>
        {!isEmpty(postings) ? (
          <div className={'w-3/5'}>
            <div>{_showTotalInfo({ page, total })}</div>
            <div>
              {postings.map(post =>
                isEmpty(post) ? null : (
                  <div
                    key={post.id}
                    className={
                      'text-black p-6 shadow text-sm leading-tight whitespace-pre-wrap'
                    }
                  >
                    {post.title && (
                      <div
                        className={
                          'text-black text-base pb-6 font-medium leading-snug'
                        }
                      >
                        <SearchHighlighter
                          highlight={search}
                          content={post.title}
                        />
                      </div>
                    )}
                    {/* <div>{post.content}</div> */}
                    <Gist content={post.content} highlight={search} />
                    <div className={'flex italic text-xs items-center mt-6'}>
                      <div className={'flex'}>
                        <span>{'Posted: '}</span>
                        <TimeAgo
                          datetime={Number(post.original_posting_time) * 1000}
                          opts={{
                            minInterval: 60,
                          }}
                        />
                      </div>

                      <div className={'flex ml-6 items-center justify-center'}>
                        <span className={'mr-1'}>
                          {getSourceIcon(post.source)}
                        </span>
                        <ExternalLink
                          text={'View'}
                          href={getPostingURL(post)}
                        />
                      </div>

                      <div
                        className={'flex ml-auto justify-center items-center'}
                      >
                        <div className={'ml-2'}>
                          <Button
                            text={'Save Job'}
                            size={ButtonSize.Tiny}
                            type={ButtonType.Secondary}
                            Icon={Add}
                            onClick={() => {
                              if (isLoggedIn) {
                                setJobModalStatus(true)
                              } else {
                                showLoginModal()
                              }
                            }}
                          />
                        </div>

                        <div
                          className={'flex ml-2 items-center justify-center'}
                        >
                          <ViewJob id={post.id} />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
            <Pagination total={total} />
            {isLoggedIn && (
              <AddJobModal
                isOpen={isJobModalOpen}
                setModalStatus={setJobModalStatus}
                afterAddCallback={() => {}}
              />
            )}
          </div>
        ) : (
          <div className={'w-3/5 pt-10'}>{'No results found'}</div>
        )}
        <div className={'w-2/5 pl-8 py-12 pr-0'}>
          <JobBoardFeedback />
          <ActiveSearchJobs />
        </div>
      </div>
    </div>
  )
}

function getSourceIcon(source: string) {
  switch (source) {
    case 'hn':
      return <HackerNews />

    case '4day':
      return <FourDay />

    default:
      return <HackerNews />
  }
}

const ExploreWrapper = () => (
  <ViewedJobsContext>
    <Explore />
  </ViewedJobsContext>
)

export default ExploreWrapper
