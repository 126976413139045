import styled from 'styled-components'
import tw from 'twin.macro'

export const Section = styled.div`
  ${tw`border-b border-gray-200 p-4 text-gray-700`}
`

export const Comment = styled.div`
  ${tw`italic text-xs leading-tight p-4 text-gray-800`};
  white-space: pre-line;
`

export const Actions = styled.div`
  ${tw`ml-auto flex gap-2`}
`

export const ActionIcon = styled.div`
  ${tw`flex w-8 h-8 text-gray-400 hover:text-purple-500 duration-300 cursor-pointer`}
`
