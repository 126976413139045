import React, { useEffect, useContext } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
// for css: refer to jublime.scss
// IMPORTANT: generated CSS
// GOTO: jublime.scss
import './css/app.scss'

// git commit info
import GitCommit from './_git_commit'

import { Email, Globe } from 'Svg/'
import Auth, { AuthContext } from 'Context/Auth/'
import ApiContext from 'Context/Api/'
import Header from 'Components/Header'
import Routes from './Routes'
import ScrollToTop from './ScrollToTop'
import Feedback from 'Components/Feedback/'
import BetaFeedback from 'Components/Feedback/BetaFeedback'
import LoginModalContext, { useLoginModal } from 'Context/LoginModal'
import { setupAxios } from 'Util/axios'

function App() {
  const { showLoginModal, hideLoginModal } = useLoginModal()
  const { authStatus } = useContext(AuthContext)
  const isLoggedIn = authStatus && authStatus.token && authStatus.user
  // setup axios first time app loads
  useEffect(() => {
    setupAxios({
      isLoggedIn: !!isLoggedIn,
      showLoginModal,
      hideLoginModal,
    })
  }, [authStatus, showLoginModal, hideLoginModal, isLoggedIn])

  return (
    <>
      <ApiContext>
        <BetaFeedback>
          <Router>
            <ScrollToTop />
            <Feedback />
            <div id="jublime-app" className="App container mx-auto">
              <Header />
              <div className="App-body">
                <Routes />
              </div>
            </div>
          </Router>
        </BetaFeedback>
      </ApiContext>
      <footer className="flex justify-between w-full mt-5 bg-purple-700 text-white self-end p-12 px-20 pb-24">
        <div>
          <div className="flex items-center self-end italic text-sm">
            <div className="w-5 h-5 mx-3">
              <Email />
            </div>
            {'arun@jublime.com'}
          </div>
          <div className="flex items-center self-end italic text-sm my-3">
            <div className="w-6 h-6 mx-3">
              <Globe />
            </div>
            <a
              className="flex items-center justify-center leading-relaxed hover:underline"
              href={'https://blog.jublime.com'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Blog
            </a>
          </div>
        </div>

        <div className="flex italic text-xs">
          {`v${process.env.REACT_APP_VERSION} (${GitCommit.git_sha})`}
        </div>
      </footer>
      <Toaster position="top-right" reverseOrder={false} />
    </>
  )
}

const JublimeApp = () => (
  <Auth>
    <LoginModalContext>
      <App />
    </LoginModalContext>
  </Auth>
)

export default JublimeApp
