import React, { useState } from 'react'

import { Search, Prospect } from 'Types/Search'
import { RecentJobDetail, JobInfo } from 'Types/Job/'

import { Tags, Locations } from 'Context/Auth/'
import {
  Tags as defaultTags,
  Locations as defaultLocations,
  JobSearch as defaultRecentSearch,
  Jobs as defaultRecentJobs,
} from 'Try/Data/'

interface DemoContextValue {
  tags: Tags
  locations: Locations
  recentSearch: Search
  recentJobs: RecentJobDetail[]

  // add a new job to our demo context
  addJob: (job: JobInfo) => void
  // edit job
  editJob: (job: JobInfo) => void
  // add job as prospect to recent search
  addJobAsProspect: (prospect: Prospect) => void
  // removes job prospect
  deleteProspect: (prospectId: number | string) => void
  // updates recent search
  updateRecentSearch: (search: Search) => void
}

const defaultValue: DemoContextValue = {
  tags: defaultTags,
  locations: defaultLocations,
  recentSearch: (defaultRecentSearch as any) as Search,
  recentJobs: (defaultRecentJobs as any) as RecentJobDetail[],

  addJob: () => {},
  editJob: () => {},
  addJobAsProspect: () => {},
  deleteProspect: () => {},
  updateRecentSearch: () => {},
}

export const DemoContext = React.createContext<DemoContextValue>(defaultValue)

function Context({ children }: { children: React.ReactNode }) {
  const [tags] = useState<Tags>(defaultTags)
  const [locations] = useState<Locations>(defaultLocations)
  const [recentJobs, setRecentJobs] = useState(
    (defaultRecentJobs as any) as RecentJobDetail[]
  )
  const [recentSearch, setRecentSearch] = useState<Search>(
    (defaultRecentSearch as any) as Search
  )

  return (
    <DemoContext.Provider
      value={{
        tags,
        locations,
        recentSearch,
        recentJobs,

        addJob: jobDetails => {
          setRecentJobs(currentJobs => {
            const { job_search, ...details } = jobDetails
            const jobDetail = details as RecentJobDetail
            return [
              {
                ...jobDetail,
                updated_at: new Date().toISOString(),
              },
              ...currentJobs,
            ]
          })
        },

        editJob: jobDetails => {
          setRecentJobs(currentJobs => {
            return currentJobs.map(job => {
              return job.id === jobDetails.id
                ? {
                    ...job,
                    ...jobDetails,
                    updated_at: new Date().toISOString(),
                  }
                : job
            })
          })
        },

        addJobAsProspect: prospectDetails => {
          setRecentSearch(currentSearch => {
            const current_prospects = currentSearch?.prospects as Prospect[]
            return {
              ...currentSearch,
              prospects: [
                {
                  ...prospectDetails,
                  updated_at: new Date().toISOString(),
                },
                ...current_prospects,
              ],
            }
          })
        },

        updateRecentSearch: (newDetails: Search) => {
          setRecentSearch(currentDetails => ({
            ...currentDetails,
            ...newDetails,
            updated_at: new Date().toISOString(),
          }))
        },

        // removes prospect from search
        deleteProspect: prospectId => {
          // remove prospect from recent search
          setRecentSearch(currentSearchDetails => {
            const updated_prospects = (
              currentSearchDetails?.prospects || []
            ).filter(p => p.id !== prospectId)
            return {
              ...currentSearchDetails,
              prospects: updated_prospects,
            }
          })
        },
      }}
    >
      {children}
    </DemoContext.Provider>
  )
}

export default Context
