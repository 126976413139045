import React, { useContext } from 'react'

import { AuthContext } from 'Context/Auth/'
import LoggedIn from './LoggedIn'
import PublicNav from './PublicNav'

function NavActions() {
  const { authStatus } = useContext(AuthContext)

  const isLoggedIn = authStatus && authStatus.token && authStatus.user

  return <div className="flex">{isLoggedIn ? <LoggedIn /> : <PublicNav />}</div>
}

export default NavActions
