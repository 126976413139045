import React, { useContext } from 'react'

import { AuthContext } from 'Context/Auth/'
import { ViewedJobsContext } from 'Context/ViewedJobs'
import { Eye, EyeClose } from 'Svg/'
import { useBetaFeedbackModal } from 'Components/Feedback/BetaFeedback'

interface Props {
  id: string
}

function ViewJob({ id }: Props) {
  const { authStatus } = useContext(AuthContext)
  const { isJobViewed, addViewed, removeViewed, updateViewed } = useContext(
    ViewedJobsContext
  )
  const isLoggedIn = authStatus && authStatus.token && authStatus.user
  const { showBetaFeedbackModal, BetaType } = useBetaFeedbackModal()

  const commonClasses =
    'ml-2 flex items-center justify-center w-5 h-5 cursor-pointer'

  if (!(isLoggedIn && authStatus?.user?.is_admin)) {
    // showing feedback modal
    return (
      <span
        onClick={() => {
          showBetaFeedbackModal({
            title: 'Mark Job Posting as Viewed',
            description:
              'This feature helps to mark a job posting as viewed. This is helpful to keep track of viewed job postings.',
            type: BetaType.ViewedJob,
          })
        }}
      >
        <span
          className={`${commonClasses} text-gray-400 hover:text-yellow-600`}
        >
          <EyeClose />
        </span>
      </span>
    )
  }

  return (
    <span
      onClick={() => {
        // optimistic update
        if (isJobViewed(id)) {
          removeViewed(id)
        } else {
          addViewed(id)
        }

        // actual 'debounced' api call
        updateViewed(id)
      }}
    >
      {isJobViewed(id) ? (
        <span className={`${commonClasses} text-yellow-600 bg-yellow-100`}>
          <Eye />
        </span>
      ) : (
        <span
          className={`${commonClasses} text-gray-400 hover:text-yellow-600`}
        >
          <EyeClose />
        </span>
      )}
    </span>
  )
}

export default ViewJob
