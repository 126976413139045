// IMPORTANT: String enums should be exported properly (ts/js) as proper module
// as it needs to present during runtime

// NOTE: list of prospect status
// IMPORTANT: Client maintains a complete list of prospect status (not server)
export enum ProspectStatus {
  InConversation = 'IN CONVERSATION',
  Applied = 'APPLIED',
  Interviewing = 'INTERVIEWING',
  Offer = 'OFFER',
  Accepted = 'ACCEPTED',

  Considering = 'CONSIDERING',
  NoReply = 'NO REPLY',
  NotInterested = 'NOT INTERESTED',
  Declined = 'DECLINED',
}

export enum SearchStatus {
  Active = 'ACTIVE',
  Concluded = 'CONCLUDED',
}

export enum JobBoards {
  HN = 'hn',
  _4Day = '4day',
}
