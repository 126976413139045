import axios from 'axios'
import { notify } from 'Util/Noty'

type ResetCallback = React.Dispatch<React.SetStateAction<boolean>>

export function sendResetInstructions(email: string, callback: ResetCallback) {
  axios
    .post(`/auth/send_reset_password_instructions/`, {
      email,
    })
    .then(({ data }) => {
      // show success message
      notify({
        type: 'success',
        text:
          'If your e-mail is in our system, you will receive instructions to reset your password shortly.',
      })
      callback(true)
    })
    .catch(err => {
      // show error message
      notify({
        type: 'error',
        text: 'We are not able to send reset instructions. Please try again',
      })
      callback(false)
    })
}

// resets password with given token
export function resetPassword(
  {
    token,
    password,
  }: {
    token: string
    password: string
  },
  successCallback: () => void
) {
  axios
    .post(`/auth/reset_password/`, {
      token,
      password,
    })
    .then(({ data }) => {
      // show success message
      notify({
        type: 'success',
        text: 'Password reset success. Please login with your new password',
        timeout: 7777,
      })
      // execute the success callback
      successCallback()
    })
    .catch(err => {
      // show error message
      notify({
        type: 'error',
        text: 'We are not able to reset the password. Please try again',
      })
    })
}
