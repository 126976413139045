import React, { useState, useEffect, useContext, useCallback } from 'react'
import { isUndefined, isNull } from 'lodash'
import { useParams, useHistory, Link } from 'react-router-dom'

import { Edit, ChevronLeft } from 'Svg/'
import { AuthContext } from 'Context/Auth/'
import { ApiContext } from 'Context/Api/'
import JobModal from 'Components/JobModal'
import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import { JobDetail as Job } from 'Types/Job/'
import { getJobDetails } from 'Util/Server/Job'
import useRecentJobSearch from 'Hooks/Search/useRecentJobSearch'
import Info from './Info'

export type JobDetails = Job | undefined | null

type JobDetailParams = {
  id: string
}

function JobDetail() {
  const history = useHistory()
  let { id } = useParams<JobDetailParams>()
  const recentSearch = useRecentJobSearch()
  const { _getSearchURL } = useContext(ApiContext)

  const [details, setDetails] = useState<JobDetails>(undefined)
  const { locations, tags } = useContext(AuthContext)

  // check if job is part of search
  const isPartOfRecentSearch =
    recentSearch &&
    recentSearch.prospects &&
    recentSearch?.prospects.find(p => p.job.id === details?.id)

  const [isJobModalOpen, setJobModalStatus] = useState(false)

  const location_id = details?.location_id
  const tag_ids = details?.tag_ids

  const _getLocation = useCallback(() => {
    const location = locations.find(l => l.id === location_id)

    return location ? location.name : ''
  }, [locations, location_id])

  const _getTags = useCallback(() => {
    return tags.filter(t => tag_ids?.split(',').includes(String(t.id)))
  }, [tags, tag_ids])

  useEffect(() => {
    getJobDetails({
      id,
      successCallback: (job_details: any) => {
        setDetails(job_details)
      },
      errorCallback: () => setDetails(null),
    })
  }, [id])

  if (isUndefined(details)) {
    return <div>Fetching job details</div>
  }

  if (isNull(details)) {
    return <div>Cannot find job details</div>
  }

  const tagsList = _getTags()
  const locationName = _getLocation()

  return (
    <div className="max-w-3xl pb-32">
      <div>
        <div
          className="flex items-center hover:underline text-gray-700 text-reg my-3 font-semibold cursor-pointer"
          onClick={() => history.push('/')}
        >
          <div className="w-6 h-6 -ml-2">
            <ChevronLeft />
          </div>
          <div className="">Home</div>
        </div>
        {isPartOfRecentSearch && recentSearch && (
          <div className="p-3 my-2 bg-green-200">
            {`'${recentSearch && recentSearch.name}' prospect.`}
            <span className="font-semibold underline mx-3 hover:text-gray-600">
              <Link to={_getSearchURL(recentSearch?.id)}>
                Continue Job Search
              </Link>
            </span>
          </div>
        )}
      </div>

      <Info details={details} tagsList={tagsList} locationName={locationName} />

      <div>
        <Button
          onClick={() => setJobModalStatus(true)}
          role={ButtonRole.Button}
          type={ButtonType.Primary}
          text={'Edit Job'}
          Icon={Edit}
        />
      </div>
      <JobModal
        mode={'edit'}
        isOpen={isJobModalOpen}
        setStatus={setJobModalStatus}
        locations={locations}
        tags={tags}
        jobDetails={details}
        setJobDetails={setDetails}
        recentSearch={recentSearch}
      />
    </div>
  )
}

export default React.memo(JobDetail)
