import axios from 'axios'
import { notify } from 'Util/Noty'

interface Subscription {
  email: string
  source?: string
  url?: string
}

export function emailSubscription(payload: Subscription, callback: () => void) {
  axios
    .post(`/public_api/email_subscription/`, {
      ...payload,
    })
    .then(({ data }) => {
      notify({
        type: 'success',
        text: 'Thank you for subscription.',
      })
      callback()
    })
    .catch(err => {
      notify({
        type: 'error',
        text: 'Problem reaching our servers. Please try again',
      })
    })
}
