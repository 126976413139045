import styled from 'styled-components'
import tw from 'twin.macro'

import { HEADER_HEIGHT_DIMENSION } from 'Components/Header'

export const Holder = styled.div`
  ${tw`my-4 p-2 border rounded shadow`};

  position: sticky;
  top: ${2 * HEADER_HEIGHT_DIMENSION + 'px'};

  & .job-holder {
    height: auto;
    max-height: 314px;
    overflow-y: auto;

    & .job {
      ${tw`flex underline cursor-pointer text-gray-600 font-semibold text-base py-1`}
    }
  }
`

export const Title = styled.a`
  ${tw`font-medium text-lg text-purple-700 cursor-pointer hover:underline`}
`

export const SearchBox = styled.input`
  ${tw`flex border rounded w-full border border-transparent focus:shadow p-2 focus:outline-none focus:border-purple-600 focus:border my-2`};
`
