import React, { useContext } from 'react'
import { useFormik } from 'formik'
import { useHistory, Redirect } from 'react-router-dom'

import GoogleLogin from 'Pages/Login/GoogleLogin/'
import { AuthContext } from 'Context/Auth/'
import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import { registerUser } from 'Util/Server/Auth'

interface FormErrors {
  email?: string
  password?: string
  confirmPassword?: string
}

function SignupForm() {
  const history = useHistory()
  const { updateAuthStatus } = useContext(AuthContext)

  const form = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validate: values => {
      const errors: FormErrors = {}

      // email validation
      if (!values.email) {
        errors.email = 'Please enter email ...'
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = 'Invalid email address'
      }

      // password validation
      if (!values.password) {
        errors.password = 'Please enter a password (min 7 characters)'
      } else if (values.password.length < 7) {
        errors.password = 'Password should be atleast 7 characters'
      }

      // confirm password validation
      if (!values.confirmPassword) {
        errors.confirmPassword = 'Please confirm the password'
      } else if (values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Password does not match'
      }

      return errors
    },
    onSubmit: values => {
      // take necessary values
      const { email, password } = values

      registerUser({
        email,
        password,
        setAuthStatus: updateAuthStatus,
        successCallback: () => {
          // go to home page
          history.push('/')
        },
        errorCallback: () => {},
      })
    },
  })

  const isEmailValid: boolean = !(form.touched.email && form.errors.email)
  const isPasswordValid: boolean = !(
    form.touched.password && form.errors.password
  )
  const isConfirmPasswordValid: boolean = !(
    form.touched.confirmPassword && form.errors.confirmPassword
  )

  return (
    <div className="w-full">
      <div className="max-w-xl mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        {/* Google Login */}
        <GoogleLogin />

        {/* Create Account form */}
        <form
          className="my-5 mt-8 border-t border-gray-200 pt-5"
          onSubmit={form.handleSubmit}
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className={isEmailValid ? 'form-ok' : 'form-notok'}
              id="email"
              name={'email'}
              type="email"
              placeholder="Email ..."
              value={form.values.email}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
            {!isEmailValid && (
              <p className="text-red-500 text-xs italic">{form.errors.email}</p>
            )}
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className={isPasswordValid ? 'form-ok' : 'form-notok'}
              id="password"
              name={'password'}
              type="password"
              placeholder="******************"
              value={form.values.password}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
            {!isPasswordValid && (
              <p className="text-red-500 text-xs italic">
                {form.errors.password}
              </p>
            )}
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Confirm Password
            </label>
            <input
              className={isConfirmPasswordValid ? 'form-ok' : 'form-notok'}
              id="confirm-password"
              name={'confirmPassword'}
              type="password"
              placeholder="******************"
              value={form.values.confirmPassword}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
            {!isConfirmPasswordValid && (
              <p className="text-red-500 text-xs italic">
                {form.errors.confirmPassword}
              </p>
            )}
          </div>
          <div className="flex items-center justify-between">
            <Button
              role={ButtonRole.Submit}
              type={ButtonType.Primary}
              text={'Create Account'}
            />
            <Button type={ButtonType.Outline} text={'Forgot Password?'} />
          </div>
        </form>
      </div>
    </div>
  )
}

function Signup() {
  const { authStatus } = useContext(AuthContext)
  const isLoggedIn = authStatus && authStatus.user && authStatus.token

  return isLoggedIn ? <Redirect to={'/'} /> : <SignupForm />
}

export default Signup
