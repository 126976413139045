import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { startCase, isEmpty, intersection } from 'lodash-es'

import { AuthContext, Tags } from 'Context/Auth/'
import { ProspectStatus } from 'Types/Enums/'
import { Prospect } from 'Types/Search/'
import JobProspect from 'Components/JobSearch/Prospect/'
import TagSelect from 'Common/TagSelect'

const TabHolder = styled.div`
  display: flex;
  flex-direction: row;
  ${tw`my-3`}
`

const Tab = styled.div<{
  active: boolean
}>`
  ${tw`border border-purple-300 p-3 px-5 font-semibold`}
  ${({ active }) => {
    return active ? tw`bg-purple-700 text-white` : tw`text-purple-700`
  }}

  cursor: pointer;
`

interface Props {
  prospects: Array<Prospect>
  updateSearchDetails: () => void
}

function scrollToHash() {
  /* Obtain hash from current location (and trim off leading #) */
  const id = window.location.hash.substr(1)

  if (id) {
    /* Find matching element by id */
    const anchor = document.getElementById(id)

    if (anchor) {
      /* Scroll to that element if present */
      // let us scroll with a delay
      setTimeout(() => {
        anchor.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'end',
        })
      }, 314)
    }
  }
}

function Prospects({ prospects, updateSearchDetails }: Props) {
  // filter by status - 'Applied/Interviewing'
  const [filter, setFilter] = useState<ProspectStatus | null>(null)
  // search by tags
  const [searchTags, setSearchTags] = useState<Tags>([])
  const [currentProspects, setCurrentProspects] = useState(prospects)
  const [allProspectsCount, setAllPropspectsCount] = useState(prospects.length)

  const { tags } = useContext(AuthContext)

  const noFilter = filter === null

  useEffect(() => {
    if (isEmpty(filter)) {
      /*
       * case 1: no filter and no search tags
       */
      if (isEmpty(searchTags)) {
        setCurrentProspects(prospects)

        return
      }

      /*
       * case 2: we have search tags
       * we need to match prospects for which tags are matched
       * prospects with maximum tag match will be shown on top
       */

      const search_tag_ids = searchTags.map(s => s.id).map(s => Number(s))

      let filtered: Prospect[] = []

      // track last match count
      let last_match_count = 0

      prospects.forEach(prospect => {
        const tag_info = (prospect?.job?.tag_ids || '')
          .split(',')
          .map(t => Number(t))

        // number of tags matched
        const match_count = intersection(search_tag_ids, tag_info).length

        if (match_count === 0) {
          // do not proceed; no match
          return
        }

        // we have a match
        // let us compare the match with first element of our list
        // if the match count is bigger; we will add as first
        // if it is smaller; we will add it to last (sorting by descending order)

        if (match_count >= last_match_count) {
          // more match; addding it to start
          filtered.unshift(prospect)
          // update last_match_count
          last_match_count = match_count
        } else {
          // less match
          // adding it to last
          filtered.push(prospect)
        }
      })

      // update prospects with matched filters
      setCurrentProspects(filtered)
      // update all prospects count
      setAllPropspectsCount(filtered.length)
    } else {
      /*
       * we have some filter - Applied/Interviewing
       * showing prospects for this filter
       */
      const filteredProspects = prospects.filter(p => p.status === filter)
      setCurrentProspects(filteredProspects)
    }
  }, [prospects, filter, searchTags])

  // go to prospect if 'hash/id' present in the url
  // ref: https://stackoverflow.com/questions/57607378/reactjs-go-to-anchor-link-in-html-with-react-router-dom
  useEffect(() => {
    if (isEmpty(prospects)) {
      return
    }

    // we have prospects loaded
    // we need to scroll to prospect if we have hash/id in the route
    scrollToHash()
  }, [prospects])

  const TAB_FILTERS = [
    ProspectStatus.Applied,
    ProspectStatus.InConversation,
    ProspectStatus.Interviewing,
    ProspectStatus.Offer,
  ]

  const TABS = TAB_FILTERS.map(t => ({
    key: t,
    value: startCase(t.toLowerCase()),
    count: prospects.filter(p => p.status === t).length,
  }))

  return (
    <div>
      <TabHolder>
        <Tab
          active={filter === null}
          onClick={() => {
            setFilter(null)
          }}
        >{`All (${allProspectsCount})`}</Tab>
        {TABS.map(tab => (
          <Tab
            key={tab.key}
            active={filter === tab.key}
            onClick={() => {
              setFilter(tab.key)
            }}
          >
            {tab.value}
            {` (${tab.count})`}
          </Tab>
        ))}
      </TabHolder>

      {noFilter && (
        <div className={`mb-3`}>
          {searchTags.length > 0 && (
            <div className={'italic text-sm mb-2 text-gray-700'}>
              <span>
                {`Searching prospects matching ${searchTags.length} ${
                  searchTags.length > 1 ? 'tags' : 'tag'
                }`}
              </span>

              <button
                className={`hover:underline italic mx-3 border-none text-purple-700 font-semibold text-xs`}
                onClick={() => setSearchTags([])}
              >{`Clear All`}</button>
            </div>
          )}
          <TagSelect
            tags={tags}
            value={searchTags}
            onChange={changed => {
              setSearchTags(changed)
            }}
            creatable={false}
            placeholder={'Filter prospects by tags'}
          />
        </div>
      )}

      <div>
        {currentProspects?.map(prospect => (
          <JobProspect
            key={prospect.id}
            prospect={prospect}
            updateSearchDetails={updateSearchDetails}
          />
        ))}
      </div>
    </div>
  )
}

export default Prospects
