import React from 'react'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

import { X } from 'Svg/'
import Button, { ButtonType, ButtonRole } from 'Common/Button/'

interface Props {
  headerText?: string
  text?: string
  buttonText?: string
  cancelButtonText?: string

  successCallback?: () => void
  errorCallback?: () => void
}

export const confirm = ({
  headerText,
  text,
  buttonText,
  cancelButtonText,

  successCallback,
  errorCallback,
}: Props) =>
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="flex justify-center flex-col w-full border">
          <div className="flex flex-1 w-full text-gray-700 border-b mb-1 p-5">
            <div className="font-bold text-2xl">
              {headerText || 'Are you sure?'}
            </div>
            <div
              className="ml-auto hover:opacity-75 cursor-pointer"
              onClick={() => onClose()}
            >
              <X />
            </div>
          </div>
          <div className="flex flex-col p-5">
            <p>{text || ''}</p>
          </div>
          <div className="flex justify-between border-t p-5">
            <Button
              role={ButtonRole.Button}
              type={ButtonType.Secondary}
              text={cancelButtonText || 'Cancel'}
              onClick={() => {
                errorCallback && errorCallback()
                onClose()
              }}
            />
            <Button
              role={ButtonRole.Button}
              type={ButtonType.PrimaryDark}
              text={buttonText || 'Proceed'}
              onClick={() => {
                successCallback && successCallback()
                onClose()
              }}
            />
          </div>
        </div>
      )
    },
  })
