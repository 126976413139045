import axios from 'axios'
import { notify } from 'Util/Noty/'

import { handleHTTPErrors } from 'Util/axios'
import { Callback } from 'Types/'
import { JobBoards } from 'Types/Enums/'

interface PostingPayload extends Callback {
  page: number
  source?: Array<JobBoards>
  search?: string
}

export interface LatestPost {
  id: string
  title?: string
  content?: string
  original_posting_time?: string
  scraped_time?: string
  source: JobBoards
  posting_url?: string
}

export interface PostingResponse {
  posts: Array<LatestPost>
  total: number
}

export function latestJobPostings({
  page,
  source,
  search,
  successCallback,
  errorCallback,
}: PostingPayload) {
  axios
    .post('/public_api/explore_latest', {
      page: Number(page) || 1,
      source,
      search,
    })
    .then(({ data }) => {
      const response: PostingResponse = data
      successCallback(response)
    })
    .catch(err => {
      handleHTTPErrors(err, {
        errorHandler: () => {
          notify({
            type: 'error',
            text: 'Error getting latest job postings. Please try again',
          })
        },
      })

      errorCallback()
    })
}
