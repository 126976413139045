import React from 'react'
import toast from 'react-hot-toast'
import styled from 'styled-components'
import tw from 'twin.macro'

import { BadgeCheck, Exclamation, Info } from 'Svg/'

const IconWrapper = styled.div`
  ${tw`flex justify-center items-center flex-shrink-0 text-white rounded-full p-1`}
`

const default_options: NotifyOptions = {
  type: 'success',
  text: '',
  timeout: 3333,
}

interface NotifyOptions {
  type: 'success' | 'error' | 'info'
  text: string
  title?: string
  timeout?: number
}

function getTitle(type: NotifyOptions['type']) {
  switch (type) {
    case 'success':
      return 'Success!'

    case 'error':
      return 'Error'

    case 'info':
      return 'Attention!'

    default:
      return 'Attention!'
  }
}

function getIcon(type: NotifyOptions['type']) {
  switch (type) {
    case 'success':
      return (
        <IconWrapper className={'bg-green-400'}>
          <BadgeCheck size={'Medium'} />
        </IconWrapper>
      )

    case 'error':
      return (
        <IconWrapper className={'bg-red-400'}>
          <Exclamation size={'Medium'} />
        </IconWrapper>
      )

    case 'info':
      return (
        <IconWrapper className={'bg-purple-500'}>
          <Info size={'Medium'} />
        </IconWrapper>
      )

    default:
      return (
        <IconWrapper className={'bg-purple-500'}>
          <Info size={'Medium'} />
        </IconWrapper>
      )
  }
}

export function notify(opts: NotifyOptions) {
  const options: NotifyOptions = {
    ...default_options,
    ...opts,
  }

  // ref: https://react-hot-toast.com/docs/toast
  switch (options.type) {
    default: {
      toast.custom(
        t => (
          <div
            className={`border border-gray-200 ${
              t.visible ? 'animate-enter' : 'animate-leave'
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                {getIcon(options.type)}
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">
                    {options.title || getTitle(options.type)}
                  </p>
                  <p className="mt-1 text-sm text-gray-500">{options.text}</p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-purple-600 hover:text-purple-600 hover:bg-gray-100 focus:outline-none"
              >
                Close
              </button>
            </div>
          </div>
        ),
        {
          duration: options.timeout,
          position: 'top-right',
        }
      )

      return
    }
  }
}
