import React, { useContext } from 'react'
import { omit } from 'lodash'
import { useHistory } from 'react-router-dom'
import Modal from 'react-modal'
import { useFormik } from 'formik'
import Select from 'react-select'

import { ApiContext } from 'Context/Api/'
import { X } from 'Svg/'
import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import { Locations, Tags, AuthContext } from 'Context/Auth/'
import LocationDropdown from 'Common/LocationDropdown/'
import TagSelect, { Values } from 'Common/TagSelect'
import { JobDetail } from 'Types/Job/'
import { RecentSearch } from 'Types/Search'
import { JobSchema } from 'Util/Schema'

// bind modal to root element
Modal.setAppElement('#root')

const customStyles = {
  overlay: {
    background: 'rgba(50, 50, 100, 0.9)',
    // 51, 40, 85
  },
  content: {
    top: '10%',
    left: '15%',
    width: '70%',
    height: '80%',
    right: 'auto',
    bottom: 'auto',

    // marginRight: '-50%',
    // transform: 'translate(-50%, -20%)',
    background: '#FCFCFC',
    opacity: 1,
    boxShadow:
      '0 4px 6px -1px rgba(255, 255, 255, 0.1), 0 2px 4px -1px rgba(255, 255, 255, 0.06)',
  },
}

interface Props {
  mode: 'edit' | 'create'

  isOpen: boolean
  setStatus: Function

  locations: Locations
  tags: Values

  jobDetails?: JobDetail
  setJobDetails?: Function

  recentSearch: RecentSearch
}

function JobModal({
  mode,

  isOpen,
  setStatus,

  locations,
  tags,

  jobDetails,
  setJobDetails,

  recentSearch,
}: Props) {
  const history = useHistory()
  const { refreshTags } = useContext(AuthContext)
  const { addJob, editJob } = useContext(ApiContext)

  // calculate initial values
  const initialValues =
    mode === 'create'
      ? {
          name: '',
          location_id: locations.length > 0 ? locations[0].id : undefined,
          company_url: '',
          job_url: '',
          comments: '',
          tags: [],
          job_search: recentSearch && recentSearch.id ? recentSearch.id : null,
        }
      : Object.assign(
          {
            job_search: null,
          },
          omit(jobDetails, ['tag_ids']),
          {
            tags: tags.filter(t =>
              jobDetails?.tag_ids?.split(',').includes(String(t.id))
            ),
          }
        )

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: JobSchema,
    onSubmit: (values, { resetForm }) => {
      const isCreateMode = mode === 'create'
      const isEditMode = mode === 'edit'

      if (isCreateMode) {
        addJob({
          job: values,
          successCallback: ({
            job_id,
            blockNavigation,
          }: {
            job_id: string
            blockNavigation?: boolean
          }) => {
            // refresh tags after adding job
            refreshTags()
            // close the modal
            setStatus(false)
            // reset the form
            resetForm()
            // redirect to job page (if not explicitly blocked)
            // we will use this switch to redirect to different page for demo
            if (!blockNavigation) {
              history.push(`/job/detail/${job_id}`)
            }
          },
          errorCallback: () => {},
        })
        // do not proceed
        return
      }

      if (isEditMode) {
        editJob({
          job: {
            id: jobDetails?.id,
            ...values,
          },
          successCallback: ({ job }: { job: JobDetail }) => {
            // refresh tags after adding job
            refreshTags()
            // close the modal
            setStatus(false)
            // update job details
            setJobDetails && setJobDetails(job)
            // reset the form
            resetForm()
          },
          errorCallback: () => {},
        })
        // do not proceed
        return
      }
    },
  })

  const isNameValid: boolean = !(form.touched.name && form.errors.name)
  const isCompanyURLValid: boolean = !(
    form.touched.company_url && form.errors.company_url
  )
  const isJobURLValid: boolean = !(form.touched.job_url && form.errors.job_url)

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={() => setStatus(false)}
    >
      <form className="flex flex-col mb-1 h-full " onSubmit={form.handleSubmit}>
        <div className="flex flex-1 w-full items-center text-gray-700 border-b border-gray-400 mb-3 px-1 py-3">
          <div className="font-bold text-2xl">
            {mode === 'create' ? 'Add Job' : 'Edit Job'}
          </div>
          <div
            className="ml-auto hover:opacity-75 cursor-pointer"
            onClick={() => setStatus(false)}
          >
            <X />
          </div>
        </div>
        <div className="flex flex-col flex-grow px-1 w-full overflow-y-auto pr-6">
          {/* Add to Recent Job Search */}
          {mode === 'create' && recentSearch && recentSearch.id && (
            <div className="flex flex-col border-b border-gray-300 pb-5 mb-3">
              {form.values.job_search && (
                <label className="block text-gray-700 text-reg font-semibold mb-2">
                  {'Add job to search'}
                </label>
              )}
              <Select
                isClearable={false}
                isSearchable={false}
                options={[
                  {
                    label: 'Do not add job to current search',
                    value: null,
                  },
                  {
                    label: recentSearch.name,
                    value: recentSearch.id,
                  },
                ]}
                value={
                  form.values.job_search
                    ? {
                        label: recentSearch.name,
                        value: recentSearch.id,
                      }
                    : {
                        label: 'Do not add job to search',
                        value: null,
                      }
                }
                onChange={(l: any) => {
                  form.setFieldValue('job_search', l.value)
                }}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,

                    primary: '#9f7aea',
                    primary75: '#b794f4',
                    primary50: '#d6bcfa',
                    primary25: '#e9d8fd',
                  },
                })}
              />
            </div>
          )}

          {/* Job Name */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="job-name"
            >
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="job-name"
              type="text"
              placeholder="Job name ... "
              name={'name'}
              value={form.values.name}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
            {!isNameValid && (
              <p className="text-red-500 text-xs italic my-1">
                {form.errors.name}
              </p>
            )}
          </div>

          {/* Job Location */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="job-location"
            >
              Location
            </label>
            <LocationDropdown
              locations={locations}
              value={form.values.location_id}
              onChange={(value: number) => {
                form.setFieldValue('location_id', value)
              }}
            />
          </div>

          {/* Job Link */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="job-link"
            >
              Where to apply
            </label>
            {/* <div className="italic text-xs text-gray-700">
              Enter Job URL or email address
            </div> */}
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="job-link"
              type="text"
              placeholder="Enter Job URL or email address"
              name={'job_url'}
              value={form.values.job_url}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
            {!isJobURLValid && (
              <p className="text-red-500 text-xs italic my-1">
                {form.errors.job_url}
              </p>
            )}
          </div>

          {/* Company URL */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="company-site"
            >
              Company URL
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="company-site"
              type="text"
              placeholder="Website of company ... "
              name={'company_url'}
              value={form.values.company_url}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
            {!isCompanyURLValid && (
              <p className="text-red-500 text-xs italic my-1">
                {form.errors.company_url}
              </p>
            )}
          </div>

          {/* Job Tags */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="job-tag"
            >
              Tags
            </label>
            <TagSelect
              tags={tags}
              value={form.values.tags}
              onChange={(values: Tags) => {
                form.setFieldValue('tags', values)
              }}
            />
          </div>

          {/* Comments for job */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="job-comments"
            >
              Comments
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="job-comments"
              placeholder="Comments/Description/Thoughts ... "
              rows={7}
              name={'comments'}
              value={form.values.comments}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex flex-1 items-center justify-between border-t border-gray-400 pt-3 px-1">
          <Button
            role={ButtonRole.Button}
            type={ButtonType.Tertiary}
            text={'Cancel'}
            onClick={() => setStatus(false)}
          />

          <Button
            role={ButtonRole.Submit}
            type={ButtonType.Primary}
            text={mode === 'create' ? 'Add Job' : 'Update Job'}
          />
        </div>
      </form>
    </Modal>
  )
}

export default JobModal
