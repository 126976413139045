import React from 'react'

import LocationDropdown from 'Common/LocationDropdown/'
import { Locations } from 'Context/Auth'

interface Props {
  locations: Locations
  value?: number
  handleChange: (value: number) => void
}

function Location({ locations, value, handleChange }: Props) {
  return (
    <div className="mb-4">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="job-location"
      >
        Location
      </label>
      <LocationDropdown
        locations={locations}
        value={value}
        onChange={handleChange}
      />
    </div>
  )
}

export default Location
