import { notify } from 'Util/Noty/'
import { get } from 'lodash-es'

interface AxiosErrorResponse {
  status: number
  data: Object
}

export function handleRegistrationErrors(
  response: AxiosErrorResponse,
  payload: {
    email: string
  }
) {
  const { status, data } = response
  const { email } = payload

  switch (status) {
    case 401: {
      const isEmailTaken: boolean =
        get(data, 'errors.email[0]') === 'has already been taken'

      if (isEmailTaken) {
        notify({
          type: 'error',
          text: `<strong>${email}</strong> has already been taken.`,
          timeout: 5555,
        })
        // do not proceed
        return
      }

      notify({
        type: 'error',
        text: 'Unauthorized',
      })

      break
    }
    default: {
      notify({
        type: 'error',
        text: 'Error in registering user',
      })
      break
    }
  }
}

export function handleLoginErrors(response: AxiosErrorResponse) {
  const { status } = response

  switch (status) {
    case 401: {
      notify({
        type: 'error',
        text: 'Invalid credentials',
      })

      break
    }
    default: {
      notify({
        type: 'error',
        text: 'Error logging in user. Please try again',
      })
      break
    }
  }
}
