import React, { useState, useContext, createContext } from 'react'
import Modal from 'react-modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { AuthContext } from 'Context/Auth/'
import { modalStyles, InfoBanner } from 'Util/Styles'
import { X, UserGroup, UserAdd } from 'Svg/'
import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import { sendFeedback } from 'Util/Server/Feedback'

// bind modal to root element
Modal.setAppElement('#root')

type Props = {
  children: React.ReactNode
}

export enum BetaType {
  ViewedJob = 'Viewed Job',
  SearchJobBoard = 'Search Job Board',
}

type MetaInfo = {
  title: string
  description: string
  type?: BetaType
}

const defaultMetaInfo: MetaInfo = {
  title: '',
  description: '',
}

type ContextValue = {
  showBetaFeedbackModal: (metaInfo: MetaInfo) => void
  hideBetaFeedbackModal: () => void
}

const defaultContextValue: ContextValue = {
  showBetaFeedbackModal: (meta: MetaInfo) => {},
  hideBetaFeedbackModal: () => {},
}

export const BetaFeedbackContext = createContext<ContextValue>(
  defaultContextValue
)

function InterestedLink({ isValid }: { isValid: boolean }) {
  return (
    <Button
      Icon={UserAdd}
      role={ButtonRole.Submit}
      type={ButtonType.Primary}
      text={"I'm interested. Notify me."}
      disabled={!isValid}
    />
  )
}

function BetaFeedback({ children }: Props) {
  const { authStatus } = useContext(AuthContext)
  const isLoggedIn = authStatus && authStatus.token && authStatus.user

  const [metaInfo, setMetaInfo] = useState<MetaInfo>(defaultMetaInfo)
  const [isOpen, setModalStatus] = useState<boolean>(false)

  const Schema = Yup.object().shape({
    email: isLoggedIn
      ? Yup.string().email('Please enter a valid email')
      : Yup.string()
          .email('Please enter a valid email')
          .required('Please enter a valid email'),
    source: Yup.string(),
    url: Yup.string(),
  })

  const initialValues = {
    email: '',
    source: 'PRIVATE-BETA',
    url: window.location.href,
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      const payload = {
        url: window.location.href,
        source: 'PRIVATE-BETA',
        feedback: `[PRIVATE-BETA:${metaInfo.type}] - ${
          isLoggedIn
            ? `User: ${authStatus.user?.id}`
            : `${values.email}, public person/email`
        }`,
      }

      sendFeedback({
        payload,
        successCallback: () => {
          resetForm()
          setModalStatus(false)
        },
        errorCallback: () => {},
      })
    },
  })

  return (
    <BetaFeedbackContext.Provider
      value={{
        hideBetaFeedbackModal: () => {
          setModalStatus(false)
        },
        showBetaFeedbackModal: meta => {
          // set meta info
          setMetaInfo(meta)
          // open the modal
          setModalStatus(true)
        },
      }}
    >
      {children}
      <Modal
        isOpen={isOpen}
        style={modalStyles}
        onRequestClose={() => {
          form.resetForm()
          setModalStatus(false)
        }}
      >
        <form className="flex flex-col h-full" onSubmit={form.handleSubmit}>
          <div className="flex flex-1 w-full items-center text-gray-600 border-b border-gray-300 mb-3 px-1 py-3">
            <div className="font-bold text-xl">{`${metaInfo.title} - Private Beta`}</div>
            <div
              className="ml-auto hover:opacity-75 cursor-pointer"
              onClick={() => setModalStatus(false)}
            >
              <X />
            </div>
          </div>

          <div className={'pb-8 h-auto max-h-96 overflow-y-scroll pr-4 pl-1'}>
            <InfoBanner>
              <div className={'flex'}>
                <div className={'text-gray-600 text-lg mx-4'}>
                  <UserGroup />
                </div>
                <div className={'w-full'}>
                  <div>
                    {'This feature is in '}
                    <strong>{'private beta.'}</strong>
                  </div>
                  <div
                    className={
                      'leading-relaxed whitespace-pre-wrap py-2 mt-2 border-t border-gray-400'
                    }
                  >
                    {metaInfo.description}
                  </div>
                </div>
              </div>
            </InfoBanner>

            {isLoggedIn ? (
              <div>
                <div className={'italic text-md my-4 leading-relaxed'}>
                  {
                    "If you would like to be notified about this feature, please click the 'Notify' button."
                  }
                </div>
                <InterestedLink isValid={form.isValid} />
              </div>
            ) : (
              <div>
                <div className={'italic text-md my-4 leading-relaxed'}>
                  {
                    "If you would like to be notified about this feature, please enter your email and click the 'Notify' button."
                  }
                  {'If you have an account '}
                  <strong>{'please login '}</strong>
                  {'and check out this feature for early access.'}
                </div>
                <div className="flex flex-grow">
                  <input
                    className="rounded w-96 p-3 mb-2 border focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                    type="text"
                    name={'email'}
                    placeholder="Please enter your Email ..."
                    value={form.values.email}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />
                </div>
                {!form.isValidating &&
                  form.touched.email &&
                  form.errors.email && (
                    <p className="text-red-500 text-xs italic my-1">
                      {form.errors.email}
                    </p>
                  )}
                <div>
                  <InterestedLink isValid={form.isValid} />
                </div>
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex flex-1 items-center justify-between border-t border-gray-300 pt-2 px-1">
            <Button
              type={ButtonType.Secondary}
              text={'Cancel'}
              onClick={() => setModalStatus(false)}
            />
            <Button
              Icon={UserAdd}
              role={ButtonRole.Submit}
              type={ButtonType.Primary}
              text={'Notify me'}
              disabled={!form.isValid}
            />
          </div>
        </form>
      </Modal>
    </BetaFeedbackContext.Provider>
  )
}

export default BetaFeedback

// helper hook for easy access of beta feedback modal
export function useBetaFeedbackModal() {
  const { showBetaFeedbackModal, hideBetaFeedbackModal } = useContext(
    BetaFeedbackContext
  )

  return {
    BetaType,
    showBetaFeedbackModal,
    hideBetaFeedbackModal,
  }
}
