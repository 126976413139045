import axios from 'axios'
import { notify } from 'Util/Noty'

export type Status = {
  type: string
  message?: string
}

type Callback = React.Dispatch<React.SetStateAction<Status>>

export function confirmUserEmail(token: string, callback: Callback) {
  axios
    .get(`/auth/confirm_email/${token}`)
    .then(({ data }) => {
      callback({
        type: 'CONFIRMED',
        message: `${data.user.email} confirmed successfully!`,
      })
    })
    .catch(err => {
      callback({
        type: 'FAILURE',
        message: 'We are not able to confirm this email.',
      })
    })
}

// resend confirmation for logged in user
export function resendConfirmation() {
  axios
    .get(`/api/resend_confirmation_email/`)
    .then(({ status, data }) => {
      const email = data.email
      if (status === 208) {
        notify({
          type: 'error',
          text: email
            ? `${email} already confirmed!`
            : 'Email already confirmed',
        })
        // do not proceed
        return
      }

      notify({
        type: 'success',
        text: email
          ? `Confirmation email sent to ${email}.`
          : 'Confirmation email sent',
      })
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to continue ...',
        })
      } else {
        notify({
          type: 'error',
          text: 'Error sending confirmation email. Please try again.',
        })
      }
    })
}
