import React from 'react'
import TimeAgo, { TDate } from 'timeago-react'

import { JobDetail } from 'Types/Job/'
import { Tags } from 'Context/Auth/'

import { BriefCase } from 'Svg/'
import { parseJobURL, getApplyIcon } from 'Util/index'
import ExternalLink from 'Common/ExternalLink'
// styles
import {
  Section,
  Name,
  TagHolder,
  Tag,
  LocationInfo,
  Comments,
  TimePeriod,
  LinkText,
} from 'Pages/Job/Detail/Styles'

interface Props {
  details: JobDetail
  tagsList: Tags
  locationName: string
}

function Info({ details, tagsList, locationName }: Props) {
  return (
    <div>
      <Section>
        <Name>{details.name}</Name>
      </Section>
      <Section>
        <div className="flex">
          <LocationInfo name={locationName} showIcon showBorder />
          <div className="ml-auto">
            <TagHolder showIcon={tagsList.length > 0}>
              {tagsList.map(tag => (
                <Tag key={tag.id}>{tag.name}</Tag>
              ))}
            </TagHolder>
          </div>
        </div>
      </Section>
      <Section>
        <div>
          <LinkText Icon={getApplyIcon(details?.job_url)} text={'Apply'}>
            <ExternalLink
              href={parseJobURL(details?.job_url)}
              text={details?.job_url}
            />
          </LinkText>
        </div>
        <div>
          <LinkText Icon={BriefCase} text={'Company'}>
            <ExternalLink href={details.company_url} />
          </LinkText>
        </div>
      </Section>
      <Section></Section>
      <Section>
        <Comments>{details.comments}</Comments>
      </Section>
      <Section>
        <div>
          <TimePeriod>
            <span className={'label'}>Last updated </span>
            <TimeAgo
              datetime={details.updated_at as TDate}
              opts={{
                minInterval: 60,
              }}
            />
          </TimePeriod>
          <TimePeriod>
            <span className={'label'}>Added </span>
            <TimeAgo
              datetime={details.inserted_at as TDate}
              opts={{
                minInterval: 60,
              }}
            />
          </TimePeriod>
        </div>
      </Section>
    </div>
  )
}

export default Info
