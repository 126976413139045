const DATA = [
  {
    created_user: 1,
    id: 1,
    inserted_at: '2020-07-31T14:02:36',
    is_archived: false,
    name: 'Remote',
    updated_at: '2020-07-31T14:02:36',
  },
  {
    created_user: 1,
    id: 2,
    inserted_at: '2020-07-31T14:02:36',
    is_archived: false,
    name: 'Bengaluru',
    updated_at: '2020-07-31T14:02:36',
  },
  {
    created_user: 1,
    id: 3,
    inserted_at: '2020-07-31T14:02:36',
    is_archived: false,
    name: 'San Francisco',
    updated_at: '2020-07-31T14:02:36',
  },
]

export default DATA
