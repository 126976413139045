import React, { useContext } from 'react'
import { isNil, isEmpty } from 'lodash-es'

import type { RecentSearch, ActiveSearch } from 'Types/Search/'

import { AuthContext } from 'Context/Auth/'
import { User, ViewGridAdd } from 'Svg'
import Button, { ButtonType, ButtonRole, HrefLink } from 'Common/Button/'
import { Holder, Title } from './Styles'
import Jobs, { ActiveProspects } from './Jobs'
import useRecentJobSearch from 'Hooks/Search/useRecentJobSearch'
import { useLoginModal } from 'Context/LoginModal'

function isValidSearch(
  recentSearch: RecentSearch
): recentSearch is ActiveSearch {
  if (isNil(recentSearch)) {
    return false
  }

  if (isNil(recentSearch.id)) {
    return false
  }

  // we have an job search; if not active job search we will not show anything
  if (recentSearch.status !== 'ACTIVE') {
    return false
  }
  // we should have active prospects
  if (isNil(recentSearch.prospects)) {
    return false
  }
  if (isEmpty(recentSearch.prospects)) {
    return false
  }

  return true
}

function ActiveSearchJobs() {
  const recentSearch = useRecentJobSearch()

  if (!isValidSearch(recentSearch)) {
    return (
      <div
        className={
          'flex flex-col justify-center items-center border shadow rounded mt-4 p-2'
        }
      >
        <div className={'text-gray-800 my-4'}>
          {
            'Jublime makes it easy to focus on your job search. Go to Dashboard to manage your Job Search.'
          }
        </div>
        <div>
          <HrefLink
            type={ButtonType.Primary}
            Icon={ViewGridAdd}
            href={'/'}
            text={'Go to Dashboard'}
          />
        </div>
      </div>
    )
  }

  const jobs: ActiveProspects = recentSearch.prospects.map(p => {
    return {
      searchId: recentSearch.id,
      prospectId: p.id,
      id: p.job.id,
      name: p.job.name,
    }
  })

  return (
    <Holder>
      <Title target={'_blank'} href={`/job/search/${recentSearch.id}`}>
        {recentSearch.name}
      </Title>
      <Jobs jobs={jobs} />
    </Holder>
  )
}

function AuthCheck() {
  const { authStatus } = useContext(AuthContext)
  const isLoggedIn = authStatus?.user
  const { showLoginModal } = useLoginModal()

  if (!isLoggedIn) {
    return (
      <div
        className={
          'flex flex-col justify-center items-center border shadow rounded mt-4 p-2'
        }
      >
        <div className={'text-gray-800 my-4'}>
          {
            'Jublime helps you to track job applications better. Track application status and add notes on job interviews. Jublime makes it easy to focus on your job search.'
          }
        </div>
        <div>
          <Button
            role={ButtonRole.Button}
            type={ButtonType.Primary}
            text={'Manage your Job Search'}
            Icon={User}
            onClick={() => {
              showLoginModal()
            }}
          />
        </div>
      </div>
    )
  }

  return <ActiveSearchJobs />
}

export default AuthCheck
