import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { Search as SearchIcon } from 'Svg/'

const Schema = Yup.object().shape({
  query: Yup.string()
    .min(2, 'Too Short!')
    .trim('Please enter a search term ...'),
})

const SearchInput = styled.input`
  ${tw`flex border border-purple-600 focus:shadow p-2 px-4 focus:outline-none focus:border-purple-600 focus:border-2`};
`

type Props = {
  onSubmit: (query: string) => void
  value: string | null
}

function Search({ onSubmit, value }: Props) {
  const initialValues = {
    query: value || '',
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { resetForm }) => {
      onSubmit(values.query)
      // reset the form
      resetForm()
    },
  })

  return (
    <form className={'flex search-input'} onSubmit={form.handleSubmit}>
      <SearchInput
        placeholder={'Search Jobs ...'}
        name={'query'}
        value={form.values.query}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
      />

      <button type={'submit'} className={'do-search'} disabled={!form.isValid}>
        <SearchIcon />
      </button>
    </form>
  )
}

export default Search
