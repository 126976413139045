// constructs prospects from jobs
import { sample } from 'lodash'
import { _getProspectId, _getSearchId } from 'Try/ApiContext'
import Jobs from './Jobs'

import { ProspectStatus } from 'Types/Enums'

const prospectStatuses = [
  ProspectStatus.Accepted,
  ProspectStatus.Applied,
  ProspectStatus.Considering,
  ProspectStatus.InConversation,
  ProspectStatus.Interviewing,
  ProspectStatus.NoReply,
  ProspectStatus.Offer,
]

const comments = [
  'Interesting job',
  'Nice salary. Bit formal',
  'Small startup. Can have freedom to explore things',
]

const prospects = Jobs.map(job => ({
  id: _getProspectId(),
  comments: sample(comments),
  status: sample(prospectStatuses),
  search_id: _getSearchId(),
  job,
  updated_at: new Date().toISOString(),
}))

export default prospects
