import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Select, { StylesConfig } from 'react-select'
import styled from 'styled-components'
import tw from 'twin.macro'

import { AuthContext } from 'Context/Auth/'
import { JobBoards } from 'Types/Enums'
import { HEADER_HEIGHT } from 'Components/Header'
import SearchInput from 'Components/SearchInput'
import { useBetaFeedbackModal } from 'Components/Feedback/BetaFeedback'

const Wrapper = styled.div`
  ${tw`flex h-12`};
  position: sticky;
  top: ${HEADER_HEIGHT};
  padding-top: 2px;

  & .search-input {
    ${tw`h-full`};

    & input {
      ${tw`flex flex-grow`};
    }

    & .do-search {
      ${tw`flex justify-center items-center bg-purple-600 hover:bg-purple-500 text-white w-12 h-full disabled:bg-purple-400 disabled:cursor-text`}
    }
  }

  & .job-boards-select {
    ${tw`h-full`};

    & > div {
      ${tw`h-full`};
    }
  }
`
type Source = {
  value: JobBoards
  label: string
  disabled: boolean
}

const sources: Array<Source> = [
  {
    value: JobBoards.HN,
    label: 'Hacker News',
    disabled: false,
  },

  {
    value: JobBoards._4Day,
    label: '4 Day Week',
    disabled: false,
  },
]

const selectStyles: StylesConfig<Source> = {
  menuList: (provided, state) => ({
    ...provided,
    background: 'white',
    border: 'thin solid rgb(139, 92, 246)',
  }),
}

function Filters() {
  const { authStatus } = useContext(AuthContext)
  const isLoggedIn = authStatus?.user
  const isAdmin = isLoggedIn && authStatus.user?.is_admin
  const isMultiSelect = false // later determine based on authStatus

  const { showBetaFeedbackModal, BetaType } = useBetaFeedbackModal()

  const location = useLocation()
  const history = useHistory()
  const urlParams = new URLSearchParams(location.search)

  const source = urlParams.get('source')
  const search = urlParams.get('search')
  const value = sources.find(s => s.value === source)

  return (
    <Wrapper>
      <div className={'w-3/5'}>
        <SearchInput
          value={search}
          onSubmit={(query: string) => {
            if (!isLoggedIn) {
              showBetaFeedbackModal({
                title: 'Search Job Boards',
                description:
                  'Please login to search across job boards. This feature allows you to search across multiple job boards. You can easily search jobs from different job boards in one place. This feature is currently in beta.',
                type: BetaType.SearchJobBoard,
              })

              // do not proceed
              return
            }

            // admin user; we will search jobs
            if (query.length > 0) {
              urlParams.set('search', query)
            } else {
              urlParams.delete('search')
            }
            // always search in first page
            urlParams.delete('page')
            // construct the url
            const url = `/find-jobs?${urlParams.toString()}`
            // navigate to new url
            history.push(url)
          }}
        />
      </div>
      <div className={'w-2/5 pl-8'}>
        {/* IMPORTANT: https://github.com/JedWatson/react-select/issues/4804 */}
        {isAdmin && (
          <Select<Source>
            className={'job-boards-select'}
            styles={selectStyles}
            value={value}
            placeholder={'Select Job Boards'}
            options={sources}
            isClearable={true}
            isMulti={isMultiSelect}
            isOptionDisabled={(option: Source) => option.disabled}
            theme={theme => ({
              ...theme,
              colors: {
                ...theme.colors,

                primary: '#9f7aea',
                primary75: '#b794f4',
                primary50: '#d6bcfa',
                primary25: '#e9d8fd',
              },
            })}
            onChange={(newSources: Source | null) => {
              // update source param
              // for now, we have only one source
              // const updatedSources: string | undefined = isArray(newSources)
              //   ? newSources.map(n => n.value).join(',')
              //   : newSources?.value

              const updatedSources: JobBoards | undefined = newSources?.value

              if (updatedSources) {
                urlParams.set('source', updatedSources)
              } else {
                urlParams.delete('source')
              }

              // when changing source we need to go to first page
              urlParams.delete('page')

              // construct the url
              const url = `/find-jobs?${urlParams.toString()}`
              // navigate to new url
              history.push(url)
            }}
          />
        )}
      </div>
    </Wrapper>
  )
}

export default Filters
