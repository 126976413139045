import React, { useContext } from 'react'
import { uniqueId } from 'lodash'

import { notify } from 'Util/Noty/'
import { ApiContext } from 'Context/Api/'
import { DemoContext } from 'Try/DemoContext'
import {
  AddProspectProps,
  EditProspectProps,
  DeleteProspectProps,
} from 'Util/Server/Prospect'
import { JobActionProps } from 'Util/Server/Job'
import { Prospect } from 'Types/Search'
import { ProspectStatus } from 'Types/Enums'
import { RecentJobDetail } from 'Types/Job'

export function _getSearchId() {
  return 5
}

// helper functions to generate unique ids
export function _getJobId() {
  return Number(uniqueId('101'))
}

export function _getProspectId() {
  return Number(uniqueId('1001'))
}

function DummyApiContext({ children }: { children: React.ReactNode }) {
  const {
    addJob,
    editJob,
    addJobAsProspect,
    recentSearch,
    recentJobs,
    deleteProspect,
    updateRecentSearch,
  } = useContext(DemoContext)

  return (
    <ApiContext.Provider
      value={{
        _getSearchURL: () => `/try-demo/my-job-search`,
        _getJobURL: id => `/try-demo/job-detail/${id}`,
        // START: Prospects related handlers
        addProspectsToSearch: ({
          prospects,
          successCallback,
        }: AddProspectProps) => {
          const new_prospects: Prospect[] = prospects
            .filter(job_id => {
              // filter job ids that are already part of current search
              const current_job_ids = (recentSearch?.prospects || []).map(
                p => p.job.id
              )
              return !current_job_ids.includes(job_id)
            })
            .map((job_id: number) => ({
              id: _getProspectId(),
              comments: 'Just added this existing job as a prospect',
              status: ProspectStatus.Considering,
              search_id: _getSearchId(),
              job: recentJobs.find(j => j.id === job_id) as RecentJobDetail,
              updated_at: new Date().toISOString(),
            }))

          const current_prospects = recentSearch.prospects || []

          // add new prospect to search
          updateRecentSearch({
            ...recentSearch,
            prospects: [...new_prospects, ...current_prospects],
          })

          notify({
            type: 'success',
            text: 'Prospects added to search',
          })

          successCallback()
        },
        editProspect: ({ prospect, successCallback }: EditProspectProps) => {
          let current_prospects = recentSearch.prospects?.map(p => {
            return p.id === prospect.id
              ? {
                  ...p,
                  status: prospect.status,
                  comments: prospect.comments,
                  updated_at: new Date().toISOString(),
                }
              : p
          })
          // sort prospects by updated time
          updateRecentSearch({
            ...recentSearch,
            prospects: (current_prospects || []).sort((p1, p2) => {
              return (
                new Date(p2.updated_at as string).getTime() -
                new Date(p1.updated_at as string).getTime()
              )
            }),
          })

          // update time for edited prospect
          // we need to put this particular prospect at the top of the list
          notify({
            type: 'success',
            text: 'Prospect updated successfully',
          })
          // call success callback with prospect
          successCallback(prospect)
        },
        deleteProspect: ({
          prospect,
          successCallback,
        }: DeleteProspectProps) => {
          deleteProspect(prospect.id)
          notify({
            type: 'success',
            text: 'Prospect removed from job search',
          })

          successCallback()
        },
        // END: Prospects related handlers

        // START: job related handlers
        addJob: ({ job, successCallback }: JobActionProps) => {
          const job_id = _getJobId()
          const { job_search, ...job_info } = job
          const job_details = {
            id: job_id,
            ...job_info,
            // NOTE: add updated_at current time
            updated_at: new Date().toISOString(),
          }
          addJob(job_details)

          // PORUMAI: Intentional bug
          // adding job to current search always for demo
          // let them either raise this or remove the job as prospect manually
          // if (job_search === recentSearch.id) {
          const prospect_details: Prospect = {
            id: _getProspectId(),
            status: ProspectStatus.Considering,
            search_id: recentSearch.id as number,
            job: job_details,
          }
          addJobAsProspect(prospect_details)
          // }

          successCallback({ job_id, blockNavigation: true })

          notify({
            type: 'success',
            text: 'Added Job',
          })

          // history.push('/try-demo/dashboard')
        },
        editJob: ({ job, successCallback }: JobActionProps) => {
          editJob(job)
          notify({
            type: 'success',
            text: 'Job updated',
          })
          successCallback({ job })
        },
        // END: job related handlers

        // START: Search related handlers
        newSearch: () => {
          notify({
            type: 'info',
            text: 'Please login to create a job search',
          })
        },

        editSearch: ({ search, successCallback }) => {
          successCallback(search)
          notify({
            type: 'success',
            text: `${search.name} updated`,
          })
        },

        // conclude search
        concludeSearch: () => {
          notify({
            type: 'success',
            text: 'This will conclude the active job search in the actual app',
          })
        },

        // END: Search related handlers
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}

export default DummyApiContext
