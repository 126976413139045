import React, { useState, useContext } from 'react'
import Login, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login'

import { Google as GoogleIcon } from 'Svg/'
import { BarLoader } from 'Assets/Loaders/'
import { notify } from 'Util/Noty/'
import { AuthContext } from 'Context/Auth/'
import { authorizeGoogleLogin } from 'Util/Server/Auth'

enum ErrorCode {
  FRAME_INIT_FAILED = 'idpiframe_initialization_failed',
  POPUP_CLOSED_BY_USER = 'popup_closed_by_user',
  ACCESS_DENIED = 'access_denied',
  IMMEDIATE_FAILED = 'immediate_failed',
}

interface FailureResponse {
  error: ErrorCode
  details: string
}

function GoogleLogin() {
  const { updateAuthStatus } = useContext(AuthContext)
  const [isLoading, setLoading] = useState(false)

  return (
    <div className="my-3">
      <div className="flex items-center justify-center">
        {isLoading ? (
          <BarLoader>
            <div className="flex items-center align-center my-3">
              <GoogleIcon />
              <span className="mx-3 text-sm text-purple-700 font-semibold">
                Please wait ... Logging in with Google account
              </span>
            </div>
          </BarLoader>
        ) : (
          <Login
            clientId="615488550172-qbvpd5mgfdotbqq53temmif1qeka2he9.apps.googleusercontent.com"
            buttonText="Login with Google"
            onSuccess={(
              params: GoogleLoginResponse | GoogleLoginResponseOffline
            ) => {
              const response = params as GoogleLoginResponse

              // we got a successfull response from google
              // set the loading status
              setLoading(true)

              authorizeGoogleLogin({
                params: {
                  email: response.profileObj.email,
                  name: response.profileObj.name,
                },
                setAuthStatus: updateAuthStatus,
                successCallback: () => {
                  // set loading to false
                  setLoading(false)
                },
                errorCallback: () => {
                  setLoading(false)
                },
              })
            }}
            onFailure={(params: FailureResponse) => {
              // notify the user that there is an error
              notify({
                type: 'error',
                text: `Google Login Error - ${params.error}, ${params.details}`,
              })
            }}
            cookiePolicy={'single_host_origin'}
            // NOTE: do not give 'isSignedIn: true' option
            // we do not want the user to be logged in with his current logged in google account
            // if he had already logged in our app, our session is more than enough
            // to keep the user logged in
          />
        )}
      </div>
    </div>
  )
}

export default GoogleLogin
