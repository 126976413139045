import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { AuthContext } from 'Context/Auth/'
import ApiContext from './ApiContext'
import DemoData from './DemoContext'
import Dashboard from 'Try/Dashboard/'
import JobSearch from 'Try/JobSearch/'
import Job from 'Try/Job/'
import { Tags, Locations } from 'Try/Data/'

function TryRoutes() {
  return (
    <AuthContext.Provider
      value={{
        authStatus: {
          token: null,
          user: null,
        },

        updateAuthStatus: () => ({
          token: null,
          user: null,
        }),

        locations: Locations,
        refreshLocations: () => Locations,

        tags: Tags,
        refreshTags: () => Tags,
      }}
    >
      <DemoData>
        <ApiContext>
          <div className="bg-yellow-100 p-3 font-normal text-gray-800 rounded border my-5 leading-relaxed">
            Welcome! This is a
            <span className="italic font-semibold mx-1">demo.</span>Changes will
            not be saved permanently. Play around to understand how you can
            organize your job search. Please consider giving your
            <span className="italic font-semibold mx-1">feedback.</span>Thank
            you!
          </div>
          <Switch>
            <Route path={'/try-demo/dashboard'}>
              <Dashboard />
            </Route>
            <Route path={'/try-demo/my-job-search'}>
              <JobSearch />
            </Route>
            <Route path={'/try-demo/job-detail/:id'}>
              <Job />
            </Route>
          </Switch>
        </ApiContext>
      </DemoData>
    </AuthContext.Provider>
  )
}

export default TryRoutes
