import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Modal from 'react-modal'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { ApiContext } from 'Context/Api'
import { modalStyles } from 'Util/Styles'
import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import { Search } from 'Types/Search'

// bind modal to root element
Modal.setAppElement('#root')

const customStyles = {
  ...modalStyles,
  content: {
    top: '5%',
    left: '15%',
    width: '70%',
    height: '85%',
  },
}

interface Props {
  mode: 'edit' | 'create'

  isOpen: boolean
  setStatus: Function

  searchDetails?: Search
  setSearchDetails?: Function
}

function SearchModal({
  mode,

  isOpen,
  setStatus,

  searchDetails,
  setSearchDetails,
}: Props) {
  const { newSearch, editSearch } = useContext(ApiContext)
  const history = useHistory()
  // const { refreshTags } = useContext(AuthContext)

  const SearchSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Too Short!')
      .required('Please enter a name for this Job Search ...'),
  })

  // calculate initial values
  const initialValues =
    mode === 'create'
      ? {
          name: '',
          comments: '',
        }
      : Object.assign({}, searchDetails)

  const form = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: SearchSchema,
    onSubmit: (values, { resetForm }) => {
      const isCreateMode = mode === 'create'
      const isEditMode = mode === 'edit'

      if (isCreateMode) {
        newSearch({
          search: values,
          successCallback: ({ search_id }: { search_id: string }) => {
            // close the modal
            setStatus(false)
            // redirect to job page
            history.push(`/job/search/${search_id}`)
          },
          errorCallback: () => {},
        })
        // do not proceed
        return
      }

      if (isEditMode) {
        editSearch({
          search: values,
          successCallback: (search_details: Search) => {
            // close the modal
            setStatus(false)
            // update existing search details
            if (setSearchDetails) {
              setSearchDetails(search_details)
            }
          },
          errorCallback: () => {},
        })
        // do not proceed
        return
      }

      resetForm()
    },
  })

  const isNameValid: boolean = !(form.touched.name && form.errors.name)

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={() => setStatus(false)}
    >
      <form className="flex flex-col h-full " onSubmit={form.handleSubmit}>
        <div className="flex  w-full items-center text-gray-700 border-b border-gray-400 mb-3 px-1">
          <div className="font-bold text-2xl">
            {mode === 'create' ? 'Start Job Search' : 'Edit Job Search'}
          </div>
          <div
            className="ml-auto text-5xl hover:opacity-75 cursor-pointer"
            onClick={() => setStatus(false)}
          >
            &times;
          </div>
        </div>
        <div className="flex flex-1 flex-col flex-grow px-1 w-full overflow-y-auto pr-6">
          {/* Search Name */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="search-name"
            >
              Name
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="search-name"
              type="text"
              placeholder="Give a name for this search (eg: My next remote job) ... "
              name={'name'}
              value={form.values.name}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
            {!isNameValid && (
              <p className="text-red-500 text-xs italic my-1">
                {form.errors.name}
              </p>
            )}
          </div>

          {/* Comments for job */}
          <div className="mb-2 flex flex-col h-full">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="search-comments"
            >
              Comments
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full h-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="search-comments"
              placeholder="Comments/Description/Thoughts ... "
              name={'comments'}
              value={form.values.comments}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex items-center justify-between border-t border-gray-400 pt-3 px-1">
          <button
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="button"
            onClick={() => setStatus(false)}
          >
            Cancel
          </button>
          <Button
            disabled={!form.dirty}
            role={ButtonRole.Submit}
            type={ButtonType.Primary}
            text={
              mode === 'create' ? 'Start Job Search' : 'Update Search Details'
            }
          />
        </div>
      </form>
    </Modal>
  )
}

export default SearchModal
