import React, { useState, useCallback, useContext } from 'react'
import { Link } from 'react-router-dom'
import Tooltip from 'react-tooltip'

import { HEADER_HEIGHT } from 'Components/Header'
import { Pencil, Comments } from 'Svg/'
import { AuthContext } from 'Context/Auth/'
import { ApiContext } from 'Context/Api/'
import { Prospect } from 'Types/Search'
import { Section, Comment, Actions, ActionIcon } from './Styles'
import { TagHolder, Tag, LocationInfo } from 'Pages/Job/Detail/Styles'
import ExternalLink from 'Common/ExternalLink'
import { Status, BadgeType } from 'Common/Badge'
import EditProspectModal from './EditProspectModal'
import { parseJobURL } from 'Util/index'

function ProspectInfo({
  prospect,
  updateSearchDetails,
}: {
  prospect: Prospect
  updateSearchDetails: Function
}) {
  const { _getJobURL } = useContext(ApiContext)
  const { job } = prospect
  // use local state for prospect (status/comments) to update it after editing
  const [status, setStatus] = useState(prospect.status)
  const [comments, setComments] = useState(prospect.comments)

  const [editStatus, setEditStatus] = useState(false)
  const { locations, tags } = useContext(AuthContext)

  const location_id = job.location_id
  const tag_ids = job.tag_ids

  const _getLocation = useCallback(() => {
    const location = locations.find(l => l.id === location_id)

    return location ? location.name : ''
  }, [locations, location_id])

  const _getTags = useCallback(() => {
    return tags.filter(t => tag_ids?.split(',').includes(String(t.id)))
  }, [tags, tag_ids])

  const tagsList = _getTags()
  const locationName = _getLocation()

  // helper component to display prospect meta data
  function MetaData() {
    if (!job) {
      return null
    }

    return (
      <div className="flex items-start mt-4">
        <div className={'flex h-8'}>
          <LocationInfo name={locationName} showIcon showBorder />
        </div>

        <div className="ml-3 flex items-end my-1">
          <ExternalLink text={'Apply'} href={parseJobURL(job.job_url)} />
        </div>

        <div className="ml-3 flex items-end my-1">
          <ExternalLink href={job.company_url} text={'Company'} />
        </div>

        <div className="ml-auto pl-4 max-w-sm">
          <TagHolder showIcon={tagsList.length > 0}>
            {tagsList.map(tag => (
              <Tag key={tag.id}>{tag.name}</Tag>
            ))}
          </TagHolder>
        </div>
      </div>
    )
  }

  return (
    <div
      id={`prospect-${prospect.id}`}
      className="flex flex-col shadow rounded py-2"
      style={{
        scrollMargin: `${HEADER_HEIGHT}`,
      }}
    >
      <Section>
        <div className="flex items-center">
          <div className="font-semibold text-gray-700 text-xl mr-1">
            <span className="hover:underline">
              <Link to={_getJobURL(job.id)}>{job.name}</Link>
            </span>
          </div>
          <div className="mx-3">
            <Status type={BadgeType.Inactive} text={status} />
          </div>
          <div
            className="mx-1 text-purple-700"
            data-tip
            data-for={`job-comment-${job.id}`}
          >
            <Comments />
            <Tooltip
              id={`job-comment-${job.id}`}
              className={'jubtip'}
              effect="solid"
            >
              <div className={'italic'}>{job.comments || 'No comments'}</div>
            </Tooltip>
          </div>
          <Actions>
            <ActionIcon onClick={() => setEditStatus(true)}>
              <Pencil />
            </ActionIcon>
          </Actions>
        </div>
        <div className="mt-2">
          <MetaData />
        </div>
      </Section>

      <Comment>{comments || 'No comments'}</Comment>

      {/* Edit modal */}
      <EditProspectModal
        MetaData={MetaData}
        prospect={prospect}
        isOpen={editStatus}
        setStatus={setEditStatus}
        updateProspectDetails={(details?: Prospect) => {
          if (details) {
            const { status, comments } = details
            setStatus(status)
            setComments(comments)
          }
          // update search details (updated_time/ current active prospects info etc)
          updateSearchDetails()
        }}
      />
    </div>
  )
}

export default ProspectInfo
