import { useState, useEffect, useContext } from 'react'

import { Search, RecentSearch } from 'Types/Search'
import { getRecentSearch } from 'Util/Server/JobSearch'
import { AuthContext } from 'Context/Auth/'

function useRecentJobSearch() {
  const [recentSearch, setRecentSearchDetails] = useState<RecentSearch>(
    undefined
  )

  const { authStatus } = useContext(AuthContext)
  const isLoggedIn = authStatus && authStatus.token && authStatus.user

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    getRecentSearch({
      successCallback: (details: Search) => setRecentSearchDetails(details),
      errorCallback: () => setRecentSearchDetails(null),
    })
  }, [isLoggedIn])

  return recentSearch
}

export default useRecentJobSearch
