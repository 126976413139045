import React, { useState, useEffect, useCallback } from 'react'
import { isUndefined, isNull, isEmpty } from 'lodash'
import { useParams, useHistory } from 'react-router-dom'

import Button, { ButtonSize, ButtonType } from 'Common/Button'
import { ChevronLeft, FolderAdd } from 'Svg/'
import { getSearchDetails } from 'Util/Server/JobSearch'
import { listJobs } from 'Util/Server/Job'
import { RecentJobs, RecentJobDetail } from 'Types/Job/'
import { Search } from 'Types/Search'

import AddProspectsModal from 'Components/JobSearch/AddProspectsModal'
import AddJobAction from 'Components/AddJobAction/'
import Details from './Details'

export type SearchDetails = Search | undefined | null

type SearchParams = {
  id: string
}

function ShowSearch() {
  const history = useHistory()
  let { id } = useParams<SearchParams>()

  const [recentJobs, setRecentJobs] = useState<RecentJobs>([])
  const [prospectsModalStatus, setProspectsModalStatus] = useState(false)
  const [details, setDetails] = useState<SearchDetails>(undefined)

  const fetchSearchDetails = useCallback(() => {
    getSearchDetails({
      id,
      successCallback: (search_details: any) => {
        setDetails(search_details)
      },
      errorCallback: () => setDetails(null),
    })
  }, [id])

  useEffect(fetchSearchDetails, [fetchSearchDetails])

  // fetch recent jobs
  useEffect(() => {
    listJobs({
      successCallback: (jobs: Array<RecentJobDetail>) => {
        setRecentJobs(jobs)
      },
      errorCallback: () => {},
    })
  }, [])

  // calculate which jobs to add
  const _calculateJobsToAdd = useCallback(() => {
    const current_prospects = (details?.prospects || []).map(p => p.job.id)

    return (recentJobs || []).filter(j => !current_prospects.includes(j.id))
  }, [recentJobs, details])

  if (isUndefined(details)) {
    return <div>Fetching search details</div>
  }

  if (isNull(details)) {
    return <div>Cannot find search details</div>
  }

  return (
    <div className="max-w-3xl pb-32">
      <div>
        <div
          className="flex items-center hover:underline text-gray-700 text-reg my-3 font-semibold cursor-pointer"
          onClick={() => history.push('/')}
        >
          <div className="w-6 h-6 -ml-2">
            <ChevronLeft />
          </div>
          <div className="">Home</div>
        </div>
      </div>
      <div className="flex justify-end my-5">
        <Button
          disabled={isEmpty(_calculateJobsToAdd())}
          size={ButtonSize.Small}
          type={ButtonType.PrimaryDark}
          text={'Add Existing'}
          Icon={FolderAdd}
          onClick={() => setProspectsModalStatus(true)}
        />
        <AddJobAction text={'Add New Job'} size={ButtonSize.Small} />
      </div>

      <Details
        details={details}
        setDetails={setDetails}
        recentJobs={recentJobs}
        fetchSearchDetails={fetchSearchDetails}
      />

      {/* Add Prospects to Search Modal */}
      <AddProspectsModal
        search_id={details.id as number}
        search_name={details.name}
        jobs={_calculateJobsToAdd()}
        isOpen={prospectsModalStatus}
        setStatus={setProspectsModalStatus}
        afterProspectsAdd={fetchSearchDetails}
      />
    </div>
  )
}

export default ShowSearch
