import { isNil } from 'lodash'
import { Email, CursorClick } from 'Svg'

import { LatestPost } from 'Util/Server/Explore'

export function parseJobURL(url: string): string {
  return String(url).startsWith('http') ? url : `mailto:${url}`
}

export function getApplyIcon(url: string): React.ComponentType<any> {
  return String(url).startsWith('http') ? CursorClick : Email
}

/* 
 * get Job site url from id 
 *
 * hn:1234567 => `https://news.ycombinator.com/item?id=1234567

 */
export function getPostingURL(posting: LatestPost): string {
  if (isNil(posting.source)) {
    return 'https://blog.jublime.com'
  }

  switch (posting.source) {
    case 'hn':
      // eg: hn:123456789
      // split with colon to get the first identifier
      const [, id] = posting.id.split(':')
      return `https://news.ycombinator.com/item?id=${id}`

    case '4day':
      return `https://4dayweek.io${posting.posting_url}`

    default:
      return 'https://blog.jublime.com'
  }
}
