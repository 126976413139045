import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useParams } from 'react-router-dom'

import { confirmUserEmail, Status } from 'Util/Server/ConfirmEmail'

const Holder = styled.div`
  ${tw`my-24 p-3 flex items-center justify-center border rounded text-xl text-white font-semibold leading-10`};
`

function ConfirmEmail() {
  const [status, setStatus] = useState<Status>({
    type: 'PENDING',
    message: 'We are confirming your email address.',
  })

  const params: {
    token?: string
  } = useParams()

  const token = params.token

  useEffect(() => {
    if (!token) {
      return
    }

    // make an api request to confirm user
    confirmUserEmail(token, setStatus)
  }, [token])

  if (!token) {
    return <div>No valid token present to confirm</div>
  }

  if (status.type === 'PENDING') {
    return (
      <Holder className="bg-blue-500">
        {status.message || 'We are confirming your email address'}
      </Holder>
    )
  }

  if (status.type === 'CONFIRMED') {
    return (
      <Holder className="bg-green-500">
        {status.message || 'Your email address is confirmed'}
      </Holder>
    )
  }

  return (
    <Holder className="bg-red-500">
      {status.message || 'We cannot perform this action right now.'}
    </Holder>
  )
}

export default ConfirmEmail
