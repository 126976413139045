import React from 'react'

export const modalStyles = {
  overlay: {
    background: 'rgba(255, 255, 255, 0.96)',
  },
  content: {
    top: '15%',
    left: '15%',
    width: '70%',
    height: '70%',
    right: 'auto',
    bottom: 'auto',

    // marginRight: '-50%',
    // transform: 'translate(-50%, -20%)',
    background: '#FCFCFC',
    opacity: 1,
    boxShadow:
      '0 4px 6px -1px rgba(255, 255, 255, 0.1), 0 2px 4px -1px rgba(255, 255, 255, 0.06)',
  },
}

type BannerProps = {
  children: React.ReactNode
}

export function InfoBanner({ children }: BannerProps) {
  return (
    <div className="bg-yellow-100 p-3 font-normal text-gray-800 rounded border border-yellow-300 shadow leading-relaxed mt-1">
      {children}
    </div>
  )
}
