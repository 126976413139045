import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Link } from 'react-router-dom'

export enum ButtonType {
  Primary = 'primary',
  PrimaryDark = 'primaryDark',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Outline = 'outline',
  Danger = 'danger',
}

export enum ButtonSize {
  Large = 'large',
  Normal = 'normal',
  Small = 'small',
  Tiny = 'tiny',
}

export enum ButtonRole {
  Button = 'button',
  Submit = 'submit',
}

type Props = {
  type: ButtonType
  role?: ButtonRole
  Icon?: React.ComponentType<any>
  onClick: Function
  text: string
  size: ButtonSize
  disabled: boolean
}

interface HolderProps {
  size: ButtonSize
  disabled?: boolean
}

const Holder = styled.button<HolderProps>`
  ${tw`py-2 text-base`};
  ${(props: HolderProps) => props.size === ButtonSize.Small && tw`py-1 px-2`};

  ${(props: HolderProps) =>
    props.size === ButtonSize.Tiny &&
    `
    ${tw`py-1 px-2`};
    padding: 2px 4px;
    `};

  ${(props: HolderProps) =>
    props.disabled &&
    `
    cursor: text;
    background: rgba(107, 70, 193, 0.5);

    &:hover {
      background: rgba(107, 70, 193, 0.5) !important;
    }
  `}

  & .icon-holder {
    ${(props: HolderProps) => props.size === ButtonSize.Small && tw`w-4 h-4`};
  }

  & .text-holder {
    ${(props: HolderProps) => props.size === ButtonSize.Small && tw`text-sm`};
    ${(props: HolderProps) => props.size === ButtonSize.Tiny && tw`text-sm`};
  }
`

const classes = {
  primary:
    'flex items-center justify-center bg-purple-500 hover:bg-purple-700 text-white font-bold px-4 rounded focus:outline-none focus:shadow-outline',
  // for header buttons
  primaryDark:
    'flex items-center justify-center bg-purple-700 hover:bg-purple-800 text-white font-bold px-4 ml-3 rounded focus:outline-none focus:shadow-outline',

  secondary:
    'flex items-center justify-center border border-purple-500 hover:bg-purple-500 hover:text-white duration-300 hover: duration-300 text-purple-600 font-bold px-4 rounded focus:outline-none focus:shadow-outline',
  tertiary:
    'flex items-center justify-center bg-gray-500 hover:bg-gray-700 text-white font-bold px-4 rounded focus:outline-none focus:shadow-outline',
  outline:
    'flex items-center justify-center align-baseline font-bold text-sm text-purple-600 hover:text-purple-800',
  // danger button
  danger:
    'flex items-center justify-center bg-red-400 hover:bg-red-500 text-white font-bold px-4 rounded focus:outline-none focus:shadow-outline',
}

interface LinkProps {
  type: ButtonType
  Icon?: React.ComponentType<any>
  text: string
  size: ButtonSize
  disabled: boolean
  href: string
}

// link with specific button styles
export const HrefLink = ({
  type,
  Icon,
  text,
  size,
  disabled,
  href,
}: LinkProps) => (
  <Link to={href}>
    <Holder size={size} className={classes[type]} disabled={disabled}>
      {Icon && (
        <div className="flex items-center w-5 h-5 mr-1 icon-holder">
          <Icon />
        </div>
      )}

      <div className="font-bold text-holder">{text || ''}</div>
    </Holder>
  </Link>
)

HrefLink.defaultProps = {
  type: ButtonType.Outline,
  size: ButtonSize.Normal,
  Icon: false,
  text: '',
  disabled: false,
  href: '/',
}

function Button({ type, size, role, Icon, onClick, text, disabled }: Props) {
  return (
    <Holder
      size={size}
      className={classes[type]}
      type={role}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {Icon && (
        <div className="flex items-center w-5 h-5 mr-1 icon-holder">
          <Icon />
        </div>
      )}

      <div className="font-bold text-holder">{text || ''}</div>
    </Holder>
  )
}

Button.defaultProps = {
  type: ButtonType.Primary,
  role: ButtonRole.Button,
  size: ButtonSize.Normal,
  Icon: false,
  onClick: () => {},
  text: '',
  disabled: false,
}

export default Button
