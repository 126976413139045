import axios, { AxiosError } from 'axios'
import { debounce } from 'lodash-es'

import { notify } from 'Util/Noty/'
import { ContextValue as LoginModalContextValue } from 'Context/LoginModal'

let axiosInterceptor: number | undefined

interface AxiosSetupOptions extends LoginModalContextValue {
  isLoggedIn: boolean
}

interface ErrorHandlerOptions {
  showLoginModal?: LoginModalContextValue['showLoginModal']
  errorHandler?: () => void
}

export function handleHTTPErrors(
  error: AxiosError,
  options?: ErrorHandlerOptions
): boolean {
  // NOTE: Network error is a plain Error
  const isNoNetworkError =
    error.name.includes('Error') && error.message.includes('Network Error')

  if (isNoNetworkError) {
    // show no network info
    notify({
      text: 'No Network Connection',
      type: 'error',
    })

    return true
  }

  // We mostly do not have a proper error
  if (!error.response) {
    // do not proceed
    return false
  }

  const requestUrl = error.config.url
  const notStatusEndpoint = requestUrl !== '/auth/status'
  const notLoginEndpoint = requestUrl !== '/auth/login'
  const notLogoutEndpoint = requestUrl !== '/auth/logout'

  const notAuthEndpoint =
    notStatusEndpoint && notLoginEndpoint && notLogoutEndpoint
  const isNotAuthorized = error.request.status === 401 && notAuthEndpoint

  if (isNotAuthorized) {
    if (options?.showLoginModal) {
      const { showLoginModal } = options
      showLoginModal({
        title: 'Session Expired',
        text: 'Please login to continue ...',
      })
    } else {
      // just notify
      notify({
        text: 'Session Expired: Please login to continue',
        type: 'error',
      })
    }
    // skip proceeding to rejecting the error
    // this error is handled here
    return true
  }

  // 406 code is used for not enough permission
  if (error.request.status === 406) {
    notify({
      text: 'Not enough permission for this action',
      type: 'error',
    })

    return true
  }

  // if we have an error handler passed then we are handling the error
  if (options?.errorHandler) {
    // handle the error
    options?.errorHandler()

    // we are handling the error
    return true
  }

  // we are not handling the error
  // it is a signal for us to pass the error handling down the chain
  return false
}
// IMPORTANT: This error handling will work only for CLIENT SIDE requests
// The initial http requests on page load will be missed
// Only the SPA api calls happening from within the app will be handled here
function _setupAxios(axiosSetupOptions: AxiosSetupOptions) {
  // before intercepting eject previous interceptor
  if (axiosInterceptor) {
    axios.interceptors.response.eject(axiosInterceptor)
  }

  // global request/response handling with axios
  // handle no internet connection
  // ref: https://axios-http.com/docs/interceptors
  axiosInterceptor = axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    error => {
      const isErrorHandled = handleHTTPErrors(error, axiosSetupOptions)

      if (!isErrorHandled) {
        // we are throwing the error back
        return Promise.reject(error)
      } else {
        return error
      }
    }
  )
}

export const setupAxios = debounce(
  (options: AxiosSetupOptions) => _setupAxios(options),
  314
)
