import React from 'react'

interface Props {
  value: string
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  handleBlur: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
}

function Comments({ value, handleChange, handleBlur }: Props) {
  return (
    <div className="mb-4">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="job-comments"
      >
        Comments
      </label>
      <textarea
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="job-comments"
        placeholder="Comments/Description/Thoughts ... "
        rows={7}
        name={'comments'}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  )
}

export default Comments
