import React from 'react'

interface Props {
  value?: string
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur: (e: React.ChangeEvent<HTMLInputElement>) => void
  errors?: string
}

function CompanyUrl({ value, handleBlur, handleChange, errors }: Props) {
  return (
    <div className="mb-4">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="company-site"
      >
        Company URL
      </label>
      <input
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        id="company-site"
        type="text"
        placeholder="Website of company ... "
        name={'company_url'}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {errors && <p className="text-red-500 text-xs italic my-1">{errors}</p>}
    </div>
  )
}

export default CompanyUrl
