import React, { useContext } from 'react'

import { DemoContext } from 'Try/DemoContext'
import { Section } from 'Pages/Dashboard/Styles/'
import JobSearch from 'Components/JobSearch/'
import JobList from 'Components/JobList/'

function Dashboard() {
  const { recentJobs, recentSearch } = useContext(DemoContext)
  const jobList = recentJobs.sort((j1, j2) => {
    return (
      new Date(j2.updated_at as string).getTime() -
      new Date(j1.updated_at as string).getTime()
    )
  })
  return (
    <div>
      <Section>
        <JobSearch recentJobs={recentJobs} recentSearch={recentSearch} />
      </Section>
      <Section>
        <JobList jobs={jobList} />
      </Section>
    </div>
  )
}

export default Dashboard
