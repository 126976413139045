import React from 'react'
import Modal from 'react-modal'

import { notify } from 'Util/Noty'
import { LoginForm } from 'Pages/Login/'
import { modalStyles } from 'Util/Styles'
import { X } from 'Svg/'

// bind modal to root element
Modal.setAppElement('#root')

interface Props {
  isOpen: boolean
  setModalStatus: React.Dispatch<React.SetStateAction<boolean>>
}

function LoginModal({ isOpen, setModalStatus }: Props) {
  return (
    <Modal
      isOpen={isOpen}
      style={{
        ...modalStyles,
        content: {
          top: '5%',
          left: '15%',
          width: '70%',
          height: '85%',
        },
      }}
      onRequestClose={() => setModalStatus(false)}
    >
      <div className="flex flex-col mb-1 h-full">
        <div className="flex w-full items-center text-gray-700 border-b border-gray-400 mb-3 px-1 py-3">
          <div className="font-bold text-2xl">{'Login'}</div>
          <div
            className="ml-auto hover:opacity-75 cursor-pointer"
            onClick={() => setModalStatus(false)}
          >
            <X />
          </div>
        </div>
        <div className="flex flex-col flex-grow px-1 w-full overflow-y-auto pr-6">
          <LoginForm
            afterLoginCallback={() => {
              setModalStatus(false)
              notify({
                type: 'success',
                text: 'Login successful',
              })
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

export default LoginModal
