import * as Yup from 'yup'

export const JobSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Too Short!')
    .required('Please enter a name for the Job ...'),
  company_url: Yup.string().url('Please enter a valid link').nullable(),
  /* job_url: Yup.string()
    .url('Please enter a valid link')
    .required('Please enter a link for the job'), */
  // ref: https://github.com/jquense/yup/issues/773
  job_url: Yup.lazy(value =>
    String(value).startsWith('http') || String(value).startsWith('www')
      ? Yup.string()
          .url('Job link or email to apply')
          .required('Please enter an email or url')
      : Yup.string()
          .email('Job link or email to apply')
          .required('Please enter an email or url')
  ),
  job_search: Yup.number().nullable().notRequired(),
})
