import React from 'react'
import { isNil } from 'lodash'
import Select from 'react-select'

import { RecentSearch } from 'Types/Search'

interface Props {
  recentSearch: RecentSearch
  value?: number
  onChange: (id?: number) => void
}

function CurrentSearch({ recentSearch, value, onChange }: Props) {
  if (isNil(recentSearch)) {
    return null
    /* return (
      <div className="bg-yellow-300 p-3 font-normal text-gray-800 rounded border leading-tight">
        {`No active job search found. Please start a job search to add the job directly to your search.`}
      </div>
    ) */
  }

  const options = [
    {
      label: 'Do not add job to current search',
      value: null,
    },
    {
      label: recentSearch.name,
      value: recentSearch.id,
    },
  ]

  return (
    <div className="flex flex-col border-b border-gray-300 pb-2 mb-3">
      <label className="block text-gray-700 text-reg font-semibold mb-2">
        {'Add job to search'}
      </label>

      <Select
        isClearable={false}
        isSearchable={false}
        options={options}
        value={
          value
            ? {
                label: recentSearch.name,
                value: recentSearch.id,
              }
            : {
                label: 'Do not add job to search',
                value: null,
              }
        }
        onChange={(l: any) => {
          onChange(l?.value)
        }}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,

            primary: '#9f7aea',
            primary75: '#b794f4',
            primary50: '#d6bcfa',
            primary25: '#e9d8fd',
          },
        })}
      />
    </div>
  )
}

export default CurrentSearch
