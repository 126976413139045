import React, { useState, useEffect } from 'react'
import { isUndefined } from 'lodash'

import { RecentJobs, RecentJobDetail } from 'Types/Job/'
import { listJobs } from 'Util/Server/Job'
import { Section } from './Styles/'
import JobSearch from 'Components/JobSearch/'
import JobList from 'Components/JobList/'
import { BarLoader } from 'Assets/Loaders/'
import useRecentJobSearch from 'Hooks/Search/useRecentJobSearch'

function Dashboard() {
  const recentSearch = useRecentJobSearch()
  const [recentJobs, setRecentJobs] = useState<RecentJobs | undefined>(
    undefined
  )

  useEffect(() => {
    listJobs({
      successCallback: (jobs: Array<RecentJobDetail>) => {
        setRecentJobs(jobs)
      },
      errorCallback: () => {},
    })
  }, [])

  if (isUndefined(recentJobs)) {
    return <BarLoader />
  }

  return (
    <div>
      <Section>
        <JobSearch recentJobs={recentJobs} recentSearch={recentSearch} />
      </Section>
      <Section>
        <JobList jobs={recentJobs} />
      </Section>
    </div>
  )
}

export default Dashboard
