import React, { useState, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import Button, { ButtonSize, ButtonType } from 'Common/Button'
import { ChevronLeft, FolderAdd, Add } from 'Svg/'
import Details from 'Pages/Search/Show/Details'
import JobModal from 'Components/JobModal'
import { DemoContext } from 'Try/DemoContext'
import AddProspectsModal from 'Components/JobSearch/AddProspectsModal'

function JobSearch() {
  const history = useHistory()
  // data injected from demo context
  const {
    tags,
    locations,
    recentSearch,
    recentJobs,
    updateRecentSearch,
  } = useContext(DemoContext)
  const [isJobModalOpen, setJobModalStatus] = useState(false)
  const [prospectsModalStatus, setProspectsModalStatus] = useState(false)

  // calculate which jobs to add
  const _calculateJobsToAdd = useCallback(() => {
    const current_prospects = (recentSearch?.prospects || []).map(p => p.job.id)

    return (recentJobs || []).filter(j => !current_prospects.includes(j.id))
  }, [recentJobs, recentSearch])

  return (
    <div className="max-w-3xl pb-32">
      <div>
        <div
          className="flex items-center hover:underline text-gray-700 text-reg my-3 font-semibold cursor-pointer"
          onClick={() => history.push('/try-demo/dashboard')}
        >
          <div className="w-6 h-6 -ml-2">
            <ChevronLeft />
          </div>
          <div className="">Dashboard</div>
        </div>
      </div>
      <div className="flex justify-end my-5">
        <Button
          disabled={isEmpty(_calculateJobsToAdd())}
          size={ButtonSize.Small}
          type={ButtonType.PrimaryDark}
          text={'Add Existing'}
          Icon={FolderAdd}
          onClick={() => setProspectsModalStatus(true)}
        />
        <Button
          size={ButtonSize.Small}
          type={ButtonType.PrimaryDark}
          text={'Add New Job'}
          Icon={Add}
          onClick={() => setJobModalStatus(true)}
        />
      </div>
      <Details
        details={recentSearch}
        setDetails={updateRecentSearch}
        recentJobs={recentJobs}
        fetchSearchDetails={() => {}}
      />

      {/* Job Modal */}
      <JobModal
        mode={'create'}
        isOpen={isJobModalOpen}
        setStatus={setJobModalStatus}
        locations={locations}
        tags={tags}
        recentSearch={recentSearch}
      />

      {/* Add Prospects to Search Modal */}
      <AddProspectsModal
        search_id={recentSearch.id as number}
        search_name={recentSearch.name}
        jobs={_calculateJobsToAdd()}
        isOpen={prospectsModalStatus}
        setStatus={setProspectsModalStatus}
        afterProspectsAdd={() => {}}
      />
    </div>
  )
}

export default JobSearch
