import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

export enum BadgeType {
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Inactive = 'inactive',
}

const Holder = styled.div<{
  type: BadgeType
}>`
  ${tw`flex items-center justify-center h-5 border border-gray-400 rounded p-2`}
  ${({ type }) => {
    switch (type) {
      default:
        return tw`text-gray-500 bg-gray-100`
    }
  }}

  & .dot {
    ${tw`w-2 h-2 rounded rounded-full border mr-2`}

    ${({ type }) => {
      switch (type) {
        case BadgeType.Success:
          return tw`border-green-400 bg-green-400`

        case BadgeType.Warning:
          return tw`border-yellow-400 bg-yellow-400`

        case BadgeType.Danger:
          return tw`border-red-400 bg-red-400`
      }
    }}
  }
`

const Text = styled.div`
  ${tw`h-4 flex items-center justify-center leading-relaxed text-xs uppercase font-semibold`}
`

function Badge({ type, text }: { type: BadgeType; text: string }) {
  return (
    <Holder type={type}>
      <div className="dot"></div>

      <Text>{text}</Text>
    </Holder>
  )
}

const StatusHolder = styled.div<{
  type: BadgeType
}>`
  ${tw`flex items-center justify-center h-5 bg-gray-500 rounded p-2 text-gray-100 text-xs uppercase font-semibold`}
`

// status of job prospect
export function Status({ type, text }: { type: BadgeType; text: string }) {
  return <StatusHolder type={type}>{text}</StatusHolder>
}

export default Badge
