import React, { useContext, useEffect } from 'react'
import { isUndefined } from 'lodash-es'
import { Route, useHistory } from 'react-router-dom'

import { notify } from 'Util/Noty/'
import { AuthContext } from 'Context/Auth/'
import ConfirmEmailNotifier from 'Components/ConfirmEmailNotifier/'

interface RouteProp {
  path: string
  children: React.ReactNode
}

// helper component to notify and redirect
function NotifyAndRedirect() {
  const history = useHistory()
  useEffect(() => {
    notify({
      type: 'error',
      text: 'Please login to access this page',
    })
    // redirect to login page
    history.push('/login')
  }, [history])

  return <div>Please log in to view this page ...</div>
}

// route that requires loggedin user
function AuthRoute({ children, path }: RouteProp) {
  const { authStatus } = useContext(AuthContext)

  /*
   * NOTE:
   * authStatus => undefined
   * Data/Status is not yet fetched from server
   */

  if (isUndefined(authStatus)) {
    // do not proceed
    return null
  }

  const { token, user } = authStatus
  const isLoggedIn = token && user

  return isLoggedIn ? (
    // NOTE: Wrap in 'Route' so that we can access params with 'useParams'
    <div>
      <ConfirmEmailNotifier />
      <Route path={path}>{children}</Route>
    </div>
  ) : (
    <NotifyAndRedirect />
  )
}

export default AuthRoute
