import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

import { Location, Tag as TagIcon } from 'Svg/'

export const Section = styled.div`
  margin: 16px 0;
`

export const Name = styled.div`
  ${tw`text-2xl text-gray-700 font-semibold leading-10`};
`

export const Tag = styled.span`
  ${tw`inline-flex items-center border border-purple-300 text-purple-700 font-medium p-1 rounded-sm text-xs my-1`};
`

export const Comments = styled.div`
  ${tw`flex p-3 text-gray-900 leading-tight border border-gray-300 rounded`};
  white-space: pre-line;
  font-size: 0.89rem;
`

export const TimePeriod = styled.div`
  ${tw`text-xs italic text-gray-700 mb-1`}

  & .label {
    ${tw`font-light`}
  }
`

const LinkTextHolder = styled.span`
  margin-left: auto;
  ${tw`flex items-center leading-7`}

  & .label {
    ${tw`flex items-center text-gray-700 uppercase text-xs mr-3 font-semibold`}

    & .icon {
      margin-right: 4px;
      ${tw`flex items-center w-4 h-4 text-gray-500`}
    }
  }
`

export function LinkText({
  Icon,
  text,
  children,
}: {
  Icon: React.ComponentType<any>
  text: string
  children: React.ReactNode
}) {
  return (
    <LinkTextHolder>
      <div className={'label'}>
        <div className={'icon'}>
          <Icon />
        </div>
        <div className={''}>
          {text}
          {':'}
        </div>
      </div>

      <div className="space-x-1 flex items-center">{children}</div>
    </LinkTextHolder>
  )
}

const TagWrapper = styled.span`
  display: flex;
  align-items: center;

  & .icon {
    margin-right: 8px;
    ${tw`inline-block w-4 h-4 text-purple-500`}
  }
`

export function TagHolder({
  children,
  showIcon,
}: {
  children: React.ReactNode
  showIcon: boolean
}) {
  return (
    <TagWrapper>
      {showIcon && (
        <div className={'icon'}>
          <TagIcon />
        </div>
      )}

      <div className="space-x-1">{children}</div>
    </TagWrapper>
  )
}

const LocationInfoHolder = styled.span<{
  showBorder?: boolean
}>`
  ${tw`inline-flex items-center text-gray-600 font-semibold rounded text-xs uppercase`};
  ${({ showBorder }) =>
    showBorder && tw`text-purple-700 border border-purple-300 p-2 py-1`}

  & .icon {
    margin-right: 8px;
    ${tw`inline-block w-4 h-4 text-purple-500`}
  }
`

export function LocationInfo({
  name,
  showBorder,
  showIcon,
}: {
  name: string
  showBorder?: boolean
  showIcon?: boolean
}) {
  return (
    <LocationInfoHolder showBorder={showBorder}>
      {showIcon && (
        <span className={'icon'}>
          <Location />
        </span>
      )}

      <span>{name}</span>
    </LocationInfoHolder>
  )
}
