import React, { useContext } from 'react'
import Modal from 'react-modal'
import Select from 'react-select'
import { startCase, delay } from 'lodash'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { ApiContext } from 'Context/Api/'
import { Minus, ClipboardCheck } from 'Svg'
import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import { Prospect } from 'Types/Search'
import { ProspectStatus } from 'Types/Enums'
import { confirm } from 'Util/Confirm'

// bind modal to root element
Modal.setAppElement('#root')

const customStyles = {
  overlay: {
    background: 'rgba(50, 50, 100, 0.9)',
    // 51, 40, 85
  },
  content: {
    top: '10%',
    left: '15%',
    width: '70%',
    height: '80%',
    right: 'auto',
    bottom: 'auto',

    // marginRight: '-50%',
    // transform: 'translate(-50%, -20%)',
    background: '#FCFCFC',
    opacity: 1,
    boxShadow:
      '0 4px 6px -1px rgba(255, 255, 255, 0.1), 0 2px 4px -1px rgba(255, 255, 255, 0.06)',
  },
}

interface Props {
  prospect: Prospect
  MetaData: React.ComponentType<any>
  isOpen: boolean
  setStatus: Function

  // callbacks
  updateProspectDetails: Function
}

function EditProspect({
  prospect,
  isOpen,
  setStatus,
  MetaData,
  updateProspectDetails,
}: Props) {
  const { editProspect, deleteProspect } = useContext(ApiContext)

  const closeModal = () => setStatus(false)

  const statusOptions = Object.values(ProspectStatus).map(p => ({
    label: startCase(p.toLowerCase()),
    value: p,
  }))

  const ProspectSchema = Yup.object().shape({
    status: Yup.string().required('Please select a status'),
    comments: Yup.string(),
  })

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      status: prospect.status,
      comments: prospect.comments || '',
    },
    validationSchema: ProspectSchema,
    onSubmit: values => {
      const payload = {
        id: prospect.id,
        search_id: prospect.search_id,
        job_id: prospect.job.id,
        ...values,
      }

      editProspect({
        prospect: payload,
        successCallback: (data: Prospect) => {
          updateProspectDetails(data)
          // close the modal
          closeModal()
        },
        errorCallback: () => {},
      })
    },
  })

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={() => closeModal()}
    >
      <form className="flex flex-col mb-1 h-full" onSubmit={form.handleSubmit}>
        <div className="flex w-full items-center text-gray-700 border-b border-gray-400 mb-3 px-1">
          <div className="font-bold text-2xl">{'Edit Job Prospect'}</div>
          <div
            className="ml-auto text-5xl hover:opacity-75 cursor-pointer pb-1"
            onClick={() => closeModal()}
          >
            &times;
          </div>
        </div>

        <div className="flex flex-col flex-grow px-1 w-full overflow-y-auto pr-6">
          {/* Job Name */}
          <div className="mb-4 border-b border-gray-300 pb-3">
            <label className="block text-gray-700 text-reg font-semibold mb-2">
              {prospect.job.name}
            </label>
            <MetaData />
          </div>

          {/* Prospect Status */}
          <div className="mb-4">
            <label className="block text-gray-700 text-reg font-semibold mb-2">
              {'Job Status'}
            </label>
            <Select
              isClearable={false}
              isSearchable={false}
              options={statusOptions}
              value={
                statusOptions.find(o => o.value === form.values.status) ||
                statusOptions[0]
              }
              onChange={(l: any) => {
                form.setFieldValue('status', l.value)
              }}
              theme={theme => ({
                ...theme,
                colors: {
                  ...theme.colors,

                  primary: '#9f7aea',
                  primary75: '#b794f4',
                  primary50: '#d6bcfa',
                  primary25: '#e9d8fd',
                },
              })}
            />
            {form.errors.status && (
              <p className="text-red-500 text-xs italic my-1">
                {form.errors.status}
              </p>
            )}
          </div>

          {/* Comments for job prospect */}
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="prospect-comments"
            >
              Comments
            </label>
            <textarea
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="prospect-comments"
              placeholder="Comments/Description/Thoughts ... "
              rows={7}
              name={'comments'}
              value={form.values.comments}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex items-center justify-between border-t border-gray-400 pt-3">
          <div className="flex gap-3">
            <Button
              type={ButtonType.Danger}
              text={'Remove'}
              Icon={Minus}
              onClick={() => {
                // open confirm box
                confirm({
                  text:
                    'Do you want to remove this prospect from active job search?',
                  buttonText: 'Remove Prospect',
                  successCallback: () => {
                    deleteProspect({
                      prospect: {
                        id: prospect.id,
                        search_id: prospect.search_id,
                        job_id: prospect.job.id,
                      },
                      successCallback: () => {
                        // update prospect details (it will refetch search details)
                        // add a delay to prevent memory leak warnings from react
                        delay(() => updateProspectDetails(), 500)
                        // close the modal
                        setStatus(false)
                      },
                      errorCallback: () => {},
                    })
                  },
                  errorCallback: () => {},
                })
              }}
            />
            <Button
              type={ButtonType.Tertiary}
              text={'Cancel'}
              onClick={() => closeModal()}
            />
          </div>

          <Button
            role={ButtonRole.Submit}
            type={ButtonType.Primary}
            Icon={ClipboardCheck}
            text={'Save Job Prospect'}
          />
        </div>
      </form>
    </Modal>
  )
}

export default EditProspect
