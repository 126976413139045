import React, { useState, useContext, useCallback } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'

import { JobDetail } from 'Types/Job/'

import { ChevronLeft, Edit } from 'Svg/'
import { ApiContext } from 'Context/Api/'
import { DemoContext } from 'Try/DemoContext'
import JobModal from 'Components/JobModal'
import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import Info from 'Pages/Job/Detail/Info'

type JobParams = {
  id: string
}

function Job() {
  const history = useHistory()
  const { id } = useParams<JobParams>()
  const { locations, tags, recentJobs, recentSearch } = useContext(DemoContext)
  const { _getSearchURL } = useContext(ApiContext)

  const [details, setDetails] = useState<JobDetail>(
    recentJobs.find(j => j.id === Number(id)) as JobDetail
  )

  // check if job is part of search
  const isPartOfRecentSearch =
    recentSearch &&
    recentSearch.prospects &&
    recentSearch?.prospects.find(p => p.job.id === details?.id)

  const [isJobModalOpen, setJobModalStatus] = useState(false)

  const location_id = details?.location_id
  const tag_ids = details?.tag_ids

  const _getLocation = useCallback(() => {
    const location = locations.find(l => l.id === location_id)

    return location ? location.name : ''
  }, [locations, location_id])

  const _getTags = useCallback(() => {
    return tags.filter(t => tag_ids?.split(',').includes(String(t.id)))
  }, [tags, tag_ids])

  const tagsList = _getTags()
  const locationName = _getLocation()

  return (
    <div className="max-w-3xl pb-32">
      <div>
        <div
          className="flex items-center hover:underline text-gray-700 text-reg my-3 font-semibold cursor-pointer"
          onClick={() => history.push('/try-demo/dashboard')}
        >
          <div className="w-6 h-6 -ml-2">
            <ChevronLeft />
          </div>
          <div className="">Dashboard</div>
        </div>
        {isPartOfRecentSearch && recentSearch && (
          <div className="p-3 my-2 bg-green-200">
            {`'${recentSearch && recentSearch.name}' prospect.`}
            <span className="font-semibold underline mx-3 hover:text-gray-600">
              <Link to={_getSearchURL()}>Continue Job Search</Link>
            </span>
          </div>
        )}
      </div>

      <Info details={details} tagsList={tagsList} locationName={locationName} />

      <div>
        <Button
          onClick={() => setJobModalStatus(true)}
          role={ButtonRole.Button}
          type={ButtonType.Primary}
          text={'Edit Job'}
          Icon={Edit}
        />
      </div>
      <JobModal
        mode={'edit'}
        isOpen={isJobModalOpen}
        setStatus={setJobModalStatus}
        locations={locations}
        tags={tags}
        jobDetails={details}
        setJobDetails={setDetails}
        recentSearch={recentSearch}
      />
    </div>
  )
}

export default Job
