import React, { useState, useContext } from 'react'
import Modal from 'react-modal'
import { useHistory } from 'react-router-dom'

import { ApiContext } from 'Context/Api'
import { RecentJobs } from 'Types/Job/'
import { BriefCase, CursorClick } from 'Svg/'
import { IconLink } from 'Common/ExternalLink/'
import Button, { ButtonType, ButtonRole } from 'Common/Button/'
import { AddProspect, AddedProspect } from './Styles/AddProspectButton'
import { parseJobURL } from 'Util/index'

// bind modal to root element
Modal.setAppElement('#root')

const customStyles = {
  overlay: {
    background: 'rgba(50, 50, 100, 0.9)',
    // 51, 40, 85
  },
  content: {
    top: '10%',
    left: '15%',
    width: '70%',
    height: '80%',
    right: 'auto',
    bottom: 'auto',

    // marginRight: '-50%',
    // transform: 'translate(-50%, -20%)',
    background: '#FCFCFC',
    opacity: 1,
    boxShadow:
      '0 4px 6px -1px rgba(255, 255, 255, 0.1), 0 2px 4px -1px rgba(255, 255, 255, 0.06)',
  },
}

interface Props {
  search_id: number
  search_name: string
  jobs: RecentJobs

  isOpen: boolean
  setStatus: Function

  afterProspectsAdd: () => void
}

function AddProspectsModal({
  search_id,
  search_name,
  jobs,

  isOpen,
  setStatus,

  afterProspectsAdd,
}: Props) {
  const history = useHistory()
  const { addProspectsToSearch, _getSearchURL } = useContext(ApiContext)
  const [addedJobs, setAddedJobs] = useState<Array<number>>([])

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={() => setStatus(false)}
    >
      <div className="flex flex-col mb-1 h-full ">
        <div className="flex w-full items-center text-gray-700 border-b border-gray-400 mb-3 px-1">
          <div className="font-bold text-2xl">{search_name}</div>
          <div
            className="ml-auto text-5xl hover:opacity-75 cursor-pointer pb-1"
            onClick={() => setStatus(false)}
          >
            &times;
          </div>
        </div>

        <div className="flex flex-col flex-grow px-1 w-full overflow-y-auto pr-6">
          {jobs?.map(j => (
            <div
              key={j.id}
              className="flex divide-y-0 divide-x border border-gray-200 text-sm"
            >
              <div className="w-1/2 p-2 flex items-center">{j.name}</div>
              <div className="w-1/12">
                <IconLink Icon={CursorClick} href={parseJobURL(j.job_url)} />
              </div>
              <div className="w-1/12">
                {j.company_url && (
                  <IconLink href={j.company_url} Icon={BriefCase} />
                )}
              </div>
              <div className="w-1/3 flex items-center justify-center">
                {addedJobs.includes(j.id) ? (
                  <AddedProspect
                    onClick={() =>
                      setAddedJobs(currentJobs =>
                        currentJobs.filter(c => c !== j.id)
                      )
                    }
                  />
                ) : (
                  <AddProspect
                    onClick={() =>
                      setAddedJobs(currentJobs => [j.id, ...currentJobs])
                    }
                  />
                )}
              </div>
            </div>
          ))}
        </div>

        {/* Action Buttons */}
        <div className="flex items-center justify-between border-t border-gray-400 pt-3 px-1">
          <Button
            role={ButtonRole.Button}
            type={ButtonType.Tertiary}
            text={'Cancel'}
            onClick={() => setStatus(false)}
          />

          <Button
            role={ButtonRole.Button}
            type={ButtonType.Primary}
            text={'Save Job Search'}
            onClick={() => {
              addProspectsToSearch({
                prospects: addedJobs,
                search_id,
                successCallback: () => {
                  // close the modal
                  setStatus(false)
                  // redirect to search page
                  history.push(_getSearchURL(search_id))
                  // execute afterProspectsAdd callback
                  afterProspectsAdd()
                },
                errorCallback: () => {},
              })
            }}
          />
        </div>
      </div>
    </Modal>
  )
}

AddProspectsModal.defaultProps = {
  afterProspectsAdd: () => {},
} as Partial<Props>

export default AddProspectsModal
