import axios from 'axios'
import { pick } from 'lodash'
import { notify } from 'Util/Noty/'

import { Search } from 'Types/Search'

interface Callback {
  successCallback: Function
  errorCallback: Function
}

interface SearchDetailsProps extends Callback {
  id: string
}

// get details for given job id
export function getSearchDetails({
  id,
  successCallback,
  errorCallback,
}: SearchDetailsProps) {
  axios
    .get(`/api/job_search/${id}`)
    .then(({ data: { data } }) => {
      successCallback(data)
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to continue.',
        })
      } else {
        notify({
          type: 'error',
          text: 'Error getting job search details. Please try again.',
        })
      }

      errorCallback()
    })
}

// get search history
export function getSearchHistory({ successCallback, errorCallback }: Callback) {
  axios
    .get('/api/job_search_history')
    .then(({ data }) => {
      successCallback(data)
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to start a job search',
        })
        // do not proceed
        return
      }

      errorCallback()
    })
}

// get recent search details
export function getRecentSearch({ successCallback, errorCallback }: Callback) {
  axios
    .get('/api/job_search/recent')
    .then(({ data: { data } }) => {
      successCallback(data)
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to start a job search',
        })
        // do not proceed
        return
      }

      errorCallback()
    })
}

export interface JobSearchProps {
  search: Search
  successCallback: Function
  errorCallback: Function
}

// start new job search
export function newSearch({
  search,
  successCallback,
  errorCallback,
}: JobSearchProps) {
  axios
    .post('/api/job_search/new', {
      search,
    })
    .then(({ data: { data } }) => {
      notify({
        type: 'success',
        text: `${data.name} added.`,
      })
      successCallback({ search_id: data.id })
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to start a job search',
        })
      } else {
        notify({
          type: 'error',
          text:
            'Error creating new job search. Please try again or contact me at arun@jublime.com',
        })
      }
      errorCallback()
    })
}

/*
 * Edit Job Search
 */
export function editSearch({
  search,
  successCallback,
  errorCallback,
}: JobSearchProps) {
  axios
    .post('/api/job_search/update', {
      id: search.id,
      // we will send only 'name/comments'
      search: pick(search, ['name', 'comments']),
    })
    .then(({ data: { data } }) => {
      notify({
        type: 'success',
        text: `${data.name} updated.`,
      })
      const search_details: Search = data
      successCallback(search_details)
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to start a job search',
        })
      } else {
        notify({
          type: 'error',
          text:
            'Error updating job search. Please try again or contact me at arun@jublime.com',
        })
      }
      errorCallback()
    })
}

export interface ConcludeProps extends Callback {
  id: number
}

// conclude job search
export function concludeSearch({
  id,
  successCallback,
  errorCallback,
}: ConcludeProps) {
  axios
    .get(`/api/job_search/conclude/${id}`)
    .then(() => {
      successCallback()
      notify({
        type: 'success',
        text: 'Job Search concluded',
      })
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to start a job search',
        })
        // do not proceed
        return
      }

      notify({
        type: 'error',
        text: 'Error concluding Job Search. Please try again',
      })

      errorCallback()
    })
}
