// by default take jobs from our seed data
import { _getSearchId } from 'Try/ApiContext'
import prospects from './Prospects'

const DATA = {
  comments: null,
  completed_at: null,
  id: _getSearchId(),
  inserted_at: new Date().toISOString(),
  name: 'My next Remote Technical Writing Job',
  paused_at: null,
  prospects,
  resumed_at: null,
  status: 'ACTIVE',
  updated_at: new Date().toISOString(),
}

export default DATA