import React, { useState, useEffect } from 'react'

import { fetchAuthStatus, fetchLocations, fetchTags } from 'Util/Server/Auth'

// ---------------------------------------------------------------------------
// START: Types
// ---------------------------------------------------------------------------

// START: location types
type Location = {
  id: number
  name: string
}

export type Locations = Array<Location>

export type SetLocations = React.Dispatch<React.SetStateAction<Locations>>

// ---------------------------------------------------------------------------
// START: Tag type
type Tag = {
  id: string
  name: string
}

export type Tags = Array<Tag>

export type SetTags = React.Dispatch<React.SetStateAction<Tags>>

// ---------------------------------------------------------------------------

export interface UserValues {
  id: number
  email: string
  is_admin: boolean
  confirmed_at?: string
}

export type AuthNotInited = undefined

export type AuthStatus =
  | {
      // null indicates user is not logged in (but data is received from server)
      token: string | null
      user: UserValues | null
    }
  // not inited (undefined) means we don't have data from server
  | AuthNotInited

export type AuthState = {
  authStatus: AuthStatus
}

export type SetAuthStatus = React.Dispatch<React.SetStateAction<AuthState>>

type AuthContextValue = {
  authStatus: AuthStatus
  updateAuthStatus: SetAuthStatus

  // locations
  locations: Locations
  refreshLocations: Function
  // tags
  tags: Tags
  refreshTags: Function
}

type Props = {
  children: React.ReactNode
}

// ---------------------------------------------------------------------------
// END: Types
// ---------------------------------------------------------------------------

const defaultContext: AuthContextValue = {
  authStatus: undefined,
  updateAuthStatus: () => {},

  locations: [],
  refreshLocations: () => {},

  tags: [],
  refreshTags: () => {},
}

export const AuthContext = React.createContext<AuthContextValue>(defaultContext)

const defaultState: AuthState = {
  authStatus: undefined,
}

function Auth({ children }: Props) {
  const [authStatus, setAuthStatus] = useState<AuthState>(defaultState)
  // locations
  const [locations, setLocations] = useState<Locations>([])
  // user tags
  const [tags, setTags] = useState<Tags>([])

  // fetch status from server
  useEffect(() => {
    fetchAuthStatus({
      setAuthStatus,
    })
  }, [])

  // fetch locations (if the user is authenticated)
  useEffect(() => {
    const { authStatus: status } = authStatus
    const isLoggedIn = status && status?.user && status?.token

    if (!isLoggedIn) {
      // do not fetch locations
      return
    }

    // we can fetch locations as the user is logged in
    fetchLocations({
      setLocations,
    })
  }, [authStatus])

  // fetch tags (if the usr is authenticated)
  useEffect(() => {
    const { authStatus: status } = authStatus
    const isLoggedIn = status && status?.user && status?.token

    if (!isLoggedIn) {
      // do not fetch locations
      return
    }

    // we can fetch locations as the user is logged in
    fetchTags({
      setTags,
    })
  }, [authStatus])

  return (
    <AuthContext.Provider
      value={{
        ...authStatus,
        updateAuthStatus: setAuthStatus,
        // locations
        locations,
        refreshLocations: () =>
          fetchLocations({
            setLocations,
          }),
        // tags
        tags,
        refreshTags: () =>
          fetchTags({
            setTags,
          }),
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default Auth
