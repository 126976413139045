import React from 'react'
import { ExternalLink } from 'Svg/'

export function IconLink({
  href,
  Icon,
}: {
  href: string
  Icon: React.ComponentType<any>
}) {
  return (
    <div
      className="flex items-center justify-center w-full h-full hover:border rounded-sm text-gray-500 hover:bg-purple-500 hover:text-white hover:duration-300 duration-300"
      onClick={e => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()

        window.open(href, '_blank')
      }}
    >
      <button className="w-5 h-5">
        <Icon />
      </button>
    </div>
  )
}

function Link({ href, text }: { href?: string; text?: string }) {
  if (!href) {
    return (
      <div className="text-xs italic text-gray-600 leading-6">
        Not available
      </div>
    )
  }

  return (
    <a
      className="flex items-end italic text-sm hover:underline text-gray-700"
      href={href}
      target="blank"
      rel="noopener noreferrer"
    >
      <div
        className="text-reg"
        style={{
          marginTop: '1px',
        }}
      >
        {text || href}
      </div>
      <div
        className="flex items-center ml-1 w-4 h-4 text-gray-500"
        style={{
          marginBottom: '1px',
        }}
      >
        <ExternalLink />
      </div>
    </a>
  )
}

export default Link
