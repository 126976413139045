import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { Add } from 'Svg/'
import Button, { ButtonType, ButtonSize } from 'Common/Button/'
import { AuthContext } from 'Context/Auth/'
import JobModal from 'Components/JobModal'
import { notify } from 'Util/Noty'
import useRecentJobSearch from 'Hooks/Search/useRecentJobSearch'

interface Props {
  size?: ButtonSize
  text?: string
}

function AddJobAction({ size, text }: Props) {
  const history = useHistory()
  const recentSearch = useRecentJobSearch()
  const { authStatus, locations, tags } = useContext(AuthContext)

  const [isJobModalOpen, setJobModalStatus] = useState(false)

  const isLoggedIn = authStatus && authStatus.token && authStatus.user

  return (
    <div className="flex">
      <Button
        type={ButtonType.PrimaryDark}
        size={size || ButtonSize.Normal}
        Icon={Add}
        onClick={() => {
          if (isLoggedIn) {
            // if there are no locations? notify the user
            // open add job modal
            setJobModalStatus(true)
            // do not proceed
            return
          }

          // notify
          notify({
            type: 'error',
            text: 'Please login to add a job',
          })
          // redirect to login page
          history.push('/login')
        }}
        text={text || 'Add Job'}
      />

      <JobModal
        mode={'create'}
        isOpen={isJobModalOpen}
        setStatus={setJobModalStatus}
        locations={locations}
        tags={tags}
        recentSearch={recentSearch}
      />
    </div>
  )
}

export default AddJobAction
