import React from 'react'

// prospect related handlers
import {
  addProspectsToSearch,
  AddProspectProps,
  editProspect,
  EditProspectProps,
  deleteProspect,
  DeleteProspectProps,
} from 'Util/Server/Prospect'
// job related handlers
import { addJob, editJob, JobActionProps } from 'Util/Server/Job'
// search related handlers
import {
  newSearch,
  editSearch,
  concludeSearch,
  JobSearchProps,
  ConcludeProps,
} from 'Util/Server/JobSearch'

type Props = {
  children: React.ReactNode
}

interface ApiContextValue {
  // helper function to get 'search url'
  // app: '/job/search/<ID>'
  // demo: 'try-demo/my-job-search'
  _getSearchURL: (id?: number | string) => string

  // helper function to get 'job url'
  // app: '/job/detail/<ID>'
  // demo: 'try-demo/job-detail/<ID>'
  _getJobURL: (id: number | string) => string

  // prospect related handlers
  addProspectsToSearch: (props: AddProspectProps) => void
  editProspect: (props: EditProspectProps) => void
  deleteProspect: (props: DeleteProspectProps) => void
  // job related handlers
  addJob: (props: JobActionProps) => void
  editJob: (props: JobActionProps) => void

  // job search related handlers
  newSearch: (props: JobSearchProps) => void
  editSearch: (props: JobSearchProps) => void
  concludeSearch: (props: ConcludeProps) => void
}

const defaultContext: ApiContextValue = {
  // helper function to get 'search url'
  // app: '/job/search/<ID>'
  // demo: 'try-demo/my-job-search'
  _getSearchURL: id => `/job/search/${id}`,
  // helper function to get 'job url'
  // app: '/job/detail/<ID>'
  // demo: 'try-demo/job-detail/<ID>'
  _getJobURL: id => `/job/detail/${id}`,

  // prospect related handlers
  addProspectsToSearch,
  editProspect,
  deleteProspect,
  // job related handlers
  addJob,
  editJob,
  // job search related handlers
  newSearch,
  editSearch,
  concludeSearch,
}

export const ApiContext = React.createContext<ApiContextValue>(defaultContext)

function Api({ children }: Props) {
  return (
    <ApiContext.Provider value={defaultContext}>{children}</ApiContext.Provider>
  )
}

export default Api
