import React, { RefObject, createRef, PureComponent } from 'react'
import { defer, isEqual } from 'lodash'
import CreatableSelect from 'react-select/creatable'
import NormalSelect, { Props as SelectProps } from 'react-select'

import { Tags } from 'Context/Auth/'

type NewValue = {
  __isNew__?: boolean
  label: string
  value: string
}

type Value = {
  id: string
  name: string
  isNew?: boolean
}

type InitialValue = Value & NewValue

export type Values = Array<Value>

type Props = {
  tags: Tags
  value: Values
  onChange: (val: Values) => void
  creatable?: boolean
  placeholder?: string
  autoFocus?: boolean
}

class TagSelect extends PureComponent<Props> {
  static defaultProps = {
    creatable: true,
    placeholder: 'Select Tags',
    autoFocus: true,
  }

  $holder: RefObject<HTMLDivElement> = createRef()

  // helper function to focus input
  focusInput() {
    const $holder = this.$holder

    // run this after clearing the callstack
    // to prevent confusions when component remounts again
    defer(() => {
      let $input:
        | HTMLInputElement
        | null
        | undefined = $holder.current?.querySelector('input[type=text]')

      if ($input) {
        // focus the input whenever value has changed
        // NOTE: it will also focus on initial mount
        // but that is okay for now
        $input.focus()
      }
    })
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      // focus the input
      this.focusInput()
    }
  }

  componentDidUpdate(prevProps: Props) {
    // PureComponet does only shallow comparison
    // our 'value' is an Array which requires deep comparison
    if (!isEqual(prevProps.value, this.props.value)) {
      // focus the input when value changed
      this.focusInput()
    }
  }

  Select = (props: SelectProps) => {
    return this.props.creatable ? (
      <CreatableSelect {...props} />
    ) : (
      <NormalSelect {...props} />
    )
  }

  render() {
    const { tags, value, onChange, placeholder } = this.props

    const Select = this.Select

    const initialValue = (value as Values).map(v => ({
      isNew: v.isNew,
      label: v.name,
      value: v.id,
    }))

    return (
      <div ref={this.$holder}>
        <Select
          inputId={'tag-input'}
          isMulti
          isSearchable
          menuPlacement={'auto'}
          value={initialValue as Array<InitialValue>}
          placeholder={placeholder}
          onChange={vals => {
            const values = vals || []

            // massage the value correctly for api
            const addedTags = (values as Array<InitialValue>).map(v => {
              return v.__isNew__
                ? {
                    // generate a unique id ourselves for tracking
                    id: `${v.label}-${new Date().getTime()}`,
                    name: v.label,
                    isNew: true,
                  }
                : {
                    id: v.value,
                    name: v.label,
                    isNew: v.isNew,
                  }
            })

            onChange(addedTags)
          }}
          noOptionsMessage={() => 'Type and click/press to create new tags'}
          styles={{
            multiValue: styles => ({
              ...styles,
              background: '#9f7aea',
              color: 'white',
            }),

            multiValueLabel: styles => ({
              ...styles,
              background: '#9f7aea',
              color: 'white',
            }),
          }}
          options={tags.map(l => ({
            ...l,
            value: l.id,
            label: l.name,
          }))}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,

              primary: '#9f7aea',
              primary75: '#b794f4',
              primary50: '#d6bcfa',
              primary25: '#e9d8fd',
            },
          })}
        />
      </div>
    )
  }
}

export default TagSelect
