import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import useRecentJobSearch from 'Hooks/Search/useRecentJobSearch'
import { ClipboardList } from 'Svg/'
import Button, { ButtonType } from 'Common/Button/'
import Gist from 'Components/JobSearch/Gist'
import SearchList from './SearchList'
import SearchModal from 'Components/JobSearchModal'

function History() {
  const history = useHistory()
  const recentSearch = useRecentJobSearch()
  const [searchModalStatus, setSearchModalStatus] = useState(false)

  return (
    <div>
      <div className="flex items-center border p-3">
        <div className="flex w-full justify-center items-center">
          {recentSearch && recentSearch.id ? (
            <div className="flex flex-col w-full justify-center p-5">
              <Gist {...recentSearch} />
              <div className="flex w-full justify-center items-center my-3">
                <div>
                  <Button
                    type={ButtonType.PrimaryDark}
                    text={'Continue Job Search'}
                    onClick={() =>
                      history.push(`/job/search/${recentSearch.id}`)
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <Button
              Icon={ClipboardList}
              type={ButtonType.PrimaryDark}
              text="Start Job Search"
              onClick={() => setSearchModalStatus(true)}
            />
          )}
        </div>
      </div>
      <SearchList />
      {/* Add New Job Search Modal */}
      <SearchModal
        mode={'create'}
        isOpen={searchModalStatus}
        setStatus={setSearchModalStatus}
      />
    </div>
  )
}

export default History
