import React, { useState, useContext } from 'react'

import { X } from 'Svg/'
import { AuthContext } from 'Context/Auth/'
import ResendConfirmation from './ResendEmailConfirmation'

function ConfirmEmailNotifier() {
  const { authStatus } = useContext(AuthContext)
  const isLoggedIn = authStatus && authStatus.token && authStatus.user

  const [showMessage, setMessageStatus] = useState(true)

  if (!isLoggedIn) {
    return null
  }

  const isEmailConfirmed = authStatus?.user?.confirmed_at

  if (isEmailConfirmed) {
    return null
  }

  // show 'Confirm email message'
  return showMessage ? (
    <div className="flex items-center justify-center p-3 rounded text-center bg-yellow-300 text-gray-700 font-semibold mb-8">
      <div className="flex flex-col w-full">
        {`Please confirm your email address - ${authStatus?.user?.email}. `}
        <ResendConfirmation />
        <div className="italic font-normal text-xs my-1">
          Please check your spam for emails from{' '}
          <span className="font-semibold">arun@jublime.com</span> and mark it as
          not spam.
        </div>
      </div>
      <div
        className="ml-auto hover:opacity-75 cursor-pointer"
        onClick={() => setMessageStatus(false)}
      >
        <X />
      </div>
    </div>
  ) : null
}

export default ConfirmEmailNotifier
