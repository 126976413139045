import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from 'react'
import { Link } from 'react-router-dom'

import { AuthContext } from 'Context/Auth/'
import { UserCircle, DocSearch, Logout } from 'Svg/'

function AccountMenu() {
  const { authStatus } = useContext(AuthContext)

  const [showMenu, setMenuStatus] = useState(false)
  const $menuEl = useRef(null)

  const handleMenuClick = useCallback(
    e => {
      const isMenuClicked = $menuEl.current === e.target

      if (!isMenuClicked) {
        // close the menu
        setMenuStatus(false)
      }
    },
    [$menuEl]
  )

  useEffect(() => {
    document.addEventListener('click', handleMenuClick)

    return () => document.removeEventListener('click', handleMenuClick)
  })

  const email = authStatus?.user?.email

  return (
    <div
      className="relative"
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <div className="flex items-center justify-center ml-5">
        <button
          className="block h-10 w-10 rounded-full overflow-hidden border-2 bg-purple-700 hover:bg-purple-500 border-purple-300 focus:outline-none"
          onClick={e => {
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation()

            setMenuStatus(status => !status)
          }}
        >
          <div className="h-full w-full object-cover">
            <UserCircle />
          </div>
        </button>
      </div>
      {showMenu && (
        <div
          style={{
            zIndex: 100001,
          }}
          ref={$menuEl}
          className="absolute right-0 mt-1 py-2 w-64 bg-white rounded-sm shadow-md"
        >
          <div className="text-sm text-gray-700 p-3 border-b font-medium break-all">
            {email}
          </div>
          <Link
            to="/search-history"
            className="flex items-center px-3 py-2 text-gray-700 text-sm hover:bg-purple-500 hover:text-white"
          >
            <span className="inline-block mr-5 h-6 w-6">
              <DocSearch />
            </span>
            <span>My Job Searches</span>
          </Link>
          <Link
            to="/logout"
            className="flex items-center px-3 py-2 text-gray-700 text-sm hover:bg-purple-700 hover:text-white"
          >
            <span className="inline-block mr-5 h-6 w-6">
              <Logout />
            </span>
            <span>Logout</span>
          </Link>
        </div>
      )}
    </div>
  )
}

export default AccountMenu
