import React, { useState } from 'react'

import { Add, Search } from 'Svg/'
import Button, { ButtonType, ButtonSize, HrefLink } from 'Common/Button/'
import AddJobModal from 'Common/Modals/AddJob/'
import AccountMenu from './AccountMenu'

function LoggedInNav() {
  const [isJobModalOpen, setJobModalStatus] = useState(false)
  return (
    <div className="flex justify-center items-center">
      <div className={`mx-4`}>
        <HrefLink href={'/find-jobs'} text="Find Jobs" Icon={Search} />
      </div>

      <div className={'mx-2'}>
        <Button
          type={ButtonType.Outline}
          size={ButtonSize.Normal}
          Icon={Add}
          onClick={() => {
            setJobModalStatus(true)
          }}
          text={'Add Job'}
        />
      </div>

      <AddJobModal
        isOpen={isJobModalOpen}
        setModalStatus={setJobModalStatus}
        afterAddCallback={() => {}}
      />
      <AccountMenu />
    </div>
  )
}

export default LoggedInNav
