import React from 'react'

import { isNull, isEmpty } from 'lodash'
import { Location, Tag, ExternalLink } from 'Svg/'
import { Jobs } from 'Types/Job/'
import Job from './Job'
import AddJobAction from 'Components/AddJobAction'

type Props = {
  jobs: Jobs
}

function JobList({ jobs }: Props) {
  // TODO: For generic components we should have a better way of tracking loading
  // and the state when the user is logged out
  if (isNull(jobs)) {
    return null
  }

  if (isEmpty(jobs)) {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="text-xs italic text-gray-600 mb-3">
          {' '}
          No jobs added. Please add jobs to get started with your job search.
        </div>
        <AddJobAction />
      </div>
    )
  }

  return (
    <div className="my-8 pb-16">
      <div className="flex text-xl text-gray-600 font-bold my-3">
        Recent Jobs
      </div>
      <div className="flex text-xs uppercase border border-gray-400 mb-3 divide-x divide-gray-400 bg-gray-600 text-gray-200 font-semibold">
        <div className="w-1/3 p-3 px-2 flex items-center">Name</div>
        <div className="w-1/6 p-3 px-1 flex items-center">
          <span className="w-4 h-4 mr-1">
            <Location />
          </span>
          <span>Location</span>
        </div>
        <div className="w-1/12 p-3 ">
          <span className="md:hidden lg:flex items-center">
            <span>Apply</span>
            <span className="w-4 h-4 ml-1">
              <ExternalLink />
            </span>
          </span>
        </div>
        <div className="w-1/12 p-3 flex items-center">
          <div className="md:hidden lg:flex items-center">
            <span>Company</span>
            <span className="w-4 h-4 ml-1">
              <ExternalLink />
            </span>
          </div>
        </div>
        <div className="w-1/3 p-3 flex items-center">
          <span className="w-4 h-4 mr-1">
            <Tag />
          </span>
          <span>Tags</span>
        </div>
      </div>
      {jobs.map((j, index) => (
        <Job key={j.id} {...j} index={index} />
      ))}
    </div>
  )
}

export default JobList
