import React from 'react'
import { Link } from 'react-router-dom'

import { Search, User, Desktop } from 'Svg/'
import { notify } from 'Util/Noty/'
import { ButtonType, HrefLink } from 'Common/Button/'

function PublicNav() {
  const Login = () => (
    <HrefLink
      type={ButtonType.Outline}
      Icon={User}
      href={'/login'}
      text={'Login'}
    />
  )

  return (
    <div className="flex items-center">
      <div className={`mx-4`}>
        <HrefLink href={'/find-jobs'} text="Find Jobs" Icon={Search} />
      </div>
      <div className={`mr-4 ml-2`}>
        <Login />
      </div>

      <Link
        className="flex items-center py-2 bg-red-500 hover:bg-red-400 border border-red-400 text-white font-bold px-4 ml-3 focus:outline-none focus:shadow-outline"
        to={'/try-demo/my-job-search'}
        onClick={() => {
          notify({
            type: 'success',
            text: 'Please feel free to explore this tool.',
            title: 'Thank you for trying out Jublime',
          })
        }}
      >
        <div className="flex items-center w-5 h-5 mr-1">
          <Desktop />
        </div>
        <div className="text-base">{'Try Demo'}</div>
      </Link>
    </div>
  )
}

export default PublicNav
