import React, { useState, useEffect } from 'react'
import { isUndefined, isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'
import TimeAgo, { TDate } from 'timeago-react'

import { SearchStatus } from 'Types/Enums/'
import { Search } from 'Types/Search'
import { getSearchHistory } from 'Util/Server/JobSearch'
import { BarLoader } from 'Assets/Loaders/'
import Badge, { BadgeType } from 'Common/Badge'

// helper function to get badge type
export function _getBadgeType(status: SearchStatus): BadgeType {
  switch (status) {
    case SearchStatus.Active:
      return BadgeType.Success

    case SearchStatus.Concluded:
      return BadgeType.Danger

    default:
      return BadgeType.Inactive
  }
}

function SearchList() {
  const history = useHistory()
  const [searchHistory, setSearchHistory] = useState<Search[] | undefined>(
    undefined
  )

  useEffect(() => {
    getSearchHistory({
      successCallback: (data: Search[]) => {
        setSearchHistory(data)
      },
      errorCallback: () => {},
    })
  }, [])

  if (isUndefined(searchHistory)) {
    return <BarLoader />
  }

  if (isEmpty(searchHistory)) {
    return (
      <div className="my-8 pb-16 font-semibold">
        No Job search history found.
      </div>
    )
  }

  return (
    <div className="my-8 pb-16">
      <div className="flex text-xl text-gray-600 font-bold my-3">
        Search History
      </div>
      <div className="flex text-xs uppercase border border-gray-400 mb-3 divide-x divide-gray-400 bg-gray-600 text-gray-200 font-semibold">
        <div className="w-1/3 p-3 px-2 flex items-center">Name</div>
        <div className="w-1/6 p-3 px-1 flex items-center">Status</div>
        <div className="w-1/6 p-3 flex items-center">Started</div>
        <div className="w-1/6 p-3 md:hidden lg:flex items-center">
          Concluded
        </div>
        <div className="w-1/6 p-3 flex items-center">Updated</div>
      </div>
      <div>
        {searchHistory.map((h, index) => (
          <div
            key={h.id}
            className={`flex divide-y-0 divide-x border border-gray-200 text-sm ${
              index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
            } hover:bg-gray-200 cursor-pointer pointer-events-auto`}
            onClick={() => history.push(`/job/search/${h.id}`)}
          >
            <div className="w-1/3 p-3 px-2 flex items-center">{h.name}</div>
            <div className="w-1/6 p-3 px-1 flex items-center">
              <Badge
                type={_getBadgeType(h.status as SearchStatus)}
                text={h.status as string}
              />
            </div>
            <div className="w-1/6 p-3 flex items-center">
              <TimeAgo
                datetime={h.inserted_at as TDate}
                opts={{
                  minInterval: 60,
                }}
              />
            </div>
            <div className="w-1/6 p-3 md:hidden lg:flex items-center">
              {h.completed_at ? (
                <TimeAgo
                  datetime={h.completed_at as TDate}
                  opts={{
                    minInterval: 60,
                  }}
                />
              ) : (
                '\u2014'
              )}
            </div>
            <div className="w-1/6 p-3 flex items-center">
              <TimeAgo
                datetime={h.updated_at as TDate}
                opts={{
                  minInterval: 60,
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default SearchList
