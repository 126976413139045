import axios from 'axios'
import { notify } from 'Util/Noty/'

import { JobInfo, Callback } from 'Types/Job/'

export function listJobs({ successCallback, errorCallback }: Callback) {
  axios
    .get('/api/jobs/list')
    .then(({ data }) => {
      successCallback(data)
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to continue ...',
        })
      } else {
        notify({
          type: 'error',
          text: 'Error getting job details. Please try again.',
        })
      }

      errorCallback()
    })
}

// get details for given job id
export function getJobDetails({
  id,
  successCallback,
  errorCallback,
}: {
  id: string
  successCallback: Function
  errorCallback: Function
}) {
  axios
    .get(`/api/job/detail/${id}`)
    .then(({ data }) => {
      successCallback(data)
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to continue.',
        })
      } else {
        notify({
          type: 'error',
          text: 'Error getting job details. Please try again.',
        })
      }

      errorCallback()
    })
}

export interface JobActionProps {
  job: JobInfo
  successCallback: Function
  errorCallback: Function
}

// Add New Job
export function addJob({
  job,
  successCallback,
  errorCallback,
}: JobActionProps) {
  axios
    .post('/api/job/add', {
      job,
    })
    .then(({ data }) => {
      notify({
        type: 'success',
        text: `${data.name} added.`,
      })
      successCallback({ job_id: data.id })
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to add job',
        })
      } else {
        notify({
          type: 'error',
          text: 'Error getting job details. Please try again.',
        })
      }
      errorCallback()
    })
}

// edits given job
export function editJob({
  job,
  successCallback,
  errorCallback,
}: JobActionProps) {
  axios
    .post('/api/job/edit', {
      job,
    })
    .then(({ data }) => {
      notify({
        type: 'success',
        text: `'${data.name}' updated.`,
      })
      successCallback({ job: data })
    })
    .catch(err => {
      const { status } = err.response
      if (status === 401) {
        notify({
          type: 'error',
          text: 'Session expired. Please login to edit job',
        })
      } else {
        notify({
          type: 'error',
          text: 'Error getting job details. Please try again.',
        })
      }
      errorCallback()
    })
}
