import React from 'react'

interface Props {
  children?: React.ReactNode
}

export function BarLoader({ children }: Props) {
  return (
    <div className="flex flex-col items-center align-center">
      <svg
        width="35"
        height="55"
        viewBox="0 0 55 80"
        xmlns="http://www.w3.org/2000/svg"
        fill="#805AD5"
      >
        <g transform="matrix(1 0 0 -1 0 80)">
          <rect width="10" height="20" rx="3">
            <animate
              attributeName="height"
              begin="0s"
              dur="4.1s"
              values="20;45;57;80;64;32;66;45;64;23;66;13;64;56;34;34;2;23;76;79;20"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="15" width="10" height="80" rx="3">
            <animate
              attributeName="height"
              begin="0s"
              dur="2s"
              values="80;55;33;5;75;23;73;33;12;14;60;80"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="30" width="10" height="50" rx="3">
            <animate
              attributeName="height"
              begin="0s"
              dur="1.3s"
              values="50;34;78;23;56;23;34;76;80;54;21;50"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </rect>
          <rect x="45" width="10" height="30" rx="3">
            <animate
              attributeName="height"
              begin="0s"
              dur="2.9s"
              values="30;45;13;80;56;72;45;76;34;23;67;30"
              calcMode="linear"
              repeatCount="indefinite"
            />
          </rect>
        </g>
      </svg>
      <div>
        {children || (
          <span className="text-sm text-purple-700 font-semibold">
            Please wait ... Loading
          </span>
        )}
      </div>
    </div>
  )
}

export const Loading = BarLoader
