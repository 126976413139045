import React, { useContext } from 'react'
import { isUndefined } from 'lodash-es'
import { Link } from 'react-router-dom'

import { notify } from 'Util/Noty/'
import { ArrowRight } from 'Svg/'
import BannerImage from 'Assets/Images/banner.png'
import ContactImage from 'Assets/contact-me.png'
import { AuthContext } from 'Context/Auth/'
import Dashboard from 'Pages/Dashboard'
import FAQ from './Faq'
import Subscription from 'Common/EmailSubscription/'
import { InfoBanner } from 'Util/Styles'

function Home() {
  const { authStatus } = useContext(AuthContext)

  /*
   * NOTE:
   * authStatus => undefined
   * Data/Status is not yet fetched from server
   */

  if (isUndefined(authStatus)) {
    // do not proceed
    return null
  }

  const { token, user } = authStatus
  const isLoggedIn = token && user

  return isLoggedIn ? (
    <div>
      <Dashboard />
    </div>
  ) : (
    <div>
      <div className="my-3 flex items-center justify-center w-full">
        <InfoBanner>
          Welcome! This app is currently in{' '}
          <span className="italic font-semibold">beta, seeking feedback</span>{' '}
          from interested people. Please use this tool for your job search and
          let me know your thoughts.
        </InfoBanner>
      </div>
      <div className="py-24 flex border-b">
        <div
          className="flex flex-col p-5 justify-center"
          style={{
            flex: 13,
          }}
        >
          <h1 className="text-5xl text-gray-700 my-3 font-semibold">
            Track Job Applications better
          </h1>
          <h3 className="text-2xl my-3 text-gray-600 font-normal leading-tight">
            Track application status and add notes on job interviews. Jublime
            makes it easy to focus on your job search.
          </h3>
          <div className="flex mt-8">
            <Link
              className="w-auto flex items-center px-8 py-2 bg-red-500 hover:bg-red-400 border border-red-400 text-white focus:outline-none focus:shadow-outline"
              to={'/try-demo/my-job-search'}
              onClick={() => {
                notify({
                  type: 'success',
                  text: 'Thank you for trying out Jublime',
                })
              }}
            >
              <div className="text-xl font-semibold">{'Get Started'}</div>
              <div className="flex items-center w-6 h-6 ml-3 mt-1">
                <ArrowRight />
              </div>
            </Link>
          </div>
          <div className="my-1 text-xs italic text-gray-600">
            No account required. Try the demo.
          </div>
        </div>
        <div
          className="flex"
          style={{
            flex: 11,
          }}
        >
          <div className="shadow rounded p-1 pb-3">
            <img src={BannerImage} alt={'Jublime Job Search Tool'} />
          </div>
        </div>
      </div>
      <div className="my-12 flex flex-col items-center justify-center">
        {/* FAQ */}
        <FAQ />
        {/* Feedback */}
        <div className="flex items-center justify-center text-4xl my-5 mt-16 text-gray-700 font-semibold">
          Feedback Please!
        </div>
        <div className="max-w-4xl">
          <div className="bg-purple-700 p-3 font-normal text-white rounded border leading-relaxed">
            Hi. I would like to know your thoughts and feedback on this job
            search tool. Please reach out to me at{' '}
            <span className="italic font-semibold">arun@jublime.com</span> , and
            let me know your feedbacks if you decide to use this tool for your
            job search.
          </div>
        </div>
        <div className="my-12  border rounded py-8 w-8/12">
          <img src={ContactImage} alt={'Please give feedback'} />
        </div>
        {/* Email Subscription */}
        <Subscription />

        <div className="italic flex items-center justify-center">
          All the best for your job search!
        </div>
      </div>
    </div>
  )
}

export default Home
