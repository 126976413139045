const DATA = [
  {
    id: '1',
    name: 'Interesting',
  },
  {
    id: '2',
    name: 'nice salary',
  },
  {
    id: '3',
    name: 'relocation assistance',
  },
  {
    id: '4',
    name: 'good benefits',
  },
  {
    id: '5',
    name: 'remote friendly',
  },
  {
    id: '6',
    name: 'insurance',
  },
  {
    id: '7',
    name: 'startup',
  },
]

export default DATA
