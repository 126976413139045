import { useState, useEffect, Dispatch } from 'react'
import { debounce, isEmpty, words, intersection, isEqual } from 'lodash-es'

import { SearchBox } from './Styles'
import { SetStateAction } from 'hoist-non-react-statics/node_modules/@types/react'

type ActiveProspect = {
  searchId: number
  prospectId: number
  id: number
  name: string
}

export type ActiveProspects = Array<ActiveProspect>

type Props = {
  jobs: ActiveProspects
}

function searchJobs(query: string, jobs: ActiveProspects): ActiveProspects {
  if (isEmpty(query)) {
    return jobs
  }

  return jobs.filter(j => {
    const queryWords = words(query).map(x => x.toLowerCase())
    const jobNameWords = words(j.name).map(x => x.toLowerCase())

    const queryMatch = j.name.toLowerCase().includes(query.toLowerCase())
    const wordMatch = !isEmpty(intersection(queryWords, jobNameWords))

    return wordMatch || queryMatch
  })
}

const lazySearch = debounce(
  (
    query: string,
    jobs: ActiveProspects,
    setActiveJobs: Dispatch<SetStateAction<ActiveProspects>>
  ) => {
    const newJobs = searchJobs(query, jobs)
    setActiveJobs(currentJobs => {
      return isEqual(currentJobs, newJobs) ? currentJobs : newJobs
    })
  },
  314
)

function Jobs({ jobs }: Props) {
  const [query, setQuery] = useState('')
  const [activeJobs, setActiveJobs] = useState(jobs)

  useEffect(() => {
    lazySearch(query, jobs, setActiveJobs)
  }, [query, jobs])

  return (
    <div>
      <SearchBox
        placeholder={'Search Active Jobs by Name ...'}
        value={query}
        onChange={e => {
          setQuery(e.target.value)
        }}
      />

      <div className={'job-holder'}>
        {activeJobs.map(j => (
          <a
            key={j.id}
            target={'_blank'}
            rel={'noreferrer'}
            className={'job'}
            href={`/job/search/${j.searchId}#prospect-${j.prospectId}`}
          >
            {j.name}
          </a>
        ))}
      </div>
    </div>
  )
}

export default Jobs
