import React from 'react'

interface Props {
  value: string
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleBlur: (e: React.ChangeEvent<HTMLInputElement>) => void
  errors?: string
}

function Name({ value, handleBlur, handleChange, errors }: Props) {
  return (
    <div className="mb-4">
      <label
        className="block text-gray-700 text-sm font-bold mb-2"
        htmlFor="job-name"
      >
        Name <span className={'mx-1 text-red-500'}>*</span>
      </label>
      <input
        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
          errors && 'border-red-500'
        }`}
        id="job-name"
        type="text"
        placeholder="Job name ... "
        name={'name'}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {errors && <p className="text-red-500 text-xs italic my-1">{errors}</p>}
    </div>
  )
}

export default Name
