import axios from 'axios'

import { notify } from 'Util/Noty/'
import { SetAuthStatus, SetLocations, SetTags, UserValues } from 'Context/Auth/'
import { handleLoginErrors } from 'Util/Server/'

// helper function to authenticate user
// sets token, user details in Auth context
// sets axios token
function authenticateUser({
  token,
  user,
  setAuthStatus,
}: {
  token: string
  user: UserValues
  setAuthStatus: SetAuthStatus
}) {
  // IMPORTANT: since the user is logged in, set TOKEN in AUTHORIZATION globally
  // this needs to come first before setting auth status with 'token'
  // because when 'token'/'user' is set, 'Auth' Context automatically picks up
  // the changes and fetches the 'location/tags' for the user

  // NOTE: we are just using Session/Cookies to track logged in status
  // this is just to verify the user in the 'auth' plug
  axios.defaults.headers.common['Authorization'] = `${token}`

  // set auth status
  setAuthStatus({
    authStatus: {
      token,
      user,
    },
  })
}

function deauthenticateUser({
  setAuthStatus,
}: {
  setAuthStatus: SetAuthStatus
}) {
  setAuthStatus({
    authStatus: {
      token: null,
      user: null,
    },
  })

  // reset global authorization token
  axios.defaults.headers.common['Authorization'] = ""
}

interface OAuthParams {
  email: string
  name?: string
}

// OAuth related stuffs
// Authorize Google login
export function authorizeGoogleLogin({
  setAuthStatus,
  params,
  successCallback,
  errorCallback,
}: {
  setAuthStatus: SetAuthStatus
  params: OAuthParams
  successCallback: Function
  errorCallback: Function
}) {
  axios
    .post('/auth/authorize-google-login', {
      ...params,
    })
    .then(({ data, status, headers, config }) => {
      const { token, user, type } = data

      // execute success callback
      successCallback()
      // this will automatically navigate to '/'
      authenticateUser({
        token,
        user,
        setAuthStatus,
      })

      // if the google user has just registered GREET them
      if (type === 'REGISTRATION') {
        notify({
          type: 'success',
          text: 'Welcome to Jublime! All the best for your Job Search!',
        })
      }
    })
    .catch(err => {
      handleLoginErrors(err.response)

      // execute error callback
      errorCallback()
    })
}

// REGISTER
export function registerUser({
  setAuthStatus,
  email,
  password,
  successCallback,
  errorCallback,
}: {
  setAuthStatus: SetAuthStatus
  email: string
  password: string
  successCallback: Function
  errorCallback: Function
}) {
  axios
    .post('/auth/register', {
      email,
      password,
    })
    .then(({ data, status, headers, config }) => {
      const { token, user } = data

      authenticateUser({
        token,
        user,
        setAuthStatus,
      })

      // execute success callback
      successCallback()
    })
    .catch(err => {
      handleLoginErrors(err.response)

      // execute error callback
      errorCallback()
    })
}

// LOGIN
export function loginUser({
  setAuthStatus,
  email,
  password,
  successCallback,
  errorCallback,
}: {
  setAuthStatus: SetAuthStatus
  email: string
  password: string
  successCallback: Function
  errorCallback: Function
}) {
  axios
    .post('/auth/login', {
      email,
      password,
    })
    .then(({ data, status, headers, config }) => {
      const { token, user } = data

      authenticateUser({
        token,
        user,
        setAuthStatus,
      })

      // execute success callback
      successCallback()
    })
    .catch(err => {
      handleLoginErrors(err.response)

      // execute error callback
      errorCallback()
    })
}

// LOGOUT
export function logOutUser(successCallback: Function) {
  axios
    .get('/auth/logout')
    .then(() => {
      notify({
        type: 'success',
        text: 'Logged out successfully',
      })
      // call the success callback
      successCallback()
    })
    .catch(err => {
      notify({
        type: 'error',
        text: 'Error in logging out user ... Please try again',
      })
    })
}

// STATUS check
export function fetchAuthStatus({
  setAuthStatus,
}: {
  setAuthStatus: SetAuthStatus
}) {
  axios
    .get('/auth/status')
    .then(({ data, status }) => {
      const { token, user } = data

      authenticateUser({
        token,
        user,
        setAuthStatus,
      })
    })
    .catch(err => {
      const { status } = err.response

      if (status === 401) {
        deauthenticateUser({
          setAuthStatus,
        })
        // do not proceed
        return
      }
    })
}

export function fetchLocations({
  setLocations,
}: {
  setLocations: SetLocations
}) {
  axios
    .get('/api/locations/list')
    .then(({ data, status }) => {
      const { locations } = data
      setLocations(locations)
    })
    .catch(err => {
      const { status } = err.response

      if (status === 401) {
        // setLocations([])
        // do not proceed
        return
      }
    })
}

export function fetchTags({ setTags }: { setTags: SetTags }) {
  axios
    .get('/api/tags/list')
    .then(({ data, status }) => {
      const { tags } = data
      setTags(tags)
    })
    .catch(err => {
      const { status } = err.response

      if (status === 401) {
        // setLocations([])
        // do not proceed
        return
      }
    })
}
