import React, { useState, useContext } from 'react'
import { isEmpty } from 'lodash'

import { Search } from 'Types/Search'
import { SearchStatus } from 'Types/Enums'
import { RecentJobs } from 'Types/Job/'

import { ApiContext } from 'Context/Api'
import { Stop, Edit } from 'Svg/'
import Gist from 'Components/JobSearch/Gist'
import BurgerMenu from 'Common/BurgerMenu/'
import { Actions } from 'Components/JobSearch/Prospect/Styles/'
import { confirm } from 'Util/Confirm'
import Prospects from './Prospects'

import SearchModal from 'Components/JobSearchModal'

interface Props {
  details: Search
  setDetails: React.Dispatch<React.SetStateAction<Search>>
  recentJobs: RecentJobs
  fetchSearchDetails: () => void
}

function Detail({
  details,
  setDetails,
  recentJobs,
  fetchSearchDetails,
}: Props) {
  const [isEditModalOpen, setEditModalStatus] = useState(false)

  const { concludeSearch } = useContext(ApiContext)

  if (isEmpty(details)) {
    return null
  }

  return (
    <div className="py-5">
      <SearchModal
        mode={'edit'}
        isOpen={isEditModalOpen}
        setStatus={setEditModalStatus}
        searchDetails={details}
        setSearchDetails={setDetails}
      />
      <div className="flex">
        <Gist {...details} />
        {details?.status === SearchStatus.Active && (
          <Actions>
            <BurgerMenu>
              {closeMenu => (
                <div>
                  {/* Edit Job Search */}
                  <div
                    className="flex items-center text-sm text-gray-700 p-3 border-b font-medium break-all cursor-pointer hover:bg-gray-100"
                    onClick={() => {
                      setEditModalStatus(true)
                      closeMenu()
                    }}
                  >
                    <span className="inline-block mr-3 h-6 w-6 text-purple-700">
                      <Edit />
                    </span>
                    <span>Edit Job Search</span>
                  </div>

                  {/* Conclude Job Search */}
                  <div
                    className="flex items-center text-sm text-gray-700 p-3 border-b font-medium break-all cursor-pointer hover:bg-gray-100"
                    onClick={() => {
                      confirm({
                        text: 'Do you want to conclude this active job search?',
                        buttonText: 'Conclude Job Search',
                        successCallback: () => {
                          concludeSearch({
                            id: details.id as number,
                            successCallback: () => {
                              // update search details as concluded
                              setDetails(currentDetails => ({
                                ...currentDetails,
                                status: 'CONCLUDED',
                                completed_at: new Date().toISOString(),
                              }))
                            },
                            errorCallback: () => {},
                          })
                        },
                        errorCallback: () => {},
                      })
                      closeMenu()
                    }}
                  >
                    <span className="inline-block mr-3 h-6 w-6 text-red-500">
                      <Stop />
                    </span>
                    <span>Conclude Job Search</span>
                  </div>
                </div>
              )}
            </BurgerMenu>
          </Actions>
        )}
      </div>

      <div>
        {details?.prospects && (
          <Prospects
            prospects={details?.prospects}
            updateSearchDetails={fetchSearchDetails}
          />
        )}
      </div>
      {recentJobs.length === 0 && (
        <div className="flex items-center justify-center p-3 my-12 bg-blue-200 rounded border">
          <div>
            You have not added any jobs yet. Click{' '}
            <span className="font-semibold">'+ Add New Job'</span> to kickstart
            your job search
          </div>
        </div>
      )}
    </div>
  )
}

export default Detail
