import React from 'react'
import { isEmpty } from 'lodash'
import TimeAgo, { TDate } from 'timeago-react'
import ReactTooltip from 'react-tooltip'

import { Comments as CommentsIcon } from 'Svg/'
import { Search } from 'Types/Search/'
import { ProspectStatus, SearchStatus } from 'Types/Enums/'
import { TimePeriod } from 'Pages/Job/Detail/Styles'
import Badge from 'Common/Badge'
import { _getBadgeType } from 'Pages/SearchHistory/SearchList'

function Gist({
  name,
  status,
  comments,
  prospects,
  inserted_at,
  updated_at,
}: Search) {
  const activeStatus: Array<ProspectStatus> = [
    ProspectStatus.Applied,
    ProspectStatus.Accepted,
    ProspectStatus.Interviewing,
    ProspectStatus.Offer,
    ProspectStatus.InConversation,
  ]

  const activeProspects = (prospects || []).filter(prospect =>
    activeStatus.includes(prospect.status)
  )

  const totalActiveProspects = activeProspects.length

  let activeProspectsInfo =
    totalActiveProspects === 1 ? `active job prospect` : `active job prospects`

  return (
    <div className="my-3">
      <div className="flex items-center my-2">
        <div className="mr-3 font-semibold text-gray-700">{name}</div>
        <Badge
          type={_getBadgeType(status as SearchStatus)}
          text={status as SearchStatus}
        />
        <span
          className={'text-purple-700 mx-2'}
          data-tip
          data-for={'search-comments'}
        >
          <CommentsIcon />
        </span>

        <ReactTooltip id="search-comments" className={'jubtip'} effect="solid">
          <div className={'italic'}>{comments || 'No Comments'}</div>
        </ReactTooltip>
      </div>
      <div>
        <TimePeriod>
          <span className={'label'}>Updated </span>
          <TimeAgo
            datetime={updated_at as TDate}
            opts={{
              minInterval: 60,
            }}
          />
        </TimePeriod>
      </div>
      <div>
        <TimePeriod>
          <span className={'label'}>Started </span>
          <TimeAgo
            datetime={inserted_at as TDate}
            opts={{
              minInterval: 60,
            }}
          />
        </TimePeriod>
      </div>
      {!isEmpty(activeProspects) && (
        <div className={'my-2'}>
          <span className="text-2xl font-bold text-gray-600">
            {totalActiveProspects}{' '}
          </span>
          <span className="text-gray-700">{activeProspectsInfo}</span>
        </div>
      )}
    </div>
  )
}

export default Gist
