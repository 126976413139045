import React, { useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { ApiContext } from 'Context/Api/'
import { AuthContext } from 'Context/Auth/'
import { JobDetail } from 'Types/Job/'
import { TagHolder, Tag, LocationInfo } from 'Pages/Job/Detail/Styles'
import { BriefCase, CursorClick } from 'Svg/'
import { IconLink } from 'Common/ExternalLink/'
import { parseJobURL } from 'Util/index'

interface Props extends JobDetail {
  index: number
}

function Job({
  index,
  id,
  name,
  location_id,
  comments,
  tag_ids,
  company_url,
  job_url,
}: Props) {
  const history = useHistory()
  const { locations, tags } = useContext(AuthContext)
  const { _getJobURL } = useContext(ApiContext)

  const _getLocation = useCallback(
    location_id => {
      const location = locations.find(l => l.id === location_id)

      return location ? location.name : ''
    },
    [locations]
  )

  const _getTags = useCallback(
    tag_ids => {
      return tags.filter(t => tag_ids?.split(',').includes(String(t.id)))
    },
    [tags]
  )

  const locationName = _getLocation(location_id)
  const tagsList = _getTags(tag_ids)

  return (
    <div
      className={`flex divide-y-0 divide-x border border-gray-200 text-sm ${
        index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
      } hover:bg-gray-200 cursor-pointer pointer-events-auto`}
      onClick={() => history.push(_getJobURL(id as number))}
    >
      <div className="w-1/3 p-2 flex items-center">{name}</div>
      <div className="w-1/6 p-2 flex items-center">
        <LocationInfo name={locationName} />
      </div>
      <div className="w-1/12">
        <IconLink href={parseJobURL(job_url)} Icon={CursorClick} />
      </div>
      <div className="w-1/12">
        {company_url && <IconLink href={company_url} Icon={BriefCase} />}
      </div>
      <div className="w-1/3 p-2 flex items-center">
        <TagHolder showIcon={false}>
          {tagsList.map(tag => (
            <Tag key={tag.id}>{tag.name}</Tag>
          ))}
        </TagHolder>
      </div>
    </div>
  )
}

export default Job
