import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import { AuthContext } from 'Context/Auth/'
import { logOutUser } from 'Util/Server/Auth'

function Logout() {
  const history = useHistory()
  const { updateAuthStatus } = useContext(AuthContext)

  useEffect(() => {
    logOutUser(() => {
      // clear the auth status
      updateAuthStatus({
        authStatus: {
          token: null,
          user: null,
        },
      })
      // redirect to home page
      history.push('/')
    })
  }, [history, updateAuthStatus])

  return <div>Please wait ... logging out ... </div>
}

export default Logout
