import React from 'react'
import { Switch, Route } from 'react-router-dom'

// TRY JUBLIME routes
import TryRoutes from 'Try/Routes'

import AuthRoute from 'Util/AuthRoute/'
// general pages
import Home from 'Pages/Home/'
import Login from 'Pages/Login/'
import Logout from 'Pages/Logout/'
import Signup from 'Pages/Signup/'
import ConfirmEmail from 'Pages/ConfirmEmail/'
import ForgotPassword from 'Pages/ForgotPassword/'
import ResetPassword from 'Pages/ResetPassword/'
import Explore from 'Pages/Explore/'

// ******************************************
// START: Authenticated routes
// ******************************************

// job related pages
import JobDetail from 'Pages/Job/Detail/'
// search related pages
import SearchDetail from 'Pages/Search/Show/'
import SearchHistory from 'Pages/SearchHistory/'

// ******************************************
// END: Authenticated routes
// ******************************************

function Routes() {
  return (
    <Switch>
      {/* START: Public Routes */}
      <Route path="/create-account">
        <Signup />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/confirm-email/:token">
        <ConfirmEmail />
      </Route>
      <Route path="/reset-password/:token">
        <ResetPassword />
      </Route>
      {/* END: Public Routes */}

      {/* --------------------------- */}
      {/* START: Authenticated Routes */}
      {/* --------------------------- */}

      {/* Get job detail */}
      <AuthRoute path="/job/detail/:id">
        <JobDetail />
      </AuthRoute>
      {/* Get search detail */}
      <AuthRoute path="/job/search/:id">
        <SearchDetail />
      </AuthRoute>
      {/* Job Search History */}
      <AuthRoute path="/search-history">
        <SearchHistory />
      </AuthRoute>

      {/* --------------------------- */}
      {/* END: Authenticated Routes */}
      {/* --------------------------- */}

      {/* Explore jobs */}
      <Route path="/find-jobs">
        <Explore />
      </Route>

      {/* START: Strictly Non Logged in users (like forgot password) */}
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>

      {/* TRY Jublime Routes */}
      <Route path={['/try-demo/*']}>
        <TryRoutes />
      </Route>
      {/* END: Strictly Non Logged in users (like forgot password) */}

      <Route exact path="/">
        <Home />
      </Route>
    </Switch>
  )
}

export default Routes
