import React, { useState, useContext } from 'react'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

import { RecentJobs } from 'Types/Job/'
import { RecentSearch } from 'Types/Search'

import { ApiContext } from 'Context/Api/'
import { ClipboardList, ViewGridAdd } from 'Svg/'
import Button, { ButtonType } from 'Common/Button/'
import SearchModal from 'Components/JobSearchModal'
import AddProspectsModal from 'Components/JobSearch/AddProspectsModal'
import Gist from './Gist'

type Props = {
  recentJobs: RecentJobs
  recentSearch: RecentSearch
}

function JobSearch({ recentJobs, recentSearch }: Props) {
  const history = useHistory()
  const { _getSearchURL } = useContext(ApiContext)

  const [searchModalStatus, setSearchModalStatus] = useState(false)
  const [prospectsModalStatus, setProspectsModalStatus] = useState(false)

  return (
    <div className="flex items-center shadow p-3">
      <div className="flex w-full justify-center items-center">
        {recentSearch && recentSearch.id ? (
          <div className="flex flex-col w-full justify-center p-5">
            <Gist {...recentSearch} />
            <div className="flex w-full justify-center items-center my-3">
              {isEmpty(recentSearch.prospects) ? (
                <div>
                  <div className="flex items-center justify-center my-3 text-xs italic text-gray-600">
                    {' '}
                    No active job prospects
                  </div>
                  <div>
                    <Button
                      disabled={recentJobs.length === 0}
                      type={ButtonType.PrimaryDark}
                      Icon={ViewGridAdd}
                      text={'Add Jobs to Search'}
                      onClick={() => setProspectsModalStatus(true)}
                    />
                  </div>
                  {/* Add Prospects to Search Modal */}
                  <AddProspectsModal
                    search_id={recentSearch.id}
                    search_name={recentSearch.name}
                    jobs={recentJobs}
                    isOpen={prospectsModalStatus}
                    setStatus={setProspectsModalStatus}
                  />
                </div>
              ) : (
                <div>
                  <Button
                    type={ButtonType.PrimaryDark}
                    text={'Continue Job Search'}
                    onClick={() => history.push(_getSearchURL(recentSearch.id))}
                  />
                </div>
              )}
            </div>
          </div>
        ) : (
          <Button
            Icon={ClipboardList}
            type={ButtonType.PrimaryDark}
            text="Start Job Search"
            onClick={() => setSearchModalStatus(true)}
          />
        )}
      </div>
      {/* Add New Job Search Modal */}
      <SearchModal
        mode={'create'}
        isOpen={searchModalStatus}
        setStatus={setSearchModalStatus}
      />
    </div>
  )
}

export default JobSearch
