import React, { useState } from 'react'
import styled from 'styled-components'

import { Hand } from 'Svg/'
import Modal from './FeedbackModal'

const Wrapper = styled.div`
  position: fixed;
  bottom: 2px;
  right: 4px;

  width: 12%;
  height: 48px;
`

function Feedback() {
  const [isOpen, setModalStatus] = useState(false)

  return (
    <div>
      <Wrapper
        className={
          'flex items-center justify-center p-3 px-5 bg-red-500 hover:bg-red-400 text-white font-semibold cursor-pointer'
        }
        onClick={() => setModalStatus(true)}
      >
        <div className="flex items-center w-5 h-5 mr-3">
          <Hand />
        </div>
        <div className="text-base">{'Feedback'}</div>
      </Wrapper>
      <Modal isOpen={isOpen} setModalStatus={setModalStatus} />
    </div>
  )
}

export default Feedback
