import React from 'react'

import { resendConfirmation } from 'Util/Server/ConfirmEmail'

function ResendConfirmation() {
  return (
    <div
      className="inline-block text-purple-700 underline hover:text-opacity-75 cursor-pointer font-semibold"
      onClick={() => {
        resendConfirmation()
      }}
    >
      Click here to send confirmation email again.{' '}
    </div>
  )
}

export default ResendConfirmation
